/**
 * app/public/typo3conf/ext/bu_sap_api/Classes/Sap/SapRequests.php
 * app/public/typo3conf/ext/bu_sap_api/Classes/Controller/ApiController.php (:301) - to disable error message hashing
 *
 * See: Google Docs "Feldbeschreibungen Schnittstelle"
 * Tab: SUPPLY_START
 * Priority: 0 -> not needed / optional (white)
 * Priority: 1 -> optional (orange)
 * Priority: 2 -> mandatory information (red)
 */

import { HeatingType, MeterType } from "@/api/sap";
import store from "@/store";
import { compact, get } from "lodash-es";
import { SalesApplicationRouteState } from "..";
import { ProdOptionsRequest } from "@/api/sap/@types/read-single-product-request.interface";

// *ok
export const getVstelleAdr = (
  formData: SalesApplicationRouteState["formData"]
) => ({
  STREET: formData.salesApplicationDeliveryStep.deliveryAddress.street?.LINE, // Priority: 2
  HOUSE_NUM: formData.salesApplicationDeliveryStep.deliveryAddress.houseNumber, // Priority: 2
  HOUSE_NUM2:
    formData.salesApplicationDeliveryStep.deliveryAddress.additionalAddress, // Priority: 1
  POST_CODE1:
    formData.salesApplicationDeliveryStep.deliveryAddress.addressResponse // Priority: 2
      ?.POST_CODE1,
  CITY:
    formData.salesApplicationDeliveryStep.deliveryAddress.addressResponse?.LINE, // Priority: 2
});

// *ok
export const getPersonData = (
  formData: SalesApplicationRouteState["formData"]
) => ({
  TITLE: formData.salesApplicationPersonalDetailsStep.salutation?.TITLE || "", // Priority: 2
  LAST_NAME: formData.salesApplicationPersonalDetailsStep.lastName, // Priority: 2
  FIRST_NAME: formData.salesApplicationPersonalDetailsStep.firstName, // Priority: 2
  BIRTHDATE: formData.salesApplicationPersonalDetailsStep.birthDate, // Priority: 2
  PHONE: formData.salesApplicationPersonalDetailsStep.phone, // Priority: 1
  EMAIL: formData.salesApplicationPersonalDetailsStep.emailAddress, // Priority: 2
});

/*
 * // ?Title: See CR: EVOW-302 -> Remove title for business customers
 */

export const getType = (formData: SalesApplicationRouteState["formData"]) =>
  formData.type;

export const getContract = (formData: SalesApplicationRouteState["formData"]) =>
  formData.contract;

export const getContractId = (
  formData: SalesApplicationRouteState["formData"]
) => formData.contractId;

export const getDeliveryStart = (
  formData: SalesApplicationRouteState["formData"]
) => formData.salesApplicationDeliveryStep.deliveryStart;

export const getProductId = (
  formData: SalesApplicationRouteState["formData"]
) => formData.salesApplicationTariffStep.product?.id;

export const getConsmptSpart = (
  formData: SalesApplicationRouteState["formData"],
  salesApplicationHsasDoubleTariffMeter: boolean
) => [
  {
    ISS_CONSMPT_SPART: compact([
      // Priority: 2
      formData.salesApplicationTariffStep.product?.highTariff && {
        ZWART: MeterType.HIGH_TARIFF,
        CONSMPT: formData.salesApplicationTariffStep.product.highTariff,
        SPARTE: formData.salesApplicationTariffStep.product?.divisionType,
        ANLART: formData.salesApplicationTariffStep.product?.heatingType,
      },
      formData.salesApplicationTariffStep.product?.lowTariff && {
        ZWART: MeterType.LOW_TARIFF,
        CONSMPT: formData.salesApplicationTariffStep.product.lowTariff,
        SPARTE: formData.salesApplicationTariffStep.product?.divisionType,
        ANLART: formData.salesApplicationTariffStep.product?.heatingType,
      },
      !salesApplicationHsasDoubleTariffMeter &&
        formData.salesApplicationTariffStep.product?.consumption && {
          CONSMPT: formData.salesApplicationTariffStep.product.consumption,
          SPARTE: formData.salesApplicationTariffStep.product?.divisionType,
          ANLART: formData.salesApplicationTariffStep.product?.heatingType,
        },
    ]),
  },
];

export const getOrgData = (
  formData: SalesApplicationRouteState["formData"]
) => ({
  TITLE: "0003", // Priority: 2 || '0003': Firma
  ORG_NAME: formData.salesApplicationPersonalDetailsStep.companyName, // Priority: 2
  ORG_NAME2: formData.salesApplicationPersonalDetailsStep.companySuffix, // Priority: 1
  BRANCHE: formData.salesApplicationPersonalDetailsStep.branchType?.IND_SECTOR, // Priority: 2
  PHONE: formData.salesApplicationPersonalDetailsStep.phone, // Priority: 1
  EMAIL: formData.salesApplicationPersonalDetailsStep.emailAddress, // Priority: 2
});

// *ok
export const getBillAltAdr = (
  formData: SalesApplicationRouteState["formData"],
  isBusinessCustomer: boolean
) => ({
  TITLE:
    formData.salesApplicationDeliveryStep.billingAddress.salutation?.TITLE ||
    "", // Priority: 2
  LAST_NAME: !isBusinessCustomer
    ? formData.salesApplicationDeliveryStep.billingAddress.lastName
    : "", // Priority: 2
  FIRST_NAME: !isBusinessCustomer
    ? formData.salesApplicationDeliveryStep.billingAddress.firstName
    : "", // Priority: 2
  ORG_NAME: formData.salesApplicationPersonalDetailsStep.companyName, // Priority: 2
  ORG_NAME2: formData.salesApplicationPersonalDetailsStep.companySuffix, // Priority: 1
  STREET: formData.salesApplicationDeliveryStep.billingAddress.street?.LINE, // Priority: 2
  HOUSE_NUM: formData.salesApplicationDeliveryStep.billingAddress.houseNumber, // Priority: 2
  POST_CODE1:
    formData.salesApplicationDeliveryStep.billingAddress.addressResponse
      ?.POST_CODE1, // Priority: 2
  POST_CODE2: formData.salesApplicationDeliveryStep.billingAddress.mailboxNumber
    ? formData.salesApplicationDeliveryStep.billingAddress.addressResponse
        ?.POST_CODE1
    : "",
  CITY:
    formData.salesApplicationDeliveryStep.billingAddress.addressResponse?.LINE, // Priority: 2
  PO_BOX:
    formData.salesApplicationDeliveryStep.billingAddress.mailboxNumber &&
    !formData.salesApplicationDeliveryStep.billingAddress.street
      ? formData.salesApplicationDeliveryStep.billingAddress.mailboxNumber
      : "", // Priority: 0
});

// *ok
export const getContactAltAdr = (
  formData: SalesApplicationRouteState["formData"]
) => {
  const contactPersonSalutation =
    formData.salesApplicationPersonalDetailsStep.contactPersonSalutation
      ?.TITLE ?? null;

  return {
    ...getPersonData(formData),
    ...(contactPersonSalutation && {
      TITLE: contactPersonSalutation,
    }),
  };
};

// *ok
export const getMeterReadings = (
  formData: SalesApplicationRouteState["formData"]
) => {
  const ISS_MTR = [];

  if (
    store.getters.isHeatingTariffType &&
    store.getters.salesApplicationTariffFormData.product?.heatingType ===
      HeatingType.NightStorage
  ) {
    ISS_MTR.push(
      {
        SERIALNO: formData.salesApplicationDeliveryStep.meterNumber, // Priority: 2
        READINGRESULT: formData.salesApplicationDeliveryStep.meterReadingHT, // Priority: 1
        ACTUALMRDATE: formData.salesApplicationDeliveryStep.dateMeterReading, // Priority: 0
        ZWART: MeterType.HIGH_TARIFF, // Priority: 0
      },
      {
        SERIALNO: formData.salesApplicationDeliveryStep.meterNumber, // Priority: 2
        READINGRESULT: formData.salesApplicationDeliveryStep.meterReadingNT, // Priority: 1
        ACTUALMRDATE: formData.salesApplicationDeliveryStep.dateMeterReading, // Priority: 0
        ZWART: MeterType.LOW_TARIFF, // Priority: 0
      }
    );
  } else {
    ISS_MTR.push({
      SERIALNO: formData.salesApplicationDeliveryStep.meterNumber, // Priority: 2
      READINGRESULT: formData.salesApplicationDeliveryStep.meterReading, // Priority: 1
      ACTUALMRDATE: formData.salesApplicationDeliveryStep.dateMeterReading, // Priority: 0
    });
  }

  return [
    // Priority: 0
    {
      ISS_MTR,
    },
  ];
};

// ?check
export const getProductSell = (
  formData: SalesApplicationRouteState["formData"],
  salesApplicationHsasDoubleTariffMeter: boolean,
  salesApplicationIsChangeOfSupplier: boolean,
  productOptions?: ProdOptionsRequest[]
) => ({
  ISS_PROD_SELL: [
    {
      PRODUCT_ID: formData.salesApplicationTariffStep.product?.id, // Priority: 2
      SPARTE: formData.salesApplicationTariffStep.product?.divisionType, // Priority: 2
      CONSMPT_SPART: getConsmptSpart(
        formData,
        salesApplicationHsasDoubleTariffMeter
      ),
      OLDSERVPROV: salesApplicationIsChangeOfSupplier
        ? formData.salesApplicationDeliveryStep.previousSupplierData
            ?.SERVICEID ||
          formData.salesApplicationDeliveryStep.previousSupplier
        : "", // Priority: 2
      METER_READINGS: getMeterReadings(formData),
      PROD_OPTIONS: productOptions ?? [],
      START_OF_DELIVERY: salesApplicationIsChangeOfSupplier
        ? formData.salesApplicationDeliveryStep.deliveryStart
        : "", // Priority: 2
      OLD_CUSTOMER_NUMBER:
        formData.salesApplicationDeliveryStep.previousCustomerNumber, // Priority: 1
      CANCEL_DATE: formData.salesApplicationDeliveryStep.terminationDate, // Priority: 1,
      BBP_AMOUNT: get(
        formData.salesApplicationTariffStep,
        "product.monthlyDiscount[0].NETTO",
        ""
      ), // Priority: 2
      // SPONSORSHIP_ID: getSponsorshipId(formData), // Priority: 1
      MALO_ID: formData.salesApplicationDeliveryStep.maLoID, // Priority: 1

      READINGRESULT: formData.salesApplicationDeliveryStep.meterReading,
      CONSUMPTION: formData.salesApplicationTariffStep.product?.consumption,
      SERIALNO: formData.salesApplicationDeliveryStep.meterNumber,
    },
  ],
});

// *ok
export const getMoveIn = (
  formData: SalesApplicationRouteState["formData"]
) => ({
  PREV_TENANT_LNAME: formData.salesApplicationDeliveryStep.previousTenant,
  METER_READINGS: getMeterReadings(formData),
});

// *ok
export const getApplicationToken = (
  formData: SalesApplicationRouteState["formData"]
) => formData.token;

// *ok
export const getBankData = (
  formData: SalesApplicationRouteState["formData"]
) => ({
  SWIFT: formData.salesApplicationPaymentStep.swift, // Priority: 1
  IBAN: formData.salesApplicationPaymentStep.iban
    .replace(/\s/g, "")
    .toUpperCase(), // Priority: 2
  KOINH: formData.salesApplicationPaymentStep.accountHolder, // Priority: 2
  XEZER: formData.salesApplicationPaymentStep.doc ? "X" : "", // Priority: 2
});

// *ok
export const getOptin = (formData: SalesApplicationRouteState["formData"]) =>
  formData.salesApplicationCompleteStep.informationAndOffersAccepted ? "X" : ""; // Priority: 2

// *ok
export const getAgb = (formData: SalesApplicationRouteState["formData"]) =>
  formData.salesApplicationCompleteStep.declarationsOfConsentAccepted
    ? "X"
    : ""; // Priority: 2

// *ok
export const getMoveindate = (
  formData: SalesApplicationRouteState["formData"]
  // salesApplicationIsChangeOfSupplier: boolean
) => formData.salesApplicationDeliveryStep.deliveryStart ?? "";

// *ok
export const getProcess = (formData: SalesApplicationRouteState["formData"]) =>
  formData.salesApplicationDeliveryStep.deliveryMethod; // Priority: 2

// *ok
export const getPostOption = (
  formData: SalesApplicationRouteState["formData"]
) =>
  formData.salesApplicationDeliveryStep.billingAddress.mailboxNumber ? "X" : ""; // Priority: 1

// *ok
// ?Info: This flag should always be filled at this time (EVOW-472)
export const getAutoSalesApplication = () =>
  // formData: SalesApplicationRouteState["formData"]
  {
    // return formData.salesApplicationTariffStep.product?.onlinereg ? "X" : ""; // Priority: 2
    return "X";
  };

export const replaceStringWithCharUntil = (
  value: string,
  char = "X",
  num = 6
) => {
  const replacableNumbers = Math.min(value.length, num);
  // get last n digits from iban
  const visibleNumbers = value.slice(-replacableNumbers);
  // replace everything other than space with an X
  const string = value.replace(/[^\s]/g, char);

  return (
    string.substring(0, string.length - replacableNumbers) + visibleNumbers
  );
};
// export const getAffiliateId = (
//   formData: SalesApplicationRouteState["formData"]
// ) =>
//   formData.salesApplicationFundingProjectsStep.affiliateId
//     ? formData.salesApplicationFundingProjectsStep.fundingProject.affiliateId
//     : "" ;

// export const getSponsorshipId = (
//   formData: SalesApplicationRouteState["formData"]
// ) =>
//   get(
//     formData.salesApplicationFundingProjectsStep.fundingProject.serviceNo,
//     store.getters.salesApplicationRouteTariffType,
//     ""
//   );
