import { get } from "lodash-es";
import { Bnka } from "../@types";
import { getBankNameAction } from "../actions";
import { getErrorMessage } from "./messages.helper";

export interface BankData {
  bankName: Bnka["BANKA"];
  swift: Bnka["SWIFT"];
  bankCode: Bnka["BNKLZ"];
}

export const getBankData = async (iban: string) => {
  const response = await getBankNameAction(iban);
  const error = getErrorMessage(response);
  const data = get(response, "0.DATA.BNKA", {}) as Bnka;

  const bankData: BankData = {
    bankName: data.BANKA,
    swift: data.SWIFT,
    bankCode: data.BNKLZ,
  };

  return {
    bankData,
    errorMsg: error,
  };
};
