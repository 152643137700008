import { CalculatorMode, CustomerType } from "@/api/sap";
import { defineGetters } from "direct-vuex";
import { findLastIndex, get, isEmpty, kebabCase } from "lodash-es";
import {
  registrationRouteModuleGetterContext,
  RegistrationRouteState,
} from ".";
import { RegistrationStepState } from "../components/RegistrationStep";
import { DeliveryType } from "../components/RegistrationSteps/steps/RegistrationDeliveryStep";

export enum GetterType {
  shouldShowRegistrationRoute = "shouldShowRegistrationRoute",
  registrationTariffFormData = "registrationTariffFormData",
  registrationPersonalDetailsFormData = "registrationPersonalDetailsFormData",
  registrationPaymentFormData = "registrationPaymentFormData",
  registrationDeliveryFormData = "registrationDeliveryFormData",
  registrationCompleteFormData = "registrationCompleteFormData",
  currentRegistrationStep = "currentRegistrationStep",
  isLastRegistrationStepActive = "isLastRegistrationStepActive",
  isChangeOfSupplier = "isChangeOfSupplier",
  isHeatingTariffType = "isHeatingTariffType",
  showHTNTMeterReading = "showHTNTMeterReading",
  // -- //
  registrationRouteHasProductSelected = "registrationRouteHasProductSelected",
  registrationRouteCustomerType = "registrationRouteCustomerType",
  registrationRouteIsBusinessCustomer = "registrationRouteIsBusinessCustomer",
  registrationRouteCurrentStepId = "registrationRouteCurrentStepId",
  registrationRouteTariffType = "registrationRouteTariffType",
  registrationRouteTariffTypeText = "registrationRouteTariffTypeText",
}

export default defineGetters<RegistrationRouteState>()({
  [GetterType.shouldShowRegistrationRoute](...args): boolean {
    const { state, getters } = registrationRouteModuleGetterContext(args);
    const { showRegistrationRoute } = state;
    const { registrationRouteHasProductSelected } = getters;

    const shouldShowRegistrationRoute =
      showRegistrationRoute && registrationRouteHasProductSelected;

    return shouldShowRegistrationRoute;
  },
  [GetterType.registrationRouteHasProductSelected](...args): boolean {
    const { state } = registrationRouteModuleGetterContext(args);
    const { formData } = state;

    const hasProduct = !isEmpty(formData.registrationTariffStep.product);

    return hasProduct;
  },
  [GetterType.registrationTariffFormData](
    ...args
  ): RegistrationRouteState["formData"]["registrationTariffStep"] {
    const { state } = registrationRouteModuleGetterContext(args);
    const { formData } = state;

    return formData.registrationTariffStep;
  },
  [GetterType.registrationPersonalDetailsFormData](
    ...args
  ): RegistrationRouteState["formData"]["registrationPersonalDetailsStep"] {
    const { state } = registrationRouteModuleGetterContext(args);
    const { formData } = state;

    return formData.registrationPersonalDetailsStep;
  },
  [GetterType.registrationPaymentFormData](
    ...args
  ): RegistrationRouteState["formData"]["registrationPaymentStep"] {
    const { state } = registrationRouteModuleGetterContext(args);
    const { formData } = state;

    return formData.registrationPaymentStep;
  },
  [GetterType.registrationDeliveryFormData](
    ...args
  ): RegistrationRouteState["formData"]["registrationDeliveryStep"] {
    const { state } = registrationRouteModuleGetterContext(args);
    const { formData } = state;

    return formData.registrationDeliveryStep;
  },
  [GetterType.registrationCompleteFormData](
    ...args
  ): RegistrationRouteState["formData"]["registrationCompleteStep"] {
    const { state } = registrationRouteModuleGetterContext(args);
    const { formData } = state;

    return formData.registrationCompleteStep;
  },
  [GetterType.currentRegistrationStep](...args): number {
    const { state } = registrationRouteModuleGetterContext(args);
    const { steps } = state;

    return findLastIndex(
      steps,
      (step) => step.state === RegistrationStepState.active
    );
  },
  [GetterType.isLastRegistrationStepActive](...args): boolean {
    const { state, getters } = registrationRouteModuleGetterContext(args);
    const { steps } = state;
    const { currentRegistrationStep } = getters;

    return currentRegistrationStep === steps.length - 1;
  },
  [GetterType.isChangeOfSupplier](...args): boolean {
    const { state } = registrationRouteModuleGetterContext(args);
    const {
      formData: { registrationDeliveryStep },
    } = state;

    return (
      registrationDeliveryStep.deliveryMethod ===
      DeliveryType.CHANGE_OF_SUPPLIER
    );
  },
  [GetterType.registrationRouteCustomerType](...args): CustomerType {
    const { state } = registrationRouteModuleGetterContext(args);
    const {
      formData: {
        registrationTariffStep: { product },
      },
    } = state;

    return product?.customerType ?? CustomerType.PrivateCustomer;
  },
  [GetterType.registrationRouteIsBusinessCustomer](...args): boolean {
    const { getters } = registrationRouteModuleGetterContext(args);
    const { registrationRouteCustomerType } = getters;

    return registrationRouteCustomerType === CustomerType.BusinessCustomer;
  },
  [GetterType.registrationRouteCurrentStepId](...args): string {
    const { state, getters } = registrationRouteModuleGetterContext(args);
    const { steps } = state;
    const { currentRegistrationStep } = getters;

    return get(steps, `${currentRegistrationStep}.id`, "");
  },
  [GetterType.registrationRouteTariffType](...args): string {
    const { getters } = registrationRouteModuleGetterContext(args);
    const { registrationTariffFormData } = getters;
    const { product } = registrationTariffFormData;

    return kebabCase(get(product, "calculatorMode", ""));
  },
  [GetterType.isHeatingTariffType](...args): boolean {
    const { getters } = registrationRouteModuleGetterContext(args);
    const { registrationRouteTariffType } = getters;

    return registrationRouteTariffType === CalculatorMode.Heating;
  },
  [GetterType.showHTNTMeterReading](...args): boolean {
    const { getters } = registrationRouteModuleGetterContext(args);
    const { isHeatingTariffType, registrationTariffFormData } = getters;

    const hasHTNT =
      registrationTariffFormData.product?.highTariff &&
      registrationTariffFormData.product?.lowTariff;

    const showHTNTMeterReading = isHeatingTariffType && hasHTNT;

    return showHTNTMeterReading;
  },
  [GetterType.registrationRouteTariffTypeText](...args): string {
    const { getters } = registrationRouteModuleGetterContext(args);
    const { registrationRouteTariffType } = getters;

    switch (registrationRouteTariffType) {
      case CalculatorMode.Electricity:
        return "Strom";
      case CalculatorMode.Gas:
        return "Gas";
      case CalculatorMode.Heating:
        return "Heizstrom";
      default:
        return "";
    }
  },
});
