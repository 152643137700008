import {
  Cs67IssTb038B,
  Cs67IssTsad3,
  Eservprovt,
  GetConfigurationData,
  StreetItem,
  transformAddress,
} from "@/api/sap";
// import {
//   FundingProject,
//   FundingProjectItem,
// } from "@/features/funding-projects/types";
import { GetProductType } from "@/features/tariff-calculator/state/getters";
import { BaseRadioButtonProps } from "@/shared/components/base/BaseRadioButton";
import {
  defineModule,
  localActionContext,
  localGetterContext,
} from "direct-vuex";
import { cloneDeep } from "lodash-es";
import { SalesApplicationRouteConfig } from "../components/SalesApplicationRoute";
import {
  SalesApplicationStepData,
  SalesApplicationStepState,
} from "../components/SalesApplicationStep";
import actions from "./actions";
import getters from "./getters";
import { ProductAbilityType } from "./helper/productStepAbilityFactory.helper";
import mutations from "./mutations";

export const salesApplicationRouteStateModuleId = "salesApplicationRoute";

export const enum SalesApplicationStepId {
  SALES_APPLICATION_TARIFF_STEP = "SalesApplicationTariffStep",
  // SALES_APPLICATION_FUNDING_PROJECTS_STEP = "SalesApplicationFundingProjectsStep",
  SALES_APPLICATION_PERSONAL_DETAILS_STEP = "SalesApplicationPersonalDetailsStep",
  SALES_APPLICATION_PAYMENT_STEP = "SalesApplicationPaymentStep",
  SALES_APPLICATION_DELIVERY_STEP = "SalesApplicationDeliveryStep",
  SALES_APPLICATION_COMPLETE_STEP = "SalesApplicationCompleteStep",
}

export interface SalesApplicationRouteState {
  timestamp: null | number;
  showSalesApplicationRoute: boolean;
  config: GetConfigurationData & SalesApplicationRouteConfig;
  steps: SalesApplicationStepData[];
  isLoadingGlobally: boolean;
  errors: string[];
  messages: string[];
  salesApplicationAbilities: ProductAbilityType;
  // fundingProjects: {
  //   projects: FundingProject[];
  //   categories: string[];
  // };
  formData: {
    token: string;
    type: string;
    contract: string;
    contractId: string;
    salesApplicationTariffStep: {
      product: null | GetProductType;
    };
    // salesApplicationFundingProjectsStep: {
    //   affiliateId: string;
    //   fundingProject: FundingProjectItem | Record<string, unknown>;
    //   loading: boolean;
    // };
    salesApplicationPersonalDetailsStep: {
      salutation: null | Cs67IssTsad3;
      firstName: string;
      lastName: string;
      birthDate: string;
      phone: string;
      emailAddress: string;
      emailAddressConfirm: string;
      contactPersonSalutation: null | Cs67IssTsad3;
      companyName: string;
      companySuffix: string;
      branchType: null | Cs67IssTb038B;
      accountCustomer: null | string;
    };
    salesApplicationPaymentStep: {
      paymentMethod: string;
      paymentMethodData: Record<any, unknown> | BaseRadioButtonProps;
      accountHolder: string;
      bankName: string;
      iban: string;
      swift: string;
      doc: boolean;
    };
    salesApplicationDeliveryStep: {
      deliveryMethod: string;
      deliveryMethodData: Record<any, unknown> | BaseRadioButtonProps;
      deliveryStart: string;
      previousTenant: string;
      previousSupplier: string;
      previousSupplierData: null | Eservprovt;
      previousCustomerNumber: string;
      meterNumber: string;
      maLoID: string;
      meterReading: string;
      meterReadingHT: string;
      meterReadingNT: string;
      dateMeterReading: string;
      cancellationType: string;
      cancellationTypeData: Record<any, unknown> | BaseRadioButtonProps;
      terminationDate: string;
      deliveryAddress: {
        addressResponse: null | ReturnType<typeof transformAddress>;
        street: null | StreetItem;
        houseNumber: null | string;
        additionalAddress: string;
      };
      differentBillingAddress: boolean;
      billingAddress: {
        addressResponse: null | ReturnType<typeof transformAddress>;
        salutation: null | Cs67IssTsad3;
        firstName: string;
        lastName: string;
        addressType: string;
        street: null | StreetItem;
        houseNumber: string;
        mailboxNumber: string;
      };
    };
    salesApplicationCompleteStep: {
      declarationsOfConsentAccepted: null | boolean;
      informationAndOffersAccepted: null | boolean;
    };
  };
}

export const initialSalesApplicationRouteState: SalesApplicationRouteState = {
  timestamp: null,
  showSalesApplicationRoute: true,
  isLoadingGlobally: true,
  errors: [],
  messages: [],
  // fundingProjects: {
  //   projects: [],
  //   categories: [],
  // },
  salesApplicationAbilities: {
    salesApplicationTarriffStep: {
      stepName: "salesApplicationTarriffStep",
      canEdit: false,
      canEditWhenEmpty: false,
      restrictedFields: [],
    },
    salesApplicationPersonalDataDetailsStep: {
      stepName: "salesApplicationPersonalDataDetailsStep",
      canEdit: false,
      canEditWhenEmpty: false,
      restrictedFields: [],
      canAdvertiseCustomer: false,
    },
    salesApplicationPaymentStep: {
      stepName: "salesApplicationPaymentStep",
      canEdit: false,
      canEditWhenEmpty: false,
      restrictedFields: [],
      canSwitchToSepa: false,
      hideAlternativePaymentType: false,
    },
    salesApplicationDeliveryStep: {
      stepName: "salesApplicationDeliveryStep",
      restrictedFields: [],
      canEdit: false,
      canEditWhenEmpty: false,
    },
  },
  config: {
    showFundingProjects: false,
    preventRedirect: false,
    links: {
      cancellationPolicy: "",
      privacyPolicy: "",
      creditAssessment: "",
      egbgbInformation: "",
      generalSupplyConditions: {
        electricity: "",
        gas: "",
      },
      supplementaryTerms: {
        electricity: "",
        gas: "",
      },
      successPages: {
        successMoveIn: "",
        successTariffChange: "",
        successContractChange: "",
      },
      errorPages: {
        errorPage: "",
        errorPageValid: "",
        errorPageAlreadyCompleted: "",
        errorPageLoading: "",
        errorPageBoniversum: "",
      },
    },
    NEUEINZUG_BIS: "",
    NEUEINZUG_VON: "",
    VERSORGERWECHSEL_BIS: "",
    VERSORGERWECHSEL_VON: "",
    VERBRAUCH_HT_PROZENT: "",
    VERBRAUCH_NT_PROZENT: "",
  },
  steps: [
    {
      id: SalesApplicationStepId.SALES_APPLICATION_TARIFF_STEP,
      headline: "Ihr Tarif",
      state: SalesApplicationStepState.unvisited,
    },
    // {
    //   id: SalesApplicationStepId.SALES_APPLICATION_FUNDING_PROJECTS_STEP,
    //   headline: "Förderprojekt wählen",
    //   state: SalesApplicationStepState.unvisited,
    // },
    {
      id: SalesApplicationStepId.SALES_APPLICATION_PERSONAL_DETAILS_STEP,
      headline: "Persönliche Angaben",
      businessCustomerHeadline: "Firmendaten",
      state: SalesApplicationStepState.unvisited,
    },
    {
      id: SalesApplicationStepId.SALES_APPLICATION_PAYMENT_STEP,
      headline: "Zahlungsweise",
      state: SalesApplicationStepState.unvisited,
    },
    {
      id: SalesApplicationStepId.SALES_APPLICATION_DELIVERY_STEP,
      headline: "Angaben zur Belieferung",
      state: SalesApplicationStepState.unvisited,
    },
    {
      id: SalesApplicationStepId.SALES_APPLICATION_COMPLETE_STEP,
      headline: "Fertigstellen",
      state: SalesApplicationStepState.unvisited,
      checked: true,
    },
  ],
  formData: {
    token: "",
    type: "",
    contract: "",
    contractId: "",
    salesApplicationTariffStep: {
      product: null,
    },
    // salesApplicationFundingProjectsStep: {
    //   affiliateId: "",
    //   fundingProject: {},
    //   loading: false,
    // },
    salesApplicationPersonalDetailsStep: {
      salutation: null,
      firstName: "",
      lastName: "",
      birthDate: "",
      phone: "",
      emailAddress: "",
      emailAddressConfirm: "",
      contactPersonSalutation: null,
      companyName: "",
      companySuffix: "",
      branchType: null,
      accountCustomer: "",
    },
    salesApplicationPaymentStep: {
      paymentMethod: "",
      paymentMethodData: {},
      accountHolder: "",
      bankName: "",
      iban: "",
      swift: "",
      doc: false,
    },
    salesApplicationDeliveryStep: {
      deliveryMethod: "",
      deliveryMethodData: {},
      deliveryStart: "",
      previousTenant: "",
      previousSupplier: "",
      previousSupplierData: null,
      previousCustomerNumber: "",
      meterNumber: "",
      maLoID: "",
      meterReading: "",
      meterReadingHT: "",
      meterReadingNT: "",
      dateMeterReading: "",
      cancellationType: "",
      cancellationTypeData: {},
      terminationDate: "",
      deliveryAddress: {
        addressResponse: null,
        street: null,
        houseNumber: null,
        additionalAddress: "",
      },
      differentBillingAddress: false,
      billingAddress: {
        addressResponse: null,
        salutation: null,
        firstName: "",
        lastName: "",
        addressType: "",
        street: null,
        houseNumber: "",
        mailboxNumber: "",
      },
    },
    salesApplicationCompleteStep: {
      declarationsOfConsentAccepted: null,
      informationAndOffersAccepted: null,
    },
  },
};

const salesApplicationRouteModule = defineModule({
  state: (): SalesApplicationRouteState =>
    cloneDeep(initialSalesApplicationRouteState),
  actions: {
    ...actions,
  },
  mutations: {
    ...mutations,
  },
  getters: {
    ...getters,
  },
});

export default salesApplicationRouteModule;

/* eslint-disable @typescript-eslint/no-explicit-any */
export const salesApplicationRouteModuleGetterContext = (
  args: [any, any, any, any]
) => localGetterContext(args, salesApplicationRouteModule);

export const salesApplicationRouteModuleActionContext = (context: any) =>
  localActionContext(context, salesApplicationRouteModule);
/* eslint-enable @typescript-eslint/no-explicit-any */
