var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LoadingOverlay',{attrs:{"isLoading":_vm.isLoading}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var _obj;

var valid = ref.valid;return [_c('div',{class:[
        _vm.$style.wrapper,
        _vm.$style[("wrapper--" + (_vm.stepData.state))],
        _vm.$style.transition,
        _vm.$style[_vm.stepData.state] ],attrs:{"id":_vm.stepData.id}},[_c('RegistrationStepCount',{class:[_vm.$style.count, _vm.$style.transition],attrs:{"state":_vm.stepData.state,"count":_vm.count,"checked":_vm.stepData.checked,"valid":_vm.stepData.locked || valid}}),(_vm.isEditable)?_c('EditButton',{staticClass:"absolute right-0 p-0 m-3",nativeOn:{"click":function($event){return (function () { return _vm.toggleEdit(_vm.index); })($event)}}}):_vm._e(),_c('div',{class:[
          _vm.$style.content,
          _vm.$style[("content--" + (_vm.stepData.state))],
          _vm.$style.transition,
          ( _obj = {}, _obj[_vm.$style['content--error']] = !_vm.stepData.locked &&
              !valid &&
              _vm.stepData.state === _vm.RegistrationStepState.visited, _obj ) ]},[_c('h2',{class:[_vm.$style.headline, _vm.$style[("headline--" + (_vm.stepData.state))]]},[_vm._v(" "+_vm._s(_vm.headline)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContent),expression:"showContent"}],staticClass:"mt-5 md:mt-11"},[_vm._t("default"),(_vm.isVisited)?_c('RegistrationStepIndicator',{attrs:{"valid":_vm.stepData.locked || valid}}):_vm._e()],2)])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }