








import { defineComponent, PropType, computed } from "@vue/composition-api";

import { SalesApplicationStepState } from "../SalesApplicationStep";

export default defineComponent({
  name: "SalesApplicationStepContent",
  props: {
    state: {
      type: String as PropType<SalesApplicationStepState>,
      default: "default",
    },
  },
  setup() {
    const slotNames = computed(() => {
      const slotNames = [];

      for (const name in SalesApplicationStepState) {
        slotNames.push(name);
      }

      return slotNames;
    });

    return {
      slotNames,
    };
  },
});
