import { CustomerType, CustomerTypeFromValue, DivisionType } from "@/api/sap";
import { useWindowUtils } from "@/hooks/useWindowUtils.hook";
import store from "@/store";
import { camelCase, get, find } from "lodash-es";
import { compress, decompress } from "lz-string";
import { RegistrationRouteState } from "..";

export enum UpsellingStorage {
  NAME = "upselling",
}

export enum UpsellingStateKey {
  PREV_DIVISION_TYPE = "prevDivisionType",
  PERSONAL_DETAILS = "personalDetails",
  DELIVERY_DETAILS = "deliveryDetails",
}

interface UpsellingState {
  [UpsellingStateKey.PREV_DIVISION_TYPE]?: DivisionType;
  [UpsellingStateKey.PERSONAL_DETAILS]?: Partial<
    RegistrationRouteState["formData"]["registrationPersonalDetailsStep"]
  >;
  [UpsellingStateKey.DELIVERY_DETAILS]?: Partial<
    RegistrationRouteState["formData"]["registrationDeliveryStep"]
  >;
}

const defaultStorageState: UpsellingState = {
  [UpsellingStateKey.PREV_DIVISION_TYPE]: undefined,
} as const;

const compressedDefaultStorageState = compress(
  JSON.stringify(defaultStorageState)
);

export function upsellingHelper() {
  const upsellingStorage =
    sessionStorage.getItem(UpsellingStorage.NAME) ??
    compressedDefaultStorageState;

  const decompressedData =
    decompress(upsellingStorage) ??
    decompress(compressedDefaultStorageState) ??
    "{}";

  const parsedData = JSON.parse(decompressedData);

  let state = parsedData as UpsellingState;

  const setValue = async (key: keyof typeof UpsellingStateKey, value: any) => {
    state[UpsellingStateKey[key]] = value;
    await syncState();
  };

  const setState = async (value: UpsellingState) => {
    state = value;
    await syncState();
  };

  const syncState = async () => {
    const compressedData = await compress(JSON.stringify(state));
    sessionStorage.setItem(UpsellingStorage.NAME, compressedData);
  };

  const reset = async () => {
    sessionStorage.setItem(
      UpsellingStorage.NAME,
      compressedDefaultStorageState
    );
  };

  const resetSelectedOptions = async () => {
    const calculator = store.state.tariffCalculator;
    const products = get(calculator, "products", []);
    for (const product of products) {
      let options = get(product, ["PROD_OPTIONS", "ZEVO_PRODUCT_OPTION"], []);
      // Fallback for Array/Object foo in SAP Data
      options = Array.isArray(options) ? options : [options];

      for (const option of options) {
        let values = get(option, ["VALUES", "ZEVO_PRODUCT_OPTION_VALUE"], []);
        // Fallback for Array/Object foo in SAP Data
        values = Array.isArray(values) ? values : [values];
        const defaultOption = find(values, { DEFAULT_VALUE: "X" }) ?? {};
        const defaultValue = get(defaultOption, ["VALUE"], undefined);
        const productId = get(product, ["PRODUCT_ID"], undefined);
        const optionId = get(option, ["ID"], undefined);

        // Reset Option to default Value
        if (defaultValue && optionId && productId) {
          await store.dispatch.updateProductSelectedOption({
            productId,
            optionId,
            selectedValue: defaultValue,
          });
        }
      }
    }
  };

  const redirect = async ({
    success,
    alreadyCustomer = false,
  }: {
    success: boolean;
    alreadyCustomer?: boolean;
  }) => {
    const { redirect: windowRedirect } = useWindowUtils();

    const registrationRoute = store.state.registrationRoute;
    const links = registrationRoute.config.links;
    const successPages = links.successPages;
    const errorPage = links.errorPage;
    const currentProduct =
      registrationRoute.formData.registrationTariffStep.product;

    const customerType = camelCase(
      CustomerTypeFromValue[
        (currentProduct?.customerType ??
          CustomerType.PrivateCustomer) as CustomerType
      ]
    ) as keyof typeof successPages;

    const currentCalculatorMode = camelCase(
      registrationRoute.formData.registrationTariffStep.product?.calculatorMode
    ) as
      | keyof typeof successPages.privateCustomer
      | keyof typeof successPages.businessCustomer;

    const resultsURL = alreadyCustomer
      ? successPages[customerType]["alreadyCustomer"]
      : success
      ? successPages[customerType][currentCalculatorMode]
      : errorPage;

    if (success) {
      const divisionType = currentProduct?.divisionType;
      divisionType && (await setValue("PREV_DIVISION_TYPE", divisionType));
    }

    windowRedirect({ resultsURL });
  };

  const getUpsellingDataFromStorage = () => {
    const personalDetails = get(state, UpsellingStateKey.PERSONAL_DETAILS, {});
    const deliveryDetails = get(state, UpsellingStateKey.DELIVERY_DETAILS, {});

    return {
      personalDetails,
      deliveryDetails,
    };
  };

  return {
    state,
    setState,
    setValue,
    syncState,
    reset,
    resetSelectedOptions,
    redirect,
    getUpsellingDataFromStorage,
  };
}
