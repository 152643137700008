








import { defineComponent, PropType, computed } from "@vue/composition-api";

import { RegistrationStepState } from "../RegistrationStep";

export default defineComponent({
  name: "RegistrationStepContent",
  props: {
    state: {
      type: String as PropType<RegistrationStepState>,
      default: "default",
    },
  },
  setup() {
    const slotNames = computed(() => {
      const slotNames = [];

      for (const name in RegistrationStepState) {
        slotNames.push(name);
      }

      return slotNames;
    });

    return {
      slotNames,
    };
  },
});
