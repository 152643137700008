




import { defineComponent } from "@vue/composition-api";
import LoadingButton from "@/shared/components/LoadingButton";

export default defineComponent({
  name: "RegistrationStepSubmit",
  props: {
    title: {
      type: String,
      default: "weiter",
    },
  },
  components: {
    LoadingButton,
  },
});
