

























































import {
  defineComponent,
  computed,
  ref,
  PropType,
  watchEffect,
} from "@vue/composition-api";
import { ValidationObserver } from "vee-validate";

import store from "@/store";

import EditButton from "@/shared/components/EditButton";
import LoadingOverlay from "@/shared/components/LoadingOverlay";

import RegistrationStepCount from "../RegistrationStepCount";
import RegistrationStepIndicator from "../RegistrationStepIndicator";

import { useLoadingEvent } from "@/hooks/useLoadingEvent.hook";

import {
  RegistrationStepState,
  RegistrationStepProps,
} from "./registration-step.types";

export default defineComponent({
  name: "RegistrationStep",
  props: {
    index: {
      type: Number,
      required: true,
    },
    step: {
      type: Object as PropType<RegistrationStepProps>,
      required: true,
    },
  },
  components: {
    ValidationObserver,
    LoadingOverlay,
    EditButton,
    RegistrationStepCount,
    RegistrationStepIndicator,
  },
  setup(props, ctx) {
    const isBusinessCustomer = ref(
      store.getters.registrationRouteIsBusinessCustomer
    );

    const toggleEdit = computed(() =>
      props.step.customToggleEdit
        ? props.step.customToggleEdit
        : store.dispatch.registrationRouteToggleEdit
    );

    const stepData = computed(
      () => store.state.registrationRoute.steps[props.index]
    );

    const headline = computed(() => {
      const headline = stepData.value.headline;
      const businessCustomerHeadline = stepData.value.businessCustomerHeadline;

      if (!businessCustomerHeadline) {
        return headline;
      }

      return isBusinessCustomer.value ? businessCustomerHeadline : headline;
    });

    const count = computed(() => props.index + 1);

    const isEditable = computed(
      () =>
        stepData.value.state === RegistrationStepState.visited &&
        !props.step.locked
    );

    const isActive = computed(
      () => stepData.value.state === RegistrationStepState.active
    );

    const isVisited = computed(
      () => stepData.value.state === RegistrationStepState.visited
    );

    const showContent = computed(
      () =>
        stepData.value.state === RegistrationStepState.active ||
        stepData.value.state === RegistrationStepState.visited
    );

    const { componentIsLoading } = useLoadingEvent({
      root: ctx.root,
      componentID: stepData.value.id,
    });

    watchEffect(() => {
      if (isActive.value) {
        ctx.root.$nextTick(() => {
          if (isActive.value) {
            const el = document.getElementById(stepData.value.id);

            if (el) {
              window.scrollTo({
                top: window.pageYOffset + el.getBoundingClientRect().top - 250,
                behavior: "smooth",
              });
            }
          }
        });
      }
    });

    return {
      RegistrationStepState,
      count,
      isEditable,
      isVisited,
      isLoading: componentIsLoading,
      showContent,
      stepData,
      toggleEdit,
      headline,
    };
  },
});
