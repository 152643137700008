import { ReadSingleProductPostData } from "@/api/sap/@types/read-single-product-request.interface";
import { generateTransformedProductOptionsForRequest } from "@/api/sap/helper/products.helper";
import { Cs67IssProductInfo } from "../@types";
import { DivisionType } from "@/api/sap";

export const generatePriceForProductOptionsRequestData = (
  context: any,
  product: Cs67IssProductInfo
): ReadSingleProductPostData => {
  const {
    state: { lastRequest: calculatorData },
  } = context;
  const { address } = calculatorData;
  const city = address?.CITY1 || address?.LINE;

  // TODO: Check what about heating if relevant anytime in the future for baukastentarif
  const consumption =
    calculatorData?.calculatorMode === "Gas"
      ? calculatorData?.gasConsumption
      : calculatorData?.powerConsumption;

  return {
    PTTR_ID: product?.PRODUCT_ID,
    CONSMPT_SPART: [
      {
        ISS_CONSMPT_SPART: [
          {
            CONSMPT: consumption,
            SPARTE:
              DivisionType[
                calculatorData?.calculatorMode as keyof typeof DivisionType
              ],
          },
        ],
      },
    ],
    POST_CODE: address?.POST_CODE1,
    CITY: city,
    PROD_OPTIONS: generateTransformedProductOptionsForRequest(product),
    // Fields not possible to get at this moment as these values get set by customer in personal data step
    // DATUM: '',
    // CITY_CODE: '',
    // STREET: '',
    // HOUSE_NUM: '',
    // PROD_OPTIONS: ProdOptions[];
  };
};
