import { CalculatorMode, DivisionType } from "@/api/sap";
import { Cs67IssProductInfo } from "@/api/sap/@types/read-products-response.interface";
import {
  defineModule,
  localActionContext,
  localGetterContext,
} from "direct-vuex";
import { TariffCalculatorConfig } from "../components/TariffCalculator";
import actions from "./actions";
import { products as dummyProducts } from "./dummy-data/products.dummy";
import getters from "./getters";
import mutations from "./mutations";

const useDummyData = false;

export const tariffCalculatorStateModuleId = "tariffCalculator";

export interface TariffCalculatorState {
  config: TariffCalculatorConfig | Record<string, unknown>;
  currentMode: keyof typeof DivisionType | "";
  loading: boolean;
  errors: string[];
  products: Cs67IssProductInfo[];
  lastRequest: Record<string, unknown>;
  availableCalculators: { mode: keyof typeof CalculatorMode }[];
}

export const initialTariffCalculatorState: TariffCalculatorState = {
  config: {},
  currentMode: "",
  loading: false,
  errors: [],
  // eslint-disable-next-line
  products: useDummyData ? (dummyProducts as any[]) : [],
  lastRequest: {},
  availableCalculators: [],
};

const tariffCalculatorModule = defineModule({
  state: (): TariffCalculatorState => {
    return initialTariffCalculatorState;
  },
  actions,
  mutations,
  getters,
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const tariffCalculatorModuleActionContext = (context: any) =>
  localActionContext(context, tariffCalculatorModule);

export const tariffCalculatorModuleGetterContext = (
  args: [any, any, any, any]
) => localGetterContext(args, tariffCalculatorModule);
/* eslint-enable @typescript-eslint/no-explicit-any */

export default tariffCalculatorModule;
