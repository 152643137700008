export interface StepAbility {
  stepName: string;
  canEdit: boolean;
  canEditWhenEmpty: boolean;
  restrictedFields: string[];
}

export interface PersonalDataStepAbility extends StepAbility {
  canAdvertiseCustomer: boolean;
}

export interface PaymentStepAbility extends StepAbility {
  canSwitchToSepa: boolean;
  hideAlternativePaymentType: boolean;
}

export interface ProductAbilityType {
  salesApplicationTarriffStep: StepAbility;
  salesApplicationPersonalDataDetailsStep: PersonalDataStepAbility;
  salesApplicationPaymentStep: PaymentStepAbility;
  salesApplicationDeliveryStep: StepAbility;
}

export const ProductStepAbilityFactory = (
  productType: string
): ProductAbilityType => {
  switch (productType) {
    case "T":
      return TariffChangeAbility();
    case "V":
      return ProviderChangeAbility();
    case "N":
      return RelocationAbility();
    default:
      return FallbackAbility();
  }
};

// Abilities are defined like in: https://business-unicorns.atlassian.net/wiki/spaces/EVO/pages/2729213968/Vertriebsanmeldestrecke+inkl.+E-Mail+Best+tigung
const TariffChangeAbility = (): ProductAbilityType => {
  return {
    salesApplicationTarriffStep: {
      stepName: "salesApplicationTarriffStep",
      canEdit: false,
      canEditWhenEmpty: false,
      restrictedFields: [],
    },
    salesApplicationPersonalDataDetailsStep: {
      stepName: "salesApplicationPersonalDataDetailsStep",
      canEdit: false,
      canEditWhenEmpty: false,
      restrictedFields: [],
      canAdvertiseCustomer: false,
    },
    salesApplicationPaymentStep: {
      stepName: "salesApplicationPaymentStep",
      canEdit: false,
      canEditWhenEmpty: false,
      restrictedFields: [],
      canSwitchToSepa: false,
      hideAlternativePaymentType: true,
    },
    salesApplicationDeliveryStep: {
      stepName: "salesApplicationDeliveryStep",
      canEdit: true,
      canEditWhenEmpty: false,
      restrictedFields: [
        "houseNumber",
        "street",
        "firstName",
        "lastName",
        "addressResponse",
        "addressType",
        "mailboxNumber",
        "additionalAddress",
        "deliveryStart",
      ],
    },
  };
};

const ProviderChangeAbility = (): ProductAbilityType => {
  return {
    salesApplicationTarriffStep: {
      stepName: "salesApplicationTarriffStep",
      canEdit: false,
      canEditWhenEmpty: true,
      restrictedFields: [],
    },
    salesApplicationPersonalDataDetailsStep: {
      stepName: "salesApplicationPersonalDataDetailsStep",
      canEdit: true,
      canEditWhenEmpty: true,
      restrictedFields: ["emailAddress"],
      canAdvertiseCustomer: false,
    },
    salesApplicationPaymentStep: {
      stepName: "salesApplicationPaymentStep",
      canEdit: true,
      canEditWhenEmpty: true,
      restrictedFields: [],
      canSwitchToSepa: true,
      hideAlternativePaymentType: false,
    },
    salesApplicationDeliveryStep: {
      stepName: "salesApplicationDeliveryStep",
      canEdit: true,
      canEditWhenEmpty: true,
      restrictedFields: [
        "houseNumber",
        "street",
        "firstName",
        "lastName",
        "addressResponse",
        "addressType",
        "mailboxNumber",
        "additionalAddress",
      ],
    },
  };
};

const RelocationAbility = (): ProductAbilityType => {
  return {
    salesApplicationTarriffStep: {
      stepName: "salesApplicationTarriffStep",
      canEdit: false,
      canEditWhenEmpty: true,
      restrictedFields: [],
    },
    salesApplicationPersonalDataDetailsStep: {
      stepName: "salesApplicationPersonalDataDetailsStep",
      canEdit: true,
      canEditWhenEmpty: true,
      restrictedFields: ["emailAddress"],
      canAdvertiseCustomer: false,
    },
    salesApplicationPaymentStep: {
      stepName: "salesApplicationPaymentStep",
      canEdit: true,
      canEditWhenEmpty: true,
      restrictedFields: [],
      canSwitchToSepa: true,
      hideAlternativePaymentType: false,
    },
    salesApplicationDeliveryStep: {
      stepName: "salesApplicationDeliveryStep",
      restrictedFields: [
        "houseNumber",
        "street",
        "firstName",
        "lastName",
        "addressResponse",
        "addressType",
        "mailboxNumber",
        "additionalAddress",
      ],
      canEdit: true,
      canEditWhenEmpty: true,
    },
  };
};

const FallbackAbility = (): ProductAbilityType => {
  return {
    salesApplicationTarriffStep: {
      stepName: "salesApplicationTarriffStep",
      canEdit: false,
      canEditWhenEmpty: true,
      restrictedFields: [],
    },
    salesApplicationPersonalDataDetailsStep: {
      stepName: "salesApplicationPersonalDataDetailsStep",
      canEdit: false,
      canEditWhenEmpty: true,
      restrictedFields: [],
      canAdvertiseCustomer: false,
    },
    salesApplicationPaymentStep: {
      stepName: "salesApplicationPaymentStep",
      canEdit: false,
      canEditWhenEmpty: true,
      restrictedFields: [],
      canSwitchToSepa: false,
      hideAlternativePaymentType: false,
    },
    salesApplicationDeliveryStep: {
      stepName: "salesApplicationDeliveryStep",
      restrictedFields: [],
      canEdit: false,
      canEditWhenEmpty: true,
    },
  };
};

export default ProductStepAbilityFactory;
