import {
  Cs67IssTb038B,
  Cs67IssTsad3,
  Eservprovt,
  GetConfigurationData,
  StreetItem,
  transformAddress,
} from "@/api/sap";
import {
  FundingProject,
  FundingProjectItem,
} from "@/features/funding-projects/types";
import { GetProductType } from "@/features/tariff-calculator/state/getters";
import { BaseRadioButtonProps } from "@/shared/components/base/BaseRadioButton";
import {
  defineModule,
  localActionContext,
  localGetterContext,
} from "direct-vuex";
import { cloneDeep } from "lodash-es";
import { RegistrationRouteConfig } from "../components/RegistrationRoute";
import {
  RegistrationStepData,
  RegistrationStepState,
} from "../components/RegistrationStep";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export const registrationRouteStateModuleId = "registrationRoute";

export const enum RegistrationStepId {
  REGISTRATION_TARIFF_STEP = "RegistrationTariffStep",
  REGISTRATION_FUNDING_PROJECTS_STEP = "RegistrationFundingProjectsStep",
  REGISTRATION_PERSONAL_DETAILS_STEP = "RegistrationPersonalDetailsStep",
  REGISTRATION_PAYMENT_STEP = "RegistrationPaymentStep",
  REGISTRATION_DELIVERY_STEP = "RegistrationDeliveryStep",
  REGISTRATION_COMPLETE_STEP = "RegistrationCompleteStep",
}

export interface RegistrationRouteState {
  timestamp: null | number;
  showRegistrationRoute: boolean;
  config: GetConfigurationData & RegistrationRouteConfig;
  steps: RegistrationStepData[];
  isLoadingGlobally: boolean;
  errors: string[];
  messages: string[];
  fundingProjects: {
    projects: FundingProject[];
    categories: string[];
  };
  formData: {
    registrationTariffStep: {
      product: null | GetProductType;
    };
    registrationFundingProjectsStep: {
      affiliateId: string;
      fundingProject: FundingProjectItem | Record<string, unknown>;
      loading: boolean;
    };
    registrationPersonalDetailsStep: {
      salutation: null | Cs67IssTsad3;
      firstName: string;
      lastName: string;
      birthDate: string;
      phone: string;
      emailAddress: string;
      emailAddressConfirm: string;
      contactPersonSalutation: null | Cs67IssTsad3;
      companyName: string;
      companySuffix: string;
      branchType: null | Cs67IssTb038B;
      accountCustomer: null | string;
    };
    registrationPaymentStep: {
      paymentMethod: string;
      paymentMethodData: Record<any, unknown> | BaseRadioButtonProps;
      accountHolder: string;
      bankName: string;
      iban: string;
      swift: string;
      doc: boolean;
    };
    registrationDeliveryStep: {
      deliveryMethod: string;
      deliveryMethodData: Record<any, unknown> | BaseRadioButtonProps;
      deliveryStart: string;
      previousTenant: string;
      previousSupplier: string;
      previousSupplierData: null | Eservprovt;
      previousCustomerNumber: string;
      meterNumber: string;
      maLoID: string;
      meterReading: string;
      meterReadingHT: string;
      meterReadingNT: string;
      dateMeterReading: string;
      cancellationType: string;
      cancellationTypeData: Record<any, unknown> | BaseRadioButtonProps;
      terminationDate: string;
      deliveryAddress: {
        addressResponse: null | ReturnType<typeof transformAddress>;
        street: null | StreetItem;
        houseNumber: null | string;
        additionalAddress: string;
      };
      differentBillingAddress: boolean;
      billingAddress: {
        addressResponse: null | ReturnType<typeof transformAddress>;
        salutation: null | Cs67IssTsad3;
        firstName: string;
        lastName: string;
        addressType: string;
        street: null | StreetItem;
        houseNumber: string;
        mailboxNumber: string;
      };
    };
    registrationCompleteStep: {
      declarationsOfConsentAccepted: null | boolean;
      informationAndOffersAccepted: null | boolean;
    };
  };
}

export const initialRegistrationRouteState: RegistrationRouteState = {
  timestamp: null,
  showRegistrationRoute: false,
  isLoadingGlobally: false,
  errors: [],
  messages: [],
  fundingProjects: {
    projects: [],
    categories: [],
  },
  config: {
    showFundingProjects: false,
    preventRedirect: false,
    links: {
      cancellationPolicy: "",
      privacyPolicy: "",
      creditAssessment: "",
      egbgbInformation: "",
      generalSupplyConditions: {
        electricity: "",
        gas: "",
      },
      supplementaryTerms: {
        electricity: "",
        gas: "",
      },
      successPages: {
        privateCustomer: {
          alreadyCustomer: "",
          electricity: "",
          gas: "",
          heating: "",
        },
        businessCustomer: {
          alreadyCustomer: "",
          electricity: "",
          gas: "",
          heating: "",
        },
      },
      errorPage: "",
    },
    NEUEINZUG_BIS: "",
    NEUEINZUG_VON: "",
    VERSORGERWECHSEL_BIS: "",
    VERSORGERWECHSEL_VON: "",
    VERBRAUCH_HT_PROZENT: "",
    VERBRAUCH_NT_PROZENT: "",
  },
  steps: [
    {
      id: RegistrationStepId.REGISTRATION_TARIFF_STEP,
      headline: "Ihr Tarif",
      state: RegistrationStepState.unvisited,
    },
    {
      id: RegistrationStepId.REGISTRATION_FUNDING_PROJECTS_STEP,
      headline: "Förderprojekt wählen",
      state: RegistrationStepState.unvisited,
    },
    {
      id: RegistrationStepId.REGISTRATION_PERSONAL_DETAILS_STEP,
      headline: "Persönliche Angaben",
      businessCustomerHeadline: "Firmendaten",
      state: RegistrationStepState.unvisited,
    },
    {
      id: RegistrationStepId.REGISTRATION_PAYMENT_STEP,
      headline: "Zahlungsweise",
      state: RegistrationStepState.unvisited,
    },
    {
      id: RegistrationStepId.REGISTRATION_DELIVERY_STEP,
      headline: "Angaben zur Belieferung",
      state: RegistrationStepState.unvisited,
    },
    {
      id: RegistrationStepId.REGISTRATION_COMPLETE_STEP,
      headline: "Fertigstellen",
      state: RegistrationStepState.unvisited,
      checked: true,
    },
  ],
  formData: {
    registrationTariffStep: {
      product: null,
    },
    registrationFundingProjectsStep: {
      affiliateId: "",
      fundingProject: {},
      loading: false,
    },
    registrationPersonalDetailsStep: {
      salutation: null,
      firstName: "",
      lastName: "",
      birthDate: "",
      phone: "",
      emailAddress: "",
      emailAddressConfirm: "",
      contactPersonSalutation: null,
      companyName: "",
      companySuffix: "",
      branchType: null,
      accountCustomer: "",
    },
    registrationPaymentStep: {
      paymentMethod: "",
      paymentMethodData: {},
      accountHolder: "",
      bankName: "",
      iban: "",
      swift: "",
      doc: false,
    },
    registrationDeliveryStep: {
      deliveryMethod: "",
      deliveryMethodData: {},
      deliveryStart: "",
      previousTenant: "",
      previousSupplier: "",
      previousSupplierData: null,
      previousCustomerNumber: "",
      meterNumber: "",
      maLoID: "",
      meterReading: "",
      meterReadingHT: "",
      meterReadingNT: "",
      dateMeterReading: "",
      cancellationType: "",
      cancellationTypeData: {},
      terminationDate: "",
      deliveryAddress: {
        addressResponse: null,
        street: null,
        houseNumber: null,
        additionalAddress: "",
      },
      differentBillingAddress: false,
      billingAddress: {
        addressResponse: null,
        salutation: null,
        firstName: "",
        lastName: "",
        addressType: "",
        street: null,
        houseNumber: "",
        mailboxNumber: "",
      },
    },
    registrationCompleteStep: {
      declarationsOfConsentAccepted: null,
      informationAndOffersAccepted: null,
    },
  },
};

const registrationRouteModule = defineModule({
  state: (): RegistrationRouteState => cloneDeep(initialRegistrationRouteState),
  actions: {
    ...actions,
  },
  mutations: {
    ...mutations,
  },
  getters: {
    ...getters,
  },
});

export default registrationRouteModule;

/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrationRouteModuleGetterContext = (
  args: [any, any, any, any]
) => localGetterContext(args, registrationRouteModule);

export const registrationRouteModuleActionContext = (context: any) =>
  localActionContext(context, registrationRouteModule);
/* eslint-enable @typescript-eslint/no-explicit-any */
