import { SapResponse } from "./sap-response.interface";

export type GetBranchtypesResponse = SapResponse<GetBranchtypesData>;

export interface GetBranchtypesData {
  BRANCHTYPES: Branchtypes;
}

export interface Branchtypes {
  "_-CS67_-ISS_TB038B": Cs67IssTb038B[];
}

export interface Cs67IssTb038B {
  SPRAS: Spras;
  ISTYPE: Istype;
  IND_SECTOR: string;
  TEXT: string;
}

enum Istype {
  Zevo = "ZEVO",
}
enum Spras {
  D = "D",
}
