






















import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "RegistrationOverviewDataTable",
  props: {
    headline: {
      type: String,
    },
    data: {
      type: Array as PropType<{ title: string; value: string }[]>,
    },
    wrapperClasses: {
      type: String,
      default: "",
    },
    valueClasses: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {};
  },
});
