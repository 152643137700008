import { find, get, head } from "lodash-es";
import {
  Address,
  CheckAddressResponse,
  Cs67IssAdrchkValues,
  Data,
  ResponsePaths,
} from "../@types";
import { checkAddressAction } from "../actions";
import { clean } from "@/helper/object.helper";

export const getAddress = (
  response: CheckAddressResponse
): Address | undefined => get(response, ResponsePaths.ADDRESS);

export const isNonUniqueAddress = (response: CheckAddressResponse) => {
  const nonUnique: Data["NON_UNIQUE"] = get(response, ResponsePaths.NON_UNIQUE);
  return nonUnique === "X";
};

export const getValues = (
  response: CheckAddressResponse
): Cs67IssAdrchkValues[] | Cs67IssAdrchkValues | Record<string, unknown> =>
  get(response, ResponsePaths.VALUES, {});

export const getPostalCode = (
  response: CheckAddressResponse,
  index: string | number = 1
) => {
  let defaultPostalCode = get(response, `${ResponsePaths.ADDRESS}.POST_CODE1`);
  let postalCode = get(
    response,
    `${ResponsePaths.ADDRESS}.POST_CODE${index}`,
    defaultPostalCode
  );

  defaultPostalCode =
    Array.isArray(defaultPostalCode) && defaultPostalCode.length === 0
      ? ""
      : defaultPostalCode;

  postalCode =
    Array.isArray(postalCode) && postalCode.length === 0
      ? defaultPostalCode
      : postalCode;

  return postalCode;
};

export const isInOberhausen = (response: CheckAddressResponse) =>
  get(response, ResponsePaths.OBERHAUSEN, undefined);

export const transformAddress = ({
  address,
  values,
  isInOberhausen,
}: {
  address: Address;
  values: Cs67IssAdrchkValues;
  isInOberhausen?: string;
}) => {
  const cityExt = Array.isArray(values.CITY_EXT) ? "" : values.CITY_EXT;
  const postalCode = get(address, "POST_CODE1");

  return {
    ...address,
    ...values,
    CITY_EXT: Array.isArray(values.CITY_EXT) ? "" : values.CITY_EXT,
    LABEL: `${postalCode} - ${values.LINE}${cityExt}`,
    OBERHAUSEN: isInOberhausen,
  };
};

export const getAddresses = (response: CheckAddressResponse) => {
  const address = getAddress(response) as Address;
  const values = getValues(response) as Cs67IssAdrchkValues;
  const isUniqueAddress = !isNonUniqueAddress(response);
  const addresses = [];

  if (isUniqueAddress) {
    addresses.push(
      transformAddress({
        address,
        values,
        isInOberhausen: isInOberhausen(response),
      })
    );
  }

  if (Array.isArray(values)) {
    values.forEach((value) => {
      addresses.push(
        transformAddress({
          address,
          values: value,
          isInOberhausen: isInOberhausen(response),
        })
      );
    });
  }

  return clean()(addresses);
};

export type GetAddressesReturnType = ReturnType<typeof getAddresses>[0];

export const getCityCodeFromPostalCode = async (
  postalCode: string,
  city = ""
) => {
  const response = await checkAddressAction(postalCode);
  const addresses = getAddresses(response);

  let address = find(addresses, { LINE: city });
  if (!address) {
    address = head(addresses);
  }

  const cityCode = get(address, "CODE");

  return cityCode;
};

export type StreetItem = Pick<Cs67IssAdrchkValues, "LINE" | "CODE">;

export const getStreets = async ({
  postalCode,
  city,
  cityCode,
  regionId,
}: {
  postalCode: string;
  city?: string;
  cityCode?: string;
  regionId?: string;
}): Promise<StreetItem[]> => {
  const code = cityCode
    ? cityCode
    : await getCityCodeFromPostalCode(postalCode, city);

  const checkAddressResponse = await checkAddressAction(postalCode, {
    CITY_CODE: code,
    ...(regionId && { REGION_ID: regionId }),
  });

  const streets = (getValues(
    checkAddressResponse
  ) as Cs67IssAdrchkValues[]).reduce(
    (allStreets: StreetItem[], streetItem: Cs67IssAdrchkValues) => [
      ...allStreets,
      { LINE: streetItem.LINE, CODE: streetItem.CODE },
    ],
    []
  );

  return streets;
};
