


























































import { defineComponent, computed } from "@vue/composition-api";

import store from "@/store";

import {
  RegistrationOverviewTextWithIconIndicator,
  RegistrationOverviewDataTable,
} from "../../../RegistrationOverview";

export default defineComponent({
  name: "RegistrationDeliveryStepOverview",
  components: {
    RegistrationOverviewTextWithIconIndicator,
    RegistrationOverviewDataTable,
  },
  setup() {
    const registrationDeliveryStepData = computed(
      () => store.state.registrationRoute.formData.registrationDeliveryStep
    );

    const tariffTypeText = computed(
      () => store.getters.registrationRouteTariffTypeText
    );

    const changeOfSupplierData = computed(() =>
      [
        {
          title: "Gewünschtes Lieferende beim bisherigen Versorger",
          value: registrationDeliveryStepData.value.deliveryStart
            ?.split("-")
            .reverse()
            .join(" / "),
        },
        {
          title: "Name des Vormieters",
          value: registrationDeliveryStepData.value.previousTenant,
        },
        {
          title: "Bisheriger Versorger",
          value: registrationDeliveryStepData.value.previousSupplier,
        },
        {
          title: "Bisherige Kundennummer",
          value: registrationDeliveryStepData.value.previousCustomerNumber,
        },
        {
          title: `${tariffTypeText.value}-Zählernummer`,
          value: registrationDeliveryStepData.value.meterNumber,
        },
        {
          title: "MaLo-ID",
          value: registrationDeliveryStepData.value.maLoID,
        },
        {
          title: "Zählerstand",
          value: registrationDeliveryStepData.value.meterReading,
        },
        {
          title: "Zählerstand (HT/NT)",
          value:
            registrationDeliveryStepData.value.meterReadingHT &&
            registrationDeliveryStepData.value.meterReadingNT &&
            `${registrationDeliveryStepData.value.meterReadingHT} / ${registrationDeliveryStepData.value.meterReadingNT}`,
        },
        {
          title: "Ablese Datum Zählerstand",
          value: registrationDeliveryStepData.value.dateMeterReading
            ?.split("-")
            .reverse()
            .join(" / "),
        },
      ].filter((item) => !!item.value)
    );

    const terminationTableData = computed(() =>
      [
        {
          title: "Kündigungsdatum",
          value: registrationDeliveryStepData.value.terminationDate
            ?.split("-")
            .reverse()
            .join(" / "),
        },
      ].filter((item) => !!item.value)
    );

    const deliveryAddressData = computed(() =>
      [
        {
          title: "Straße/Hausnr.",
          value:
            registrationDeliveryStepData.value.deliveryAddress.street?.LINE &&
            registrationDeliveryStepData.value.deliveryAddress.houseNumber &&
            `${registrationDeliveryStepData.value.deliveryAddress.street.LINE} ${registrationDeliveryStepData.value.deliveryAddress.houseNumber}`,
        },
        {
          title: "PLZ/Ort.",
          value:
            registrationDeliveryStepData.value.deliveryAddress.addressResponse
              ?.LABEL ?? "",
        },
        {
          title: "Hausnummerzusatz",
          value:
            registrationDeliveryStepData.value.deliveryAddress
              .additionalAddress,
        },
      ].filter((item) => !!item.value)
    );

    const billingAddressData = computed(() =>
      [
        {
          title: "Vorname/Nachname",
          value:
            registrationDeliveryStepData.value.billingAddress.firstName &&
            registrationDeliveryStepData.value.billingAddress.lastName &&
            `${registrationDeliveryStepData.value.billingAddress.firstName} ${registrationDeliveryStepData.value.billingAddress.lastName}`,
        },
        {
          title: "Straße/Hausnr.",
          value:
            registrationDeliveryStepData.value.billingAddress.street?.LINE &&
            registrationDeliveryStepData.value.billingAddress.houseNumber &&
            `${registrationDeliveryStepData.value.billingAddress.street.LINE} ${registrationDeliveryStepData.value.billingAddress.houseNumber}`,
        },
        {
          title: "Postfach-Nummer",
          value:
            registrationDeliveryStepData.value.billingAddress.mailboxNumber,
        },
        {
          title: "PLZ/Ort.",
          value:
            registrationDeliveryStepData.value.billingAddress.addressResponse
              ?.LABEL ?? "",
        },
      ].filter((item) => !!item.value)
    );

    return {
      changeOfSupplierData,
      registrationDeliveryStepData,
      terminationTableData,
      deliveryAddressData,
      billingAddressData,
      tariffTypeText,
    };
  },
});
