import { FundingProject } from "@/features/funding-projects/types";
import { defineMutations } from "direct-vuex";
import Vue from "vue";
import { RegistrationRouteState } from ".";
import {
  RegistrationStepData,
  RegistrationStepState,
} from "../components/RegistrationStep";

export enum MutationType {
  setConfigData = "setConfigData",
  setIsLoadingGlobally = "setIsLoadingGlobally",
  addRegistrationRouteError = "addRegistrationRouteError",
  resetRegistrationRouteErrors = "resetRegistrationRouteErrors",
  addRegistrationRouteMessage = "addRegistrationRouteMessage",
  resetRegistrationRouteMessage = "resetRegistrationRouteMessage",
  setFundingProjectsStep = "setFundingProjectsStep",
  setTariffStep = "setTariffStep",
  setPersonalDetails = "setPersonalDetails",
  setPaymentData = "setPaymentData",
  setDeliveryData = "setDeliveryData",
  setRegistrationCompleteData = "setRegistrationCompleteData",
  setSteps = "setSteps",
  setStepData = "setStepData",
  setStepState = "setStepState",
  setActiveStep = "setActiveStep",
  setShowRegistrationRoute = "setShowRegistrationRoute",
  resetRegistrationStepsState = "resetRegistrationStepsState",
  setRegistrationRouteTimestamp = "setRegistrationRouteTimestamp",
  setFundingProjects = "setFundingProjects",
  setFundingCategories = "setFundingCategories",
}

export default defineMutations<RegistrationRouteState>()({
  [MutationType.setConfigData](
    state,
    payload: Partial<RegistrationRouteState["config"]>
  ) {
    Vue.set(state, "config", {
      ...state.config,
      ...payload,
    });
  },
  [MutationType.setIsLoadingGlobally](state, isLoading: boolean) {
    state.isLoadingGlobally = isLoading;
  },
  [MutationType.addRegistrationRouteError](state, error: string) {
    Vue.set(state, "errors", [error, ...state.errors]);
  },
  [MutationType.resetRegistrationRouteErrors](state) {
    Vue.set(state, "errors", []);
  },
  [MutationType.addRegistrationRouteMessage](state, message: string) {
    Vue.set(state, "messages", [message, ...state.messages]);
  },
  [MutationType.resetRegistrationRouteMessage](state) {
    Vue.set(state, "messages", []);
  },
  [MutationType.setTariffStep](
    state,
    payload: Partial<
      RegistrationRouteState["formData"]["registrationTariffStep"]
    >
  ) {
    Vue.set(state.formData, "registrationTariffStep", {
      ...state.formData.registrationTariffStep,
      ...payload,
    });
  },
  [MutationType.setFundingProjectsStep](
    state,
    payload: Partial<
      RegistrationRouteState["formData"]["registrationFundingProjectsStep"]
    >
  ) {
    Vue.set(state.formData, "registrationFundingProjectsStep", {
      ...state.formData.registrationFundingProjectsStep,
      ...payload,
    });
  },
  [MutationType.setPersonalDetails](
    state,
    payload: Partial<
      RegistrationRouteState["formData"]["registrationPersonalDetailsStep"]
    >
  ) {
    Vue.set(state.formData, "registrationPersonalDetailsStep", {
      ...state.formData.registrationPersonalDetailsStep,
      ...payload,
    });
  },
  [MutationType.setPaymentData](
    state,
    payload: Partial<
      RegistrationRouteState["formData"]["registrationPaymentStep"]
    >
  ) {
    Vue.set(state.formData, "registrationPaymentStep", {
      ...state.formData.registrationPaymentStep,
      ...payload,
    });
  },
  [MutationType.setDeliveryData](
    state,
    payload: Partial<
      RegistrationRouteState["formData"]["registrationDeliveryStep"]
    >
  ) {
    Vue.set(state.formData, "registrationDeliveryStep", {
      ...state.formData.registrationDeliveryStep,
      ...payload,
    });
  },
  [MutationType.setRegistrationCompleteData](
    state,
    payload: Partial<
      RegistrationRouteState["formData"]["registrationCompleteStep"]
    >
  ) {
    Vue.set(state.formData, "registrationCompleteStep", {
      ...state.formData.registrationCompleteStep,
      ...payload,
    });
  },
  [MutationType.setSteps](
    state,
    payload: Partial<RegistrationRouteState["steps"]>
  ) {
    Vue.set(state, "steps", payload);
  },
  [MutationType.setStepData](
    state,
    payload: { index: number; stepData: Partial<RegistrationStepData> }
  ) {
    if (payload.index < state.steps.length) {
      const newStepsData = [...state.steps];
      newStepsData[payload.index] = {
        ...state.steps[payload.index],
        ...payload.stepData,
      };

      Vue.set(state, "steps", newStepsData);
    }
  },
  [MutationType.setStepState](
    state,
    payload: { index: number; state: RegistrationStepState }
  ) {
    if (payload.index < state.steps.length) {
      const newStepsData = [...state.steps];
      newStepsData[payload.index].state = payload.state;

      Vue.set(state, "steps", newStepsData);
    }
  },
  [MutationType.setActiveStep](state, index: number) {
    const newStepsData = [...state.steps];

    newStepsData.forEach((step, i) => {
      if (i < index) {
        step.state = RegistrationStepState.visited;
      } else if (i === index) {
        step.state = RegistrationStepState.active;
      }
    });

    Vue.set(state, "steps", newStepsData);
  },
  [MutationType.setShowRegistrationRoute](
    state,
    showRegistrationRoute: boolean
  ) {
    state.showRegistrationRoute = showRegistrationRoute;
  },
  [MutationType.resetRegistrationStepsState](state) {
    const newStepsData = [...state.steps];
    newStepsData.forEach((step) => {
      step.state = RegistrationStepState.unvisited;
    });

    Vue.set(state, "steps", newStepsData);
  },
  [MutationType.setRegistrationRouteTimestamp](state) {
    const timestamp = Date.now();
    state.timestamp = timestamp;
  },
  [MutationType.setFundingProjects](state, projects: FundingProject[]) {
    Vue.set(state.fundingProjects, "projects", projects);
  },
  [MutationType.setFundingCategories](state, categories: string[]) {
    Vue.set(state.fundingProjects, "categories", categories);
  },
});
