export function useRandomPlaceholder(
  options: (string | Record<string, unknown>)[]
) {
  const optionsLength = options.length;
  const randomIndex = Math.floor(Math.random() * optionsLength);
  const randomPlaceholderItem = options[randomIndex];
  const randomPlaceholderValue =
    typeof randomPlaceholderItem === "string"
      ? randomPlaceholderItem
      : randomPlaceholderItem.label ?? randomPlaceholderItem.value;
  const placeholder = `z.B. ${randomPlaceholderValue}`;

  return {
    placeholder,
  };
}
