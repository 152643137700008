import { get, set, find, camelCase, toNumber, indexOf } from "lodash-es";

import tariffCalculatorConfig from "@/features/tariff-calculator/config";
import {
  // getSelectableProductCombiOptions,
  isOptionBlacklisted,
  getTransformedProductOptions,
  getTransformedCombinedProductOptions,
} from "@/api/sap/helper/product-options.helper";

import {
  TransformedProductOption,
  // TransformedCombinedProductOption,
} from "@/api/sap/@types";

import {
  /* ProdOptions, ProdOptionsOld, ProdOptSel,*/ ProdOptSelRequest,
} from "@/api/sap/@types/read-single-product-request.interface";

import { CustomerType } from "@/api/sap";

import {
  ResponsePaths,
  ReadProductsResponse,
  Cs67IssProdDesc,
  ProdDescType,
  Cs67IssProductInfo,
  Cs67IssPriceInfo,
} from "../@types";

import { formatNumber } from "@/helper";
import { pluralizedWordWithAmount } from "@/helper/pluralize.helper";

export enum PriceType {
  WorkingPrice = "1",
  BasePrice = "2",
  BonusPrice = "3",
  AnnualCosts = "5",
  MonthlyDiscount = "6",
  MonthlyBasePrice = "8",
}

export enum PriceUnit {
  Brutto = "BRUTTO",
  Netto = "NETTO",
}

export enum DatePeriodType {
  Tag = 0,
  Monat = 1,
  Jahr = 2,
  Woche = 3,
}

export const getProducts = (response: ReadProductsResponse) => {
  const products = get(response, ResponsePaths.PRODUCTS, []);

  return Array.isArray(products) ? products : [products];
};

export const getProduct = (response: ReadProductsResponse) => {
  const product = get(response, ResponsePaths.PRODUCT, []);
  return product;
};

export const getProductDescriptionByType = (
  products: Cs67IssProdDesc[],
  type: ProdDescType
) => {
  let productDescription = [""];

  if (!Array.isArray(products)) {
    products = [products as Cs67IssProdDesc];
  }

  const productByType = find(
    products,
    (product) => product.PROD_DESC_TYPE === type
  );

  if (productByType) {
    if (type === ProdDescType.PROD_DESC) {
      productDescription = [
        ...productByType.PROD_DESC_CONTENT.replace(
          "** ",
          '<sup class="text-orange-500">**</sup> '
        )
          .split("<br>")
          .map((part) => part.trim()),
        ...productDescription,
      ];
    } else {
      productDescription = [
        productByType.PROD_DESC_CONTENT,
        ...productDescription,
      ];
    }
  }

  return productDescription;
};

export const getProductDescription = (
  products: Cs67IssProdDesc[]
): Record<string, unknown> => {
  const types = Object.keys(ProdDescType);
  let descriptionInfo = "";

  const productDescription = types.reduce(
    (
      productDescription: {
        [key: string]: string;
      },
      type
    ) => {
      const key = camelCase(type);
      const productDescriptionByType = getProductDescriptionByType(
        products,
        ProdDescType[type as keyof typeof ProdDescType]
      );

      if (type === ProdDescType.PROD_DESC) {
        descriptionInfo = get(productDescriptionByType, "1");
      }

      productDescription[key] = productDescriptionByType[0];

      return productDescription;
    },
    {}
  );

  return {
    descriptionInfo,
    ...productDescription,
  };
};

export const formatPricesForData = (
  prices: Cs67IssPriceInfo,
  paths: string[]
): void => {
  paths.forEach((path) => {
    const price = get(prices, path, "");

    set(prices, path, formatNumber(price));
  });
};

export const getPriceDataByType = (
  pricesData: Cs67IssPriceInfo[],
  priceType: PriceType
): Cs67IssPriceInfo[] | Partial<Cs67IssPriceInfo> => {
  const priceDataByType = pricesData.filter(
    (priceData) => priceData.PREIS_ART === priceType
  );

  if (!priceDataByType) {
    return {};
  }

  return priceDataByType;
};

export const getProductPrices = (prices: Cs67IssPriceInfo[]) => {
  const types = Object.keys(PriceType) as (keyof typeof PriceType)[];

  const productPrices = types.reduce(
    (
      productPrices: {
        [key: string]: ReturnType<typeof getPriceDataByType>;
      },
      type
    ) => {
      const key = camelCase(type);

      productPrices[key] = getPriceDataByType(
        prices,
        PriceType[type as keyof typeof PriceType]
      );

      return productPrices;
    },
    {}
  );

  return productPrices;
};

export const getContractDetails = (
  productData: Cs67IssProductInfo,
  customerType: string | unknown = "1"
) => {
  const isBusinessCustomer = customerType === CustomerType.BusinessCustomer;

  const initialTerm = toNumber(get(productData, "ERSTLAUF"));
  const initialTermPeriod = toNumber(get(productData, "ERSTPERIO"));
  const initialTermInfo = `${pluralizedWordWithAmount(
    DatePeriodType[initialTermPeriod],
    initialTerm
  )} ab Lieferbeginn`;

  const contractRenewal = toNumber(get(productData, "VERLAENG"));
  const contractPeriod = toNumber(get(productData, "VERPER"));
  let contractRenewalInfo = "auf unbestimmte Zeit";

  if (contractRenewal !== 1 && contractPeriod !== 0) {
    contractRenewalInfo = `${pluralizedWordWithAmount(
      DatePeriodType[contractPeriod],
      contractRenewal
    )} bei Nichtkündigung`;
  }

  const termOfNotice = toNumber(get(productData, "KFRIST"));
  const termOfNoticePeriod = toNumber(get(productData, "KUENPER"));
  const terminationDate = toNumber(get(productData, "KZP"));
  const timeOfNoticeFallback = isBusinessCustomer
    ? " zum Vertragsende"
    : " nach Erstvertragslaufzeit";

  let termOfNoticeInfo = `${pluralizedWordWithAmount(
    DatePeriodType[termOfNoticePeriod],
    termOfNotice
  )}`;

  switch (terminationDate) {
    case 1:
      termOfNoticeInfo = termOfNoticeInfo + " zum Ende des Abrechnungsjahres";
      break;
    case 2:
      termOfNoticeInfo = termOfNoticeInfo + " zum Vertragsende";
      break;
    case 3:
      termOfNoticeInfo = termOfNoticeInfo + " zum Monatsende";
      break;
    case 4:
      termOfNoticeInfo = "generell " + termOfNoticeInfo;
      break;
    default:
      termOfNoticeInfo = termOfNoticeInfo + timeOfNoticeFallback;
  }

  const guaranteeTypes = ["03", "04"];
  const guaranteeType =
    guaranteeTypes.indexOf(get(productData, "GARANTIEART")) > -1;
  const guarantee = get(productData, "PREISGARANTIE");
  let guaranteeInfo = undefined;

  if (guarantee && guarantee !== "0000-00-00") {
    guaranteeInfo = `bis zum ${new Date(guarantee).toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })}`;
  }

  return {
    initialTermInfo,
    contractRenewalInfo,
    termOfNoticeInfo,
    guaranteeType,
    guaranteeInfo,
  };
};

export const getProductHeaderData = ({
  productID,
  badge,
}: {
  productID: string;
  badge?: string | unknown;
}) => {
  const { paths, extensions } = tariffCalculatorConfig;

  const tariffHeaderSrc = `${paths.tariffHeads}${productID}${extensions.tariffHead}`;
  const tariffHeaderNegativeSrc = `${paths.tariffHeads}${productID}${extensions.tariffHeadNegative}`;

  const tariffBadgeSrc = badge
    ? `${paths.badges}${badge}${extensions.badge}`
    : undefined;

  return {
    tariffHeaderSrc,
    tariffHeaderNegativeSrc,
    tariffBadgeSrc,
  };
};

export const checkHasBulkPrices = (
  prices: ReturnType<typeof getProductPrices>
) =>
  (get(prices, "basePrice[0].STAFFELN.ZNLI_ISS_PREISSTAFFEL", []) as unknown[])
    .length > 0;

export const checkHasIncreasedBasePrice = (
  prices: ReturnType<typeof getProductPrices>
) => (get(prices, "basePrice", []) as unknown[]).length > 1;

export const checkHasIncreasedWorkingPrice = (
  prices: ReturnType<typeof getProductPrices>
) => (get(prices, "workingPrice", []) as unknown[]).length > 1;

export const checkHasPropertiePricescale = (properties: string[]) =>
  properties.indexOf("PRICESCALE") > -1;

export const generateTransformedProductOptionsForRequest = (
  product: Cs67IssProductInfo
) => {
  const transformedProduct = transformProductData(product);
  return generateProductOptionsForRequest(transformedProduct);
};

export const generateProductOptionsForRequest = (
  product: TransformedProductData
) => {
  const transformedOptions: ProdOptSelRequest[] = [];
  product.productOptions?.forEach((opt: TransformedProductOption) => {
    if (!isOptionBlacklisted(opt.type)) {
      transformedOptions.push({
        ID: opt.id,
        NAME: opt.name ?? "",
        VALUE: opt.selectedOption ?? "",
        VALUE_TXT: opt.description ?? "",
      });
    }
  });

  // Leave out combined options for now as their SAP does not recognize them, but can find out by combination of options
  // Only push for selectable CombiOptions from currently selected product options
  // const selectableCombinedOptions =
  //   getSelectableProductCombiOptions(product) ?? [];

  // selectableCombinedOptions?.forEach(
  //   (opt: TransformedCombinedProductOption) => {
  //     transformedOptions.push({
  //       ID: opt.id,
  //       NAME: opt.name ?? "",
  //       VALUE_TXT: opt.description ?? "",
  //       VALUE: opt.selectedOption ?? "",
  //     });
  //   }
  // );
  return [{ ZEVO_PROD_OPTION_ITEM: transformedOptions }];
};

export const transformProductData = (
  productData: Cs67IssProductInfo,
  consumption?: string | unknown,
  customerType?: string | unknown
) => {
  const productID = get(productData, "PRODUCT_ID");
  const sponsorshipId = get(productData, "SPONSORSHIP_ID");
  const divisionType = get(productData, "SPARTE");
  const productName = get(productData, "PRODUCT_NAME");
  const regionId = get(productData, "REGION_ID");
  const onlinereg = get(productData, "ONLINEREG");
  const productPriority = get(productData, "PRIORITY");
  const productDefaultProviderDifference = get(
    productData,
    "DEFAULT_PROVIDER_DIFFERENCE"
  );

  const productProps = get(productData, "PROD_PROP._-CS67_-PROD_PROP", []);
  const productPrices = get(productData, "PRICES._-CS67_-ISS_PRICE_INFO", []);
  const productDescriptions = get(
    productData,
    "PROD_DESC._-CS67_-ISS_PROD_DESC",
    []
  );
  const productGroups = get(productData, "PROD_GROUP.ZNLI_PRODUCT_GROUP", []);
  const prices = getProductPrices(productPrices);

  const hasBulkPrices = checkHasBulkPrices(prices);
  const hasIncreasedBasePrice = checkHasIncreasedBasePrice(prices);
  const hasIncreasedWorkingPrice = checkHasIncreasedWorkingPrice(prices);
  const hasPropertiePricescale = checkHasPropertiePricescale(productProps);
  const productOptions = getTransformedProductOptions(productData);
  const combinedProductOptions = getTransformedCombinedProductOptions(
    productData
  );

  const isBasicSupplierTariff =
    indexOf(get(productData, "PROD_PROP._-CS67_-PROD_PROP", []), "GRUNDVERS") >
    -1;

  const transformedProductDescription = getProductDescription(
    productDescriptions
  );

  const transformedProductData = {
    id: productID,
    sponsorshipId,
    name: productName,
    regionId,
    onlinereg,
    divisionType,
    properties: productProps,
    priority: productPriority,
    defaultProviderDifference: productDefaultProviderDifference,
    prices,
    hasBulkPrices,
    productGroups,
    hasIncreasedBasePrice,
    hasIncreasedWorkingPrice,
    hasPropertiePricescale,
    isBasicSupplierTariff,
    consumption,
    productOptions,
    combinedProductOptions,
    ...transformedProductDescription,
    ...getContractDetails(productData, customerType),
    ...getProductHeaderData({
      productID,
      badge: transformedProductDescription.badges,
    }),
  };

  return transformedProductData;
};

export const SalutationTitles = {
  "0001": "Frau",
  "0002": "Herrn",
  "0003": "Firma",
  "0004": "Eheleute",
  "3000": "Frau/Frau",
  "4000": "Herr/Herr",
  "5001": "Geschwister",
  "5002": "Familie",
  "5003": "Wohngemeinschaft",
  "5004": "Erbengemeinschaft",
  "5005": "Eigentümergemeinschaft",
  "5006": "Grundstücksgemeinschaft",
  "5007": "Bauherrengemeinschaft",
  "5008": "Dres.",
  "5009": "Rechtsanwaltskanzlei",
  "5010": "Arbeitsgemeinschaft",
  "5011": "Gemeinschaftspraxis",
  "5012": "Liefergemeinschaft",
  "5013": "Kooperation",
  "5014": "Steuerberater",
  "5015": "Bürogemeinschaft",
  "5999": "",
  "6000": "Frau/Herr",
};

export const getSalutationMediForId = (
  salutationId: keyof typeof SalutationTitles
) => SalutationTitles[salutationId] ?? "";

export type TransformedProductData = ReturnType<typeof transformProductData>;
