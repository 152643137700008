// import { FundingProject } from "@/features/funding-projects/types";
import { defineMutations } from "direct-vuex";
import Vue from "vue";
import { SalesApplicationRouteState } from ".";
import {
  SalesApplicationStepData,
  SalesApplicationStepState,
} from "../components/SalesApplicationStep";

export enum MutationType {
  setConfigData = "setConfigData",
  setIsLoadingGlobally = "setIsLoadingGlobally",
  addSalesApplicationRouteError = "addSalesApplicationRouteError",
  resetSalesApplicationRouteErrors = "resetSalesApplicationRouteErrors",
  addSalesApplicationRouteMessage = "addSalesApplicationRouteMessage",
  resetSalesApplicationRouteMessage = "resetSalesApplicationRouteMessage",
  // setFundingProjectsStep = "setFundingProjectsStep",
  setTariffStep = "setTariffStep",
  setFormData = "setFormData",
  setPersonalDetails = "setPersonalDetails",
  setPaymentData = "setPaymentData",
  setDeliveryData = "setDeliveryData",
  setSalesApplicationCompleteData = "setSalesApplicationCompleteData",
  setSteps = "setSteps",
  setStepData = "setStepData",
  setStepState = "setStepState",
  setActiveStep = "setActiveStep",
  setShowSalesApplicationRoute = "setShowSalesApplicationRoute",
  resetSalesApplicationStepsState = "resetSalesApplicationStepsState",
  setSalesApplicationRouteTimestamp = "setSalesApplicationRouteTimestamp",
  setSalesApplicationAbilities = "setSalesApplicationAbilities",
  // setFundingProjects = "setFundingProjects",
  // setFundingCategories = "setFundingCategories",
}

export default defineMutations<SalesApplicationRouteState>()({
  [MutationType.setConfigData](
    state,
    payload: Partial<SalesApplicationRouteState["config"]>
  ) {
    Vue.set(state, "config", {
      ...state.config,
      ...payload,
    });
  },
  [MutationType.setIsLoadingGlobally](state, isLoading: boolean) {
    state.isLoadingGlobally = isLoading;
  },
  [MutationType.addSalesApplicationRouteError](state, error: string) {
    Vue.set(state, "errors", [error, ...state.errors]);
  },
  [MutationType.resetSalesApplicationRouteErrors](state) {
    Vue.set(state, "errors", []);
  },
  [MutationType.addSalesApplicationRouteMessage](state, message: string) {
    Vue.set(state, "messages", [message, ...state.messages]);
  },
  [MutationType.resetSalesApplicationRouteMessage](state) {
    Vue.set(state, "messages", []);
  },
  [MutationType.setTariffStep](
    state,
    payload: Partial<
      SalesApplicationRouteState["formData"]["salesApplicationTariffStep"]
    >
  ) {
    Vue.set(state.formData, "salesApplicationTariffStep", {
      ...state.formData.salesApplicationTariffStep,
      ...payload,
    });
  },
  // [MutationType.setFundingProjectsStep](
  //   state,
  //   payload: Partial<
  //     SalesApplicationRouteState["formData"]["salesApplicationFundingProjectsStep"]
  //   >
  // ) {
  //   Vue.set(state.formData, "salesApplicationFundingProjectsStep", {
  //     ...state.formData.salesApplicationFundingProjectsStep,
  //     ...payload,
  //   });
  // },
  [MutationType.setPersonalDetails](
    state,
    payload: Partial<
      SalesApplicationRouteState["formData"]["salesApplicationPersonalDetailsStep"]
    >
  ) {
    Vue.set(state.formData, "salesApplicationPersonalDetailsStep", {
      ...state.formData.salesApplicationPersonalDetailsStep,
      ...payload,
    });
  },
  [MutationType.setPaymentData](
    state,
    payload: Partial<
      SalesApplicationRouteState["formData"]["salesApplicationPaymentStep"]
    >
  ) {
    Vue.set(state.formData, "salesApplicationPaymentStep", {
      ...state.formData.salesApplicationPaymentStep,
      ...payload,
    });
  },
  [MutationType.setDeliveryData](
    state,
    payload: Partial<
      SalesApplicationRouteState["formData"]["salesApplicationDeliveryStep"]
    >
  ) {
    Vue.set(state.formData, "salesApplicationDeliveryStep", {
      ...state.formData.salesApplicationDeliveryStep,
      ...payload,
    });
  },
  [MutationType.setSalesApplicationCompleteData](
    state,
    payload: Partial<
      SalesApplicationRouteState["formData"]["salesApplicationCompleteStep"]
    >
  ) {
    Vue.set(state.formData, "salesApplicationCompleteStep", {
      ...state.formData.salesApplicationCompleteStep,
      ...payload,
    });
  },

  [MutationType.setSalesApplicationAbilities](
    state,
    payload: Partial<SalesApplicationRouteState["salesApplicationAbilities"]>
  ) {
    Vue.set(state, "salesApplicationAbilities", {
      ...state,
      ...payload,
    });
  },

  [MutationType.setFormData](
    state,
    payload: Partial<SalesApplicationRouteState["formData"]>
  ) {
    Vue.set(state, "formData", {
      ...state.formData,
      ...payload,
    });
  },
  [MutationType.setSteps](
    state,
    payload: Partial<SalesApplicationRouteState["steps"]>
  ) {
    Vue.set(state, "steps", payload);
  },
  [MutationType.setStepData](
    state,
    payload: { index: number; stepData: Partial<SalesApplicationStepData> }
  ) {
    if (payload.index < state.steps.length) {
      const newStepsData = [...state.steps];
      newStepsData[payload.index] = {
        ...state.steps[payload.index],
        ...payload.stepData,
      };

      Vue.set(state, "steps", newStepsData);
    }
  },
  [MutationType.setStepState](
    state,
    payload: { index: number; state: SalesApplicationStepState }
  ) {
    if (payload.index < state.steps.length) {
      const newStepsData = [...state.steps];
      newStepsData[payload.index].state = payload.state;

      Vue.set(state, "steps", newStepsData);
    }
  },
  [MutationType.setActiveStep](state, index: number) {
    const newStepsData = [...state.steps];

    newStepsData.forEach((step, i) => {
      if (i < index) {
        step.state = SalesApplicationStepState.visited;
      } else if (i === index) {
        step.state = SalesApplicationStepState.active;
      }
    });

    Vue.set(state, "steps", newStepsData);
  },
  [MutationType.setShowSalesApplicationRoute](
    state,
    showSalesApplicationRoute: boolean
  ) {
    state.showSalesApplicationRoute = showSalesApplicationRoute;
  },
  [MutationType.resetSalesApplicationStepsState](state) {
    const newStepsData = [...state.steps];
    newStepsData.forEach((step) => {
      step.state = SalesApplicationStepState.unvisited;
    });

    Vue.set(state, "steps", newStepsData);
  },
  [MutationType.setSalesApplicationRouteTimestamp](state) {
    const timestamp = Date.now();
    state.timestamp = timestamp;
  },
  // [MutationType.setFundingProjects](state, projects: FundingProject[]) {
  //   Vue.set(state.fundingProjects, "projects", projects);
  // },
  // [MutationType.setFundingCategories](state, categories: string[]) {
  //   Vue.set(state.fundingProjects, "categories", categories);
  // },
});
