


































import { defineComponent, ref, Ref } from "@vue/composition-api";
import { ValidationResult } from "vee-validate/dist/types/types";
import { mask } from "vue-the-mask";

import {
  checkAddressTariffCalcAction,
  SapError,
  getErrorMessage,
  getAddresses,
} from "@/api/sap";

import { useRandomPlaceholder } from "@/hooks/useRandomPlaceholder.hook";

import BaseSelect from "@/shared/components/base/BaseSelect/BaseSelect.vue";
import { get, isEmpty, some } from "lodash-es";

export default defineComponent({
  name: "SapPostalCodeField",
  directives: {
    mask,
  },
  props: {
    customErrorMessage: {
      type: String,
    },
  },
  setup(props, context) {
    const searchRef: Ref<HTMLInputElement | null> = ref(null);
    const selectRef: Ref<
      | (typeof BaseSelect & {
          providerRef: {
            validate: (...args: any[]) => Promise<ValidationResult>;
            reset: () => void;
          };
        })
      | null
    > = ref(null);

    const isLoading = ref(false);
    const options: Ref<ReturnType<typeof getAddresses>> = ref([]);
    const errors: Ref<string[]> = ref([]);
    const skipEmit = ref(false);

    const selectValue = ref(context.attrs.value);
    const inputValue = ref("");

    const { placeholder } = useRandomPlaceholder([
      "46045",
      "46047",
      "46049",
      "46117",
      "46119",
      "46145",
      "46147",
      "46149",
    ]);

    function onInput(value: ReturnType<typeof getAddresses> | null) {
      if (skipEmit.value) {
        return;
      }

      selectValue.value = value;

      if (!value) {
        inputValue.value = "";
      }

      context.emit("input", value);
    }

    async function search(
      loading: (isLoading: boolean) => void,
      searchQuery: string
    ) {
      try {
        isLoading.value = true;
        loading(isLoading.value);
        (searchRef.value as HTMLInputElement).blur();
        selectRef.value?.providerRef.reset();

        context.emit("search:start");
        const response = await checkAddressTariffCalcAction(searchQuery);
        const errorMsg = getErrorMessage(response);

        if (errorMsg) {
          throw new Error(
            props.customErrorMessage ? props.customErrorMessage : errorMsg
          );
        }

        const addresses = getAddresses(response);
        if (
          some(addresses, (address) => !isEmpty(get(address, "POST_CODE1")))
        ) {
          options.value = addresses;

          context.emit("search:success");

          if (addresses.length === 1) {
            skipEmit.value = true;
            selectValue.value = addresses[0];
            inputValue.value = addresses[0].LABEL;
            context.emit("input", addresses[0]);
          } else if (addresses.length > 1) {
            (searchRef.value as HTMLInputElement).focus();
          }
        } else {
          throw new SapError("No address available");
        }
      } catch (error) {
        (searchRef.value as HTMLInputElement).blur();

        let message = (error as Error).message;

        if (error instanceof SapError) {
          message = (error as SapError).userMessage;
        }

        errors.value.push(message);
        context.emit("search:error", errors.value);
      } finally {
        isLoading.value = false;
        await loading(isLoading.value);
        context.emit("search:end");
        skipEmit.value = false;
      }
    }

    async function onSearch(
      searchQuery: string,
      loading: (isLoading: boolean) => void
    ) {
      inputValue.value = searchQuery;

      if (errors.value.length > 0) {
        errors.value = [];
      }

      const cancelSearch =
        searchQuery === context.attrs.value ||
        searchQuery.length < 5 ||
        searchQuery.length > 5;

      !cancelSearch && search(loading, searchQuery);
    }

    function clearSearchOnBlur() {
      if (inputValue.value.length < 5) {
        selectRef.value?.providerRef.validate();
      }
    }

    return {
      clearSearchOnBlur,
      errors,
      inputValue,
      isLoading,
      onInput,
      onSearch,
      options,
      placeholder,
      searchRef,
      selectRef,
      selectValue,
      skipEmit,
    };
  },
});
