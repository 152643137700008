enum ActionTypes {
  CHECK_ADDRESS = "CHECK_ADDRESS",
  CHECK_ADDRESS_TARIFFCALC = "CHECK_ADDRESS_TARIFFCALC",

  READ_PRODUCTS = "READ_PRODUCTS",
  READ_PRODUCT_SINGLE = "READ_PRODUCT_SINGLE",
  READ_ANREDE = "READ_ANREDE",
  READ_TITLE = "READ_TITLE",
  READ_LIEFERANT = "READ_LIEFERANT",

  GET_CONFIGURATION = "GET_CONFIGURATION",
  GET_BRANCHTYPES = "GET_BRANCHTYPES",
  GET_BANK_NAME = "GET_BANK_NAME",

  SUPPLY_START = "SUPPLY_START",
  PRODUCT_CHANGE = "PRODUCT_CHANGE",
}

export default ActionTypes;
