import ActionTypes from "./action-types";

export class SapError extends Error {
  public userMessage: string;

  constructor(message: string, action: ActionTypes | "" = "") {
    super(message);
    this.name = action;
    this.userMessage =
      "Es konnte keine Abfrage durchgeführt werden. Bitte versuchen Sie es erneut oder kontaktieren Sie uns per Mail.";
  }
}
