import {
  getProducts,
  readProductsAction,
  ReadProductsPostData,
  fetchProductForOptions,
  SapError,
} from "@/api/sap";
import { defineActions } from "direct-vuex";
import { tariffCalculatorModuleActionContext } from ".";
import { Cs67IssProductInfo } from "@/api/sap/@types/read-products-response.interface";
import { get } from "lodash-es";
import { generatePriceForProductOptionsRequestData } from "@/api/sap/helper/prices-for-options.helper";

export enum ActionType {
  fetchProducts = "fetchProducts",
  updateProductSelectedOption = "updateProductSelectedOption",
  updateProductSelectedCombiOption = "updateProductSelectedCombiOption",
  updateProductPrices = "updateProductPrices",
}

export default defineActions({
  async [ActionType.fetchProducts](
    context,
    {
      payload,
      lastRequestData,
    }: {
      payload: ReadProductsPostData;
      // eslint-disable-next-line
      lastRequestData?: Record<string, any>;
    }
  ) {
    const { commit } = tariffCalculatorModuleActionContext(context);
    const errors = [];

    try {
      commit.setLoading(true);
      commit.setErrors([]);
      commit.setProducts(null);

      const response = await readProductsAction(payload);
      const products = getProducts(response);

      commit.setProducts(products);
      commit.setLastRequest(lastRequestData ?? {});
    } catch (error) {
      const sapError = new SapError((error as Error).message);
      errors.push(sapError.userMessage);
      commit.setErrors(errors);
      commit.setLastRequest({});
    } finally {
      commit.setLoading(false);
    }
  },
  async [ActionType.updateProductPrices](
    context,
    payload: {
      productId: string;
    }
  ) {
    const { commit } = tariffCalculatorModuleActionContext(context);
    const { setProductPrices } = commit;
    const {
      state: { products },
    } = context;
    const product = products.find(
      (prod: Cs67IssProductInfo) => prod["PRODUCT_ID"] === payload.productId
    );

    if (product) {
      const productPricesRequestData = generatePriceForProductOptionsRequestData(
        context,
        product
      );

      try {
        const response = await fetchProductForOptions(productPricesRequestData);
        if (response) {
          const product: Cs67IssProductInfo = get(response, [
            "DATA",
            "PRODUCT",
          ]);

          const prices = get(product, "PRICES", undefined);

          if (prices) {
            setProductPrices({
              productId: product.PRODUCT_ID,
              prices,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      console.warn("COULD NOT FIND PRODUCT WITH ID", payload.productId);
    }
  },
  async [ActionType.updateProductSelectedOption](
    context,
    payload: {
      productId: string | number;
      optionId: string | number;
      selectedValue: string | number | boolean | undefined;
    }
  ) {
    const { commit } = tariffCalculatorModuleActionContext(context);
    const { setProductSelectedOption } = commit;

    setProductSelectedOption(payload);
    await context.dispatch(ActionType.updateProductPrices, {
      productId: payload.productId,
    });
  },
  async [ActionType.updateProductSelectedCombiOption](
    context,
    payload: {
      productId: string | number;
      optionId: string | number;
      selectedValue: string | number | boolean | undefined;
    }
  ) {
    const { commit } = tariffCalculatorModuleActionContext(context);
    const { setProductSelectedCombiOption } = commit;

    setProductSelectedCombiOption(payload);
    await context.dispatch(ActionType.updateProductPrices, {
      productId: payload.productId,
    });
  },
});
