var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.$style.wrapper,
    _vm.$style[("wrapper--" + _vm.state)],
    ( _obj = {}, _obj[_vm.$style['wrapper--error']] = !_vm.valid && _vm.state === _vm.SalesApplicationStepState.visited, _obj ) ]},[_c('span',{class:[
      _vm.$style.count,
      _vm.$style[("count--" + _vm.state)],
      ( _obj$1 = {}, _obj$1[_vm.$style.checked] = _vm.checked, _obj$1 ) ]},[(_vm.checked)?[_c('BaseIcon',{staticClass:"w-4 h-4 fill-current stroke-0 md:h-11 md:w-11",attrs:{"name":"Check"}})]:[_vm._v(" "+_vm._s(_vm.count)+". ")]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }