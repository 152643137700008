export enum RegistrationStepState {
  active = "active",
  visited = "visited",
  unvisited = "unvisited",
}

export interface RegistrationStepData {
  id: string;
  headline: string;
  businessCustomerHeadline?: string;
  state: RegistrationStepState;
  checked?: boolean;
  errors?: string[];
  hidden?: boolean;
  locked?: boolean;
}

export interface RegistrationStepProps extends RegistrationStepData {
  customToggleEdit?: (index: number) => void;
}
