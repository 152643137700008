import { DivisionType, Eservprovt } from "../@types";
import { readLieferantAction } from "../actions";
import { get } from "lodash-es";

export const getProviders = async (divisionType: DivisionType) => {
  const response = await readLieferantAction(divisionType);
  const providersData = get(
    response,
    "0.DATA.XT_ESERVPROVT.ESERVPROVT",
    []
  ) as Eservprovt[];

  return providersData;
};
