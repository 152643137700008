declare global {
  interface Window {
    dataLayer: any;
  }
}

export interface DataLayerObject {
  event: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  ecommerce?: any;
}

export const pushEvent = (obj: DataLayerObject) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};
