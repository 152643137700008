import { get, sortBy } from "lodash-es";
import { Cs67IssTsad3, CustomerType } from "../@types";
import { readAnredeAction } from "../actions";

export const getSalutations = async (customerType: CustomerType) => {
  const response = await readAnredeAction(customerType);
  const salutationsData = get(
    response,
    "0.DATA.TSAD3T._-CS67_-ISS_TSAD3",
    []
  ) as Cs67IssTsad3[];

  return sortBy(salutationsData, ["TITLE"]);
};

export const getSalutationTitle = (data: Cs67IssTsad3 | null) =>
  get(data, "TITLE_MEDI", "");
