



































import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  name: "RegistrationOverviewTextWithIconIndicator",
  props: {
    valid: {
      type: Boolean,
      default: true,
    },
    hint: {
      type: String,
    },
    headline: {
      type: String,
    },
    text: {
      type: String,
    },
    subText: {
      type: String,
    },
    textClasses: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const iconName = computed(() => (props.valid ? "CheckFilled" : "Close"));

    return {
      iconName,
    };
  },
});
