import { Ref } from "@vue/composition-api";
import axios, { AxiosResponse, Canceler, CancelToken } from "axios";
import {
  CustomerType,
  DivisionType,
  GetBankNameResponse,
  GetBranchtypesResponse,
  GetConfigurationResponse,
  ReadAnredeResponse,
  SapResponse,
} from "./@types";
import { CheckAddressResponse } from "./@types/check-address.interface";
import { ReadProductsPostData } from "./@types/read-products-request.interface";
import { ReadSingleProductPostData } from "./@types/read-single-product-request.interface";
import {
  ReadProductsResponse,
  ReadProductsWithOptionsResponse,
} from "./@types/read-products-response.interface";
import { ReadTitleResponse } from "./@types/read-title.interface";
import ActionTypes from "./action-types";
import { SAP_CONFIG, sapAPI } from "./config";
import { TariffDataResponseType } from "@/api/sap/@types/sales-types.interface";
import { SapError } from "./errors";

type StringDataType = string | Readonly<Ref<string>>;

const CancelToken = axios.CancelToken;
export const checkAddressAction = async (
  postalCode: StringDataType,
  additionalData?: Record<string, unknown>
): Promise<AxiosResponse<CheckAddressResponse>["data"]> => {
  try {
    const response: AxiosResponse<CheckAddressResponse> = await sapAPI({
      data: {
        action: ActionTypes.CHECK_ADDRESS,
        data: {
          POST_CODE1: postalCode,
          ...additionalData,
        },
      },
    });

    return response.data;
  } catch (e) {
    throw new SapError(e, ActionTypes.CHECK_ADDRESS);
  }
};

export const checkAddressTariffCalcAction = async (
  postalCode: StringDataType,
  additionalData?: Record<string, unknown>
): Promise<AxiosResponse<CheckAddressResponse>["data"]> => {
  try {
    const response: AxiosResponse<CheckAddressResponse> = await sapAPI({
      data: {
        action: ActionTypes.CHECK_ADDRESS_TARIFFCALC,
        data: {
          POST_CODE1: postalCode,
          ...additionalData,
        },
      },
    });

    return response.data;
  } catch (e) {
    throw new SapError(e, ActionTypes.CHECK_ADDRESS_TARIFFCALC);
  }
};

export const readProductsAction = async (
  postData: ReadProductsPostData
): Promise<AxiosResponse<ReadProductsResponse>["data"]> => {
  try {
    const response: AxiosResponse<ReadProductsResponse> = await sapAPI({
      data: {
        action: ActionTypes.READ_PRODUCTS,
        data: postData,
      },
    });

    return response.data;
  } catch (e) {
    throw new SapError(e, ActionTypes.READ_PRODUCTS);
  }
};

export const fetchProductForOptions = async (
  postData: ReadSingleProductPostData
): Promise<AxiosResponse<ReadProductsWithOptionsResponse>["data"]> => {
  try {
    const response: AxiosResponse<
      ReadProductsWithOptionsResponse[]
    > = await sapAPI({
      data: {
        action: ActionTypes.READ_PRODUCT_SINGLE,
        data: postData,
      },
    });

    return response.data[0];
  } catch (e) {
    throw new SapError(e, ActionTypes.READ_PRODUCT_SINGLE);
  }
};

export const readSingleProductAction = async (
  postData: ReadSingleProductPostData
): Promise<AxiosResponse<ReadProductsResponse>["data"]> => {
  try {
    const response: AxiosResponse<ReadProductsResponse> = await sapAPI({
      data: {
        action: ActionTypes.READ_PRODUCT_SINGLE,
        data: postData,
      },
    });

    return response.data;
  } catch (e) {
    throw new SapError(e, ActionTypes.READ_PRODUCT_SINGLE);
  }
};

export const readAnredeAction = async (
  customerType: CustomerType
): Promise<AxiosResponse<ReadAnredeResponse[]>["data"]> => {
  try {
    const response: AxiosResponse<ReadAnredeResponse[]> = await sapAPI({
      data: {
        action: ActionTypes.READ_ANREDE,
        data: {
          BU_TYPE: customerType,
        },
      },
    });

    return response.data;
  } catch (e) {
    throw new SapError(e, ActionTypes.READ_ANREDE);
  }
};

export const readTitleAction = async (
  customerType: CustomerType
): Promise<AxiosResponse<ReadTitleResponse[]>["data"]> => {
  try {
    const response: AxiosResponse<ReadTitleResponse[]> = await sapAPI({
      data: {
        action: ActionTypes.READ_TITLE,
        data: {
          BU_TYPE: customerType,
        },
      },
    });

    return response.data;
  } catch (e) {
    throw new SapError(e, ActionTypes.READ_TITLE);
  }
};

export const readLieferantAction = async (
  divisionType: DivisionType
): Promise<AxiosResponse<ReadTitleResponse[]>["data"]> => {
  try {
    const response: AxiosResponse<ReadTitleResponse[]> = await sapAPI({
      data: {
        action: ActionTypes.READ_LIEFERANT,
        data: {
          SPARTE: divisionType,
        },
      },
    });

    return response.data;
  } catch (e) {
    throw new SapError(e, ActionTypes.READ_LIEFERANT);
  }
};

export const getConfigurationAction = async (): Promise<
  AxiosResponse<GetConfigurationResponse[]>["data"]
> => {
  try {
    const response: AxiosResponse<GetConfigurationResponse[]> = await sapAPI({
      data: {
        action: ActionTypes.GET_CONFIGURATION,
        data: {},
      },
    });

    return response.data;
  } catch (e) {
    throw new SapError(e, ActionTypes.GET_CONFIGURATION);
  }
};

export const getBranchtypesAction = async (): Promise<
  AxiosResponse<GetBranchtypesResponse[]>["data"]
> => {
  try {
    const response: AxiosResponse<GetBranchtypesResponse[]> = await sapAPI({
      data: {
        action: ActionTypes.GET_BRANCHTYPES,
        data: {},
      },
    });

    return response.data;
  } catch (e) {
    throw new SapError(e, ActionTypes.GET_BRANCHTYPES);
  }
};

export let cancelGetBankName: Canceler;
export const getBankNameAction = async (
  iban: string
): Promise<AxiosResponse<GetBankNameResponse[]>["data"]> => {
  try {
    const response: AxiosResponse<GetBankNameResponse[]> = await sapAPI({
      data: {
        action: ActionTypes.GET_BANK_NAME,
        data: {
          IBAN: iban,
        },
      },
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancelGetBankName = c;
      }),
    });

    return response.data;
  } catch (e) {
    throw new SapError(e.message, ActionTypes.GET_BANK_NAME);
  }
};

export const queueSupplyStartAction = async (
  payload: Record<string, unknown>
): Promise<AxiosResponse<SapResponse[]>["data"]> => {
  try {
    const response: AxiosResponse<SapResponse[]> = await sapAPI({
      baseURL: SAP_CONFIG.baseUrl,
      params: {
        code: "queue",
      },
      data: {
        action: ActionTypes.SUPPLY_START,
        data: payload,
      },
    });

    return response.data;
  } catch (e) {
    throw new SapError(e, ActionTypes.SUPPLY_START);
  }
};

export const sendSalesApplication = async (
  payload: Record<string, unknown>,
  actionType: string = ActionTypes.SUPPLY_START
): Promise<AxiosResponse<SapResponse[]>["data"]> => {
  try {
    const response: AxiosResponse<SapResponse[]> = await sapAPI({
      baseURL: SAP_CONFIG.baseUrl,
      params: {
        code: "sendSales",
      },
      data: {
        action: actionType,
        data: payload,
      },
    });

    return response.data;
  } catch (e) {
    throw new SapError(e, ActionTypes.SUPPLY_START);
  }
};

export const fetchCustomerDataWithToken = async (
  token: string
): Promise<TariffDataResponseType> => {
  try {
    const response: AxiosResponse<TariffDataResponseType> = await sapAPI({
      baseURL: SAP_CONFIG.baseUrl,
      params: {
        code: "sales",
      },
      data: {
        identifier: token,
      },
    });

    return response.data;
  } catch (e) {
    throw new SapError(e, ActionTypes.SUPPLY_START);
  }
};
