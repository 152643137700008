
















import { defineComponent, computed, PropType, ref } from "@vue/composition-api";
import { subWeeks, formatISO, startOfDay, addDays, addYears } from "date-fns";

import store from "@/store";

import { DeliveryType } from "../RegistrationSteps/steps/RegistrationDeliveryStep";

export default defineComponent({
  name: "RegistrationDateOfDelivery",
  props: {
    deliveryType: {
      type: String as PropType<DeliveryType>,
      required: true,
    },
  },
  setup(props) {
    const isChangeOfSupplier = ref(
      props.deliveryType === DeliveryType.CHANGE_OF_SUPPLIER
    );
    const defaultChangeOfSupplierMinDate = ref(
      startOfDay(addDays(new Date(), 19))
    );
    const defaultMovingInMinDate = ref(startOfDay(subWeeks(new Date(), 6)));

    const defaultMinDate = computed(() =>
      isChangeOfSupplier.value
        ? defaultChangeOfSupplierMinDate.value
        : defaultMovingInMinDate.value
    );
    const defaultMaxDate = ref(startOfDay(addYears(new Date(), 1)));

    const changeOfSupplierFrom = computed(
      () => store.state.registrationRoute.config.VERSORGERWECHSEL_VON
    );
    const changeOfSupplierUntil = computed(
      () => store.state.registrationRoute.config.VERSORGERWECHSEL_BIS
    );

    const movingInFrom = computed(
      () => store.state.registrationRoute.config.NEUEINZUG_VON
    );
    const movingInUntil = computed(
      () => store.state.registrationRoute.config.NEUEINZUG_BIS
    );

    const minDate = computed(() => {
      const value =
        props.deliveryType === DeliveryType.CHANGE_OF_SUPPLIER
          ? changeOfSupplierFrom.value
          : movingInFrom.value;

      return value ? startOfDay(new Date(value)) : defaultMinDate.value;
    });

    const maxDate = computed(() => {
      const value =
        props.deliveryType === DeliveryType.CHANGE_OF_SUPPLIER
          ? changeOfSupplierUntil.value
          : movingInUntil.value;

      return value ? startOfDay(new Date(value)) : defaultMaxDate.value;
    });

    const initialValue = computed(() =>
      formatISO(minDate.value, {
        representation: "date",
      })
    );

    const dateOfDeliveryTitle = computed(() =>
      props.deliveryType === DeliveryType.CHANGE_OF_SUPPLIER
        ? "Gewünschtes Lieferende beim bisherigen Versorger"
        : "Gewünschter Lieferbeginn"
    );

    return {
      defaultMinDate,
      defaultMaxDate,
      changeOfSupplierFrom,
      changeOfSupplierUntil,
      movingInFrom,
      movingInUntil,
      minDate,
      maxDate,
      initialValue,
      dateOfDeliveryTitle,
    };
  },
});
