import { computed, ref, SetupContext, onUnmounted } from "@vue/composition-api";

interface UseLoadingEventProps {
  root: SetupContext["root"];
  componentID?: string;
}

export function useLoadingEvent({
  root,
  componentID = undefined,
}: UseLoadingEventProps) {
  const globalEventCount = ref(0);
  const componentEventCount = ref(0);

  const componentIsLoading = computed(() => componentEventCount.value > 0);
  const anyIsLoading = computed(() => globalEventCount.value > 0);

  root.$on("loading:start", (args = { id: undefined }) => {
    globalEventCount.value += 1;

    if (args.id === componentID) {
      componentEventCount.value += 1;
    }
  });

  root.$on("loading:end", (args = { id: undefined }) => {
    globalEventCount.value -= 1;

    if (args.id === componentID) {
      componentEventCount.value -= 1;
    }
  });

  onUnmounted(() => {
    root.$off("loading:start");
    root.$off("loading:end");
  });

  return {
    componentIsLoading,
    anyIsLoading,
  };
}
