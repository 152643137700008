import { get } from "lodash-es";
import { Cs67IssTb038B } from "../@types";
import { getBranchtypesAction } from "../actions";

export const getAllBranchtypes = async () => {
  const response = await getBranchtypesAction();
  const branchTypes = get(
    response,
    "0.DATA.BRANCHTYPES._-CS67_-ISS_TB038B",
    []
  ) as Cs67IssTb038B[];

  return branchTypes;
};

export const getBranchTypeTitle = (data: Cs67IssTb038B | null) =>
  get(data, "TEXT", "");
