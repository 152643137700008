
















import { defineComponent, computed, PropType, ref } from "@vue/composition-api";
import { subWeeks, formatISO, startOfDay, addDays, addYears } from "date-fns";

import store from "@/store";

import { DeliveryType } from "../SalesApplicationSteps/steps/SalesApplicationDeliveryStep";

export default defineComponent({
  name: "SalesApplicationDateOfDelivery",
  props: {
    deliveryType: {
      type: String as PropType<DeliveryType>,
      required: true,
    },
  },
  setup(props) {
    const isChangeOfSupplier = ref(
      props.deliveryType === DeliveryType.CHANGE_OF_SUPPLIER
    );
    const isTariffChange = ref(
      props.deliveryType === DeliveryType.TARIFF_CHANGE
    );

    const defaultChangeOfSupplierMinDate = ref(
      startOfDay(addDays(new Date(), 19))
    );

    const sapDeliveryDate = computed(
      () =>
        new Date(
          store.state.salesApplicationRoute.formData.salesApplicationDeliveryStep.deliveryStart
        )
    );

    const defaultMovingInMinDate = ref(startOfDay(subWeeks(new Date(), 6)));

    const defaultMinDate = computed(() =>
      isChangeOfSupplier.value
        ? defaultChangeOfSupplierMinDate.value
        : isTariffChange.value
        ? sapDeliveryDate.value
        : defaultMovingInMinDate.value
    );
    const defaultMaxDate = ref(startOfDay(addYears(new Date(), 1)));

    const changeOfSupplierFrom = computed(
      () => store.state.salesApplicationRoute.config.VERSORGERWECHSEL_VON
    );
    const changeOfSupplierUntil = computed(
      () => store.state.salesApplicationRoute.config.VERSORGERWECHSEL_BIS
    );

    const movingInFrom = computed(
      () => store.state.salesApplicationRoute.config.NEUEINZUG_VON
    );
    const movingInUntil = computed(
      () => store.state.salesApplicationRoute.config.NEUEINZUG_BIS
    );

    const minDate = computed(() => {
      const value = sapDeliveryDate.value
        ? sapDeliveryDate.value
        : isChangeOfSupplier.value
        ? changeOfSupplierFrom.value
        : movingInFrom.value;

      return value ? startOfDay(new Date(value)) : defaultMinDate.value;
    });

    const maxDate = computed(() => {
      const value = isChangeOfSupplier.value
        ? changeOfSupplierUntil.value
        : movingInUntil.value;

      return value ? startOfDay(new Date(value)) : defaultMaxDate.value;
    });

    const initialValue = computed(() =>
      formatISO(minDate.value, {
        representation: "date",
      })
    );

    const dateOfDeliveryTitle = computed(() =>
      props.deliveryType === DeliveryType.CHANGE_OF_SUPPLIER 
        ? "Gewünschtes Lieferende beim bisherigen Versorger"
        : props.deliveryType === DeliveryType.TARIFF_CHANGE
        ? "Gewünschtes Vertragswechseldatum"
        : "Gewünschter Lieferbeginn"
    );

    return {
      defaultMinDate,
      defaultMaxDate,
      changeOfSupplierFrom,
      changeOfSupplierUntil,
      movingInFrom,
      movingInUntil,
      minDate,
      maxDate,
      initialValue,
      dateOfDeliveryTitle,
    };
  },
});
