export * from "./sap-response.interface";
export * from "./check-address.interface";
export * from "./read-products-request.interface";
export * from "./read-products-response.interface";
export * from "./read-anrede.interface";
export * from "./read-title.interface";
export * from "./read-lieferant.interface";
export * from "./get-branchtypes.interface";
export * from "./get-bank-name.interface";
export * from "./get-configuration.interface";
export * from "./sales-types.interface";

export enum MessageType {
  Success = "I",
  Error = "E",
  AlreadyCustomer = "W",
}

export enum DivisionType {
  Electricity = "10",
  Gas = "20",
  Heating = "10",
}

export enum CustomerType {
  PrivateCustomer = "1",
  BusinessCustomer = "2",
}

export enum CalculatorMode {
  Electricity = "electricity",
  Gas = "gas",
  Heating = "heating",
}

export const CustomerTypeFromValue: Record<
  CustomerType,
  keyof typeof CustomerType
> = {
  [CustomerType.PrivateCustomer]: "PrivateCustomer",
  [CustomerType.BusinessCustomer]: "BusinessCustomer",
} as const;

export enum HeatingType {
  HeatingPump = "RWP",
  NightStorage = "NSP",
}

export enum ResponsePaths {
  MESSAGES = "0.LOG.MESSAGES._-CS67_-DIALOG_MSG",
  ADDRESS = "0.DATA.ADDRESS",
  VALUES = "0.DATA.VALUES._-CS67_-ISS_ADRCHK_VALUES",
  NON_UNIQUE = "0.DATA.NON_UNIQUE",
  PRODUCTS = "0.DATA.PRODUCTS._-CS67_-ISS_PRODUCT_INFO",
  PRODUCT = "0.DATA.PRODUCT",
  OBERHAUSEN = "0.DATA.OBERHAUSEN",
}

export const enum MeterType {
  HIGH_TARIFF = "HT",
  LOW_TARIFF = "NT",
}
