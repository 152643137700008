import { get } from "lodash-es";

import {
  MessageType,
  SapResponse,
  Cs67DialogMsg,
  ResponsePaths,
} from "../@types";
import { hasError } from "./error.helper";

export const getMessages = (
  response: SapResponse | SapResponse[]
): Cs67DialogMsg | undefined =>
  get(
    Array.isArray(response) ? response : [response],
    ResponsePaths.MESSAGES,
    {}
  );

export const getLogMessage = (response: SapResponse | SapResponse[]) => {
  const messages = getMessages(response);
  const logMessage = get(messages, "MSG_TXT");

  return logMessage;
};

export const getErrorMessage = (response: SapResponse | SapResponse[]) => {
  const messages = getMessages(response);

  if (!hasError(messages)) {
    return undefined;
  }

  return get(messages, "MSG_TXT");
};

export const getAlreadyCustomerMessages = (
  messages: Cs67DialogMsg[] | undefined
): Cs67DialogMsg[] => {
  if (messages) {
    const alreadyCustomerMessages =
      messages.filter(
        (message) => get(message, "MSGTY") === MessageType.AlreadyCustomer
      ) ?? [];

    return alreadyCustomerMessages;
  } else {
    return [];
  }
};

export const getErrorMessages = (
  messages: Cs67DialogMsg[] | undefined
): Cs67DialogMsg[] => {
  if (messages) {
    const errorMessages =
      messages.filter(
        (message) => get(message, "MSGTY") === MessageType.Error
      ) ?? [];
    return errorMessages;
  } else {
    return [];
  }
};

export const getSuccessMessages = (
  messages: Cs67DialogMsg[] | undefined
): Cs67DialogMsg[] => {
  if (messages) {
    const errorMessages =
      messages.filter(
        (message) => get(message, "MSGTY") === MessageType.Success
      ) ?? [];
    return errorMessages;
  } else {
    return [];
  }
};

export const mergeMessages = (
  messages: Cs67DialogMsg[] | undefined
): string => {
  let mergedMessages = "";
  if (messages) {
    messages.forEach((message, index) => {
      mergedMessages += get(message, "MSG_TXT");
      if (index !== messages.length - 1) {
        mergedMessages += ", ";
      }
    });
  }

  return mergedMessages;
};

export const getMessagesArray = (
  response: SapResponse | SapResponse[]
): Cs67DialogMsg[] | undefined =>
  get(
    Array.isArray(response) ? response : [response],
    ResponsePaths.MESSAGES,
    []
  );

export const userIsAlreadyCustomer = (
  messages: Cs67DialogMsg[] | undefined
): boolean => {
  if (messages) {
    const alreadyCustomerMessages = getAlreadyCustomerMessages(messages);

    const customerMessages = mergeMessages(alreadyCustomerMessages);

    // Loosely Compare already customer messages with set of possible values
    const containsCustomerMessage = [
      "Benutzername ist bereits vergeben",
      "hat bereits einen Portalzugang",
    ].some((str) => customerMessages.includes(str));

    return !!alreadyCustomerMessages.length && containsCustomerMessage;
  } else {
    return false;
  }
};
