import { ReadProductsPostData } from "@/api/sap/@types/read-products-request.interface";
import { ReadSingleProductPostData } from "@/api/sap/@types/read-single-product-request.interface";
import { TariffDataResponseType } from "@/api/sap/@types/sales-types.interface";
import { DivisionType } from "@/api/sap";
import {
  TransformedProductData,
  SalutationTitles,
  getSalutationMediForId,
} from "@/api/sap/helper/products.helper";

// TODO: CHECK IF HARDCODED SPARTE IS REALLY CORRECT OR WE CAN DYNAMIZE HERE
export const generateProductsPostData = (
  data: TariffDataResponseType
): ReadProductsPostData => ({
  STREET: data.salesApplicationDeliveryStep.deliveryAddress.street.LINE,
  ZZ_DIST_CHANNEL: "1",
  CONSMPT_SPART: [
    {
      ISS_CONSMPT_SPART: [
        {
          CONSMPT: data.salesApplicationTariffStep.powerConsumption?.trim(),
          SPARTE: DivisionType.Electricity,
        },
        // {
        //   CONSMPT: data.salesApplicationTariffStep.powerConsumption?.trim(),
        //   SPARTE: DivisionType.Gas,
        // },
      ],
    },
  ],
  BU_TYPE: "1",
  CITY: data.salesApplicationDeliveryStep.deliveryAddress.addressResponse.CITY1,
  POST_CODE:
    data.salesApplicationDeliveryStep.deliveryAddress.addressResponse
      .POST_CODE1,
});

export const generateSingleProductPostData = (
  data: TariffDataResponseType,
  token?: string
): ReadSingleProductPostData => ({
  HANDLE: token,
  PTTR_ID: data.salesApplicationTariffStep.productId,
  CONSMPT_SPART: [
    {
      ISS_CONSMPT_SPART: [
        {
          CONSMPT: data.salesApplicationTariffStep.powerConsumption?.trim(),
          SPARTE: data.salesApplicationDeliveryStep.sparte,
        },
      ],
    },
  ],
  DATUM: data.salesApplicationTariffStep.erDat,
  STREET: data.salesApplicationDeliveryStep.deliveryAddress.street.LINE,
  HOUSE_NUM: data.salesApplicationDeliveryStep.deliveryAddress.houseNumber,
  CITY: data.salesApplicationDeliveryStep.deliveryAddress.addressResponse.CITY1,
  POST_CODE:
    data.salesApplicationDeliveryStep.deliveryAddress.addressResponse
      .POST_CODE1,
});

export const buildFormData = (
  data: TariffDataResponseType,
  product: TransformedProductData,
  token: string
) => {
  const calculatorMode = Object.keys(DivisionType).find(
    (key) =>
      DivisionType[key as keyof typeof DivisionType] ===
      data.salesApplicationDeliveryStep.sparte
  );

  // Map form Data to corresponding State Field Name
  const formData: any = {
    token,
    ...data,
    salesApplicationTariffStep: {
      divisionType: data.salesApplicationDeliveryStep.sparte,
      product: {
        ...product,
        ...data.salesApplicationTariffStep,
        calculatorMode: calculatorMode,
        consumption: data?.salesApplicationTariffStep?.powerConsumption,
        customerType: data.salesApplicationPersonalDetailsStep.companyName
          ? "2"
          : "1",
        priceUnit: data.salesApplicationPersonalDetailsStep.companyName
          ? "NETTO"
          : "BRUTTO",
      },
    },
    salesApplicationPersonalDetailsStep: {
      ...data.salesApplicationPersonalDetailsStep,
      salutation: {
        ...data.salesApplicationPersonalDetailsStep.salutation,
        TITLE_MEDI: data.salesApplicationPersonalDetailsStep.salutation
          .TITLE_MEDI
          ? data.salesApplicationPersonalDetailsStep.salutation.TITLE_MEDI
          : getSalutationMediForId(
              data.salesApplicationPersonalDetailsStep.salutation
                .TITLE as keyof typeof SalutationTitles
            ),
      },
    },
    salesApplicationPaymentStep: {
      ...data.salesApplicationPaymentStep,
      paymentMethod: data.salesApplicationPaymentStep?.iban
        ? "sepa"
        : "transfer",
      value: {
        ...data.salesApplicationPaymentStep,
        paymentMethod: data.salesApplicationPaymentStep?.iban
          ? "sepa"
          : "transfer",
      },
    },
    salesApplicationDeliveryStep: {
      ...data.salesApplicationDeliveryStep,
      previousSupplierData: data.salesApplicationDeliveryStep
        ?.previousSupplierData?.length
        ? data.salesApplicationDeliveryStep.previousSupplierData
        : undefined,
      billingAddress: {
        ...data.salesApplicationDeliveryStep?.billingAddress,
        addressType: data.salesApplicationDeliveryStep?.billingAddress
          ?.mailboxNumber
          ? "Postfach"
          : data.salesApplicationDeliveryStep?.billingAddress?.houseNumber &&
            data.salesApplicationDeliveryStep?.billingAddress?.street?.LINE
          ? "Postanschrift"
          : "",
      },
    },
  };

  return formData;
};
