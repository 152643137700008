var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"md:mt-3"},[_vm._v(" Preise - jeweils für Ihren gesamten Verbrauch - bei einem Verbrauch von: ")]),_c('div',{staticClass:"grid gap-2 md:grid-cols-2 lg:grid-cols-3"},_vm._l((_vm.tableData),function(ref,i){
      var from = ref.from;
      var to = ref.to;
      var formattedFrom = ref.formattedFrom;
      var formattedTo = ref.formattedTo;
      var basePrice = ref.basePrice;
      var workingPrice = ref.workingPrice;
return _c('table',{key:i,staticClass:"mt-3 border-b-0 table-colored table-styled",class:_vm.tableClasses,attrs:{"align":"left"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col","colspan":"2"}},[_c('span',{staticClass:"block text-sm leading-tight md:text-lg"},[(from <= 0)?[_vm._v("bis "+_vm._s(formattedTo))]:(from > 0 && to < 99999999)?[_vm._v(" "+_vm._s(formattedFrom)+" bis "+_vm._s(formattedTo)+" ")]:[_vm._v(" ab "+_vm._s(formattedFrom)+" ")],_vm._v(" kWh/Jahr ")],2)])])]),_c('tbody',[_c('tr',{staticClass:"text-base"},[_c('td',{staticClass:"w-50"},[_vm._v("Grundpreis:")]),_c('td',{staticClass:"font-bold w-50"},[_vm._v(" "+_vm._s(basePrice[_vm.priceUnit] ? ((_vm.formatNumber(basePrice[_vm.priceUnit])) + " " + (basePrice.EINHEIT)) : "unverändert")+" ")])]),_c('tr',{staticClass:"text-base"},[_c('td',{staticClass:"w-50"},[_vm._v("Arbeitspreis:")]),_c('td',{staticClass:"font-bold w-50"},[_vm._v(" "+_vm._s(workingPrice[_vm.priceUnit] ? ((_vm.formatNumber(workingPrice[_vm.priceUnit])) + " " + (workingPrice.EINHEIT)) : "unverändert")+" ")])])])])}),0),(_vm.increasedTableData.length > 0)?_c('p',{staticClass:"md:mt-8"},[_vm._v(" Preise ab "+_vm._s(new Date(_vm.prices.basePrice[1].VALID_FROM).toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit", }))+": ")]):_vm._e(),(_vm.increasedTableData.length > 0)?_c('div',{staticClass:"grid gap-2 md:grid-cols-2 lg:grid-cols-3"},_vm._l((_vm.increasedTableData),function(ref,i){
      var from = ref.from;
      var to = ref.to;
      var formattedFrom = ref.formattedFrom;
      var formattedTo = ref.formattedTo;
      var basePrice = ref.basePrice;
      var workingPrice = ref.workingPrice;
return _c('table',{key:i,staticClass:"mt-3 border-b-0 table-colored table-styled",class:_vm.tableClasses,attrs:{"align":"left"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col","colspan":"2"}},[_c('span',{staticClass:"block text-sm leading-tight md:text-lg"},[(from <= 0)?[_vm._v("bis "+_vm._s(formattedTo))]:(from > 0 && to < 99999999)?[_vm._v(" "+_vm._s(formattedFrom)+" bis "+_vm._s(formattedTo)+" ")]:[_vm._v(" ab "+_vm._s(formattedFrom)+" ")],_vm._v(" kWh/Jahr ")],2)])])]),_c('tbody',[_c('tr',{staticClass:"text-base"},[_c('td',{staticClass:"w-50"},[_vm._v("Grundpreis:")]),_c('td',{staticClass:"font-bold w-50"},[_vm._v(" "+_vm._s(basePrice[_vm.priceUnit] ? ((_vm.formatNumber(basePrice[_vm.priceUnit])) + " " + (basePrice.EINHEIT)) : "unverändert")+" ")])]),_c('tr',{staticClass:"text-base"},[_c('td',{staticClass:"w-50"},[_vm._v("Arbeitspreis:")]),_c('td',{staticClass:"font-bold w-50"},[_vm._v(" "+_vm._s(workingPrice[_vm.priceUnit] ? ((_vm.formatNumber(workingPrice[_vm.priceUnit])) + " " + (workingPrice.EINHEIT)) : "unverändert")+" ")])])])])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }