import {
  Cs67IssProductInfo,
  ProdOption,
  ProdOptionValues,
  ProdOptionCombi,
  TransformedProductOption,
  TransformedCombinedProductOption,
  ProdOptionsCombiOption,
} from "../@types";

import { get, isNil, orderBy } from "lodash-es";
import { TransformedProductData } from "./products.helper";
import { TransformedCombinedProductOptionValue } from "@/api/sap/@types";

const OPTIONS_SORT_ORDER = "asc";

export const getTransformedProductOptions = (
  productData: Cs67IssProductInfo
): TransformedProductOption[] => {
  const options = get(productData, "PROD_OPTIONS.ZEVO_PRODUCT_OPTION", []);

  let transformedOptions: TransformedProductOption[] = [];

  if (Array.isArray(options)) {
    const sortedOptions = orderBy(
      options,
      (o) => new Number(o["ORDER"]),
      OPTIONS_SORT_ORDER
    );
    transformedOptions = sortedOptions?.map((option: ProdOption) => {
      return transformProductOption(option);
    });
  } else if (options) {
    transformedOptions = [transformProductOption(options)];
  }

  return transformedOptions ?? [];
};

export const transformProductOption = (
  option: ProdOption
): TransformedProductOption => {
  const values = get(option, "VALUES.ZEVO_PRODUCT_OPTION_VALUE", []);
  const sortedValues =
    orderBy(values, (o) => new Number(o["ORDER"]), "desc") ?? [];
  const id = get(option, "ID", "");
  let selectedOption = get(option, "SELECTED_VALUE", undefined);

  const transformedValues = sortedValues?.map((val: ProdOptionValues) => {
    // Transform weird SAP API data values
    let defaultValue = get(val, "DEFAULT_VALUE", undefined);
    defaultValue =
      Array.isArray(defaultValue) && defaultValue.length === 0
        ? undefined
        : defaultValue;

    let value = get(val, "VALUE", "");
    value = Array.isArray(value) && value.length === 0 ? "" : value;

    // If current selected Option is not set
    // AND defaultValue is set to "X" ("true" from SAP API)
    // => set the value of the current option as selectedOption
    if (isNil(selectedOption) && defaultValue === "X") {
      selectedOption = value;
    }

    return {
      id: `${id}-${get(val, "VALUE", "")}`,
      default: defaultValue,
      text: get(val, "VALUE_TXT", ""),
      value,
    };
  });

  const info =
    typeof get(option, "INFO", "") === "string"
      ? get(option, "INFO", "")
      : undefined;

  return {
    id,
    description: get(option, "DESCRIPTION", ""),
    name: get(option, "NAME", ""),
    type: get(option, "TYPE", ""),
    info,
    optionType: "default",
    validFrom: get(option, "VALID_FROM", ""),
    validTo: get(option, "VALID_TO", ""),
    values: transformedValues,
    selectedOption,
  };
};

export const getTransformedCombinedProductOptions = (
  productData: Cs67IssProductInfo
): TransformedCombinedProductOption[] => {
  const combinedOptions = get(
    productData,
    "PROD_OPTIONS_COMBI.ZEVO_PRODUCT_OPTION_COMBI",
    []
  );

  let transformedOptions: TransformedCombinedProductOption[] = [];

  if (Array.isArray(combinedOptions)) {
    const sortedCombinedOptions = orderBy(
      combinedOptions,
      (o) => new Number(o["ORDER"]),
      OPTIONS_SORT_ORDER
    );

    transformedOptions = sortedCombinedOptions?.map(
      (option: ProdOptionCombi) => {
        return transformCombinedProductOption(option);
      }
    );
  } else if (combinedOptions) {
    transformedOptions = [transformCombinedProductOption(combinedOptions)];
  }

  return transformedOptions ?? [];
};

export const transformCombinedProductOption = (
  combinedOption: ProdOptionCombi
) => {
  const values = get(
    combinedOption,
    "PROD_OPTIONS.ZEVO_PROD_OPTION_COMBI_ITEM",
    []
  );
  const sortedValues =
    orderBy(values, (o) => new Number(o["ORDER"]), OPTIONS_SORT_ORDER) ?? [];

  const id = get(combinedOption, "ID", "");
  const selectedOption = get(combinedOption, "SELECTED_VALUE", undefined);
  const combinedOptionData = get(combinedOption, "PROD_OPTION_NEW", undefined);
  const transformedCombinedOptionData = combinedOptionData
    ? transformProductOption(combinedOptionData)
    : undefined;
  const transformedOptions = sortedValues?.map(
    (val: ProdOptionsCombiOption) => {
      return {
        id: `${id}-${get(val, "VALUE", "")}`,
        option: get(val, "PROD_OPTION", ""),
        text: get(val, "VALUE_TXT", ""),
        value: get(val, "VALUE", ""),
      };
    }
  );

  const info =
    typeof get(combinedOption, "INFO", "") === "string"
      ? get(combinedOption, "INFO", "")
      : undefined;

  return {
    id,
    description: get(combinedOption, "DESCRIPTION", ""),
    name: get(combinedOption, "NAME", ""),
    type: get(combinedOption, "TYPE", ""),
    info,
    optionType: "combined",
    validFrom: get(combinedOption, "VALID_FROM", ""),
    validTo: get(combinedOption, "VALID_TO", ""),
    selectedOption: selectedOption,
    options: transformedOptions ?? [],
    optionData: transformedCombinedOptionData,
  };
};

export const getSelectedProductOptionsMapping = (
  options: TransformedProductOption[]
) => {
  return options.map((option) => ({
    id: option.id,
    selectedValue: option.selectedOption,
  }));
};

export const getSelectableProductCombiOptions = (
  product: TransformedProductData
): TransformedCombinedProductOption[] => {
  // Map all currently present options and their selectedValue
  const selectedProductOptionsMapping = getSelectedProductOptionsMapping(
    product.productOptions
  );
  return product.combinedProductOptions.filter(
    (option: TransformedCombinedProductOption) => {
      // Map all dependencies for rendering a combined product option
      const optionDependencies = option.options?.map(
        (option: TransformedCombinedProductOptionValue) => ({
          id: option.option,
          selectedValue: option.value,
        })
      );
      // Match if every dependency for every option is met in current selected options
      const matches = optionDependencies.every((dependency) => {
        return selectedProductOptionsMapping.find((item) => {
          return (
            item.id === dependency.id &&
            item.selectedValue === dependency.selectedValue
          );
        });
      });
      // Return if all dependencies match
      // ==> return all combined options whose dependencies match current selected values
      return matches;
    }
  );
};

export const isOptionBlacklisted = (type: string) => {
  const optionTypeBlacklist = ["FIX_DETAIL"];

  return optionTypeBlacklist.includes(type);
};
