import { get, sortBy, toNumber } from "lodash-es";
import { CustomerType } from "../@types";
import { Cs67IssTsad2 } from "../@types/read-title.interface";
import { readTitleAction } from "../actions";

export const getTitles = async (customerType: CustomerType) => {
  const response = await readTitleAction(customerType);
  const titles = get(
    response,
    "0.DATA._-CS67_-ISS_TSAD2",
    []
  ) as Cs67IssTsad2[];

  return sortBy(titles, [(o) => toNumber(o.TITLE_KEY)]);
};
