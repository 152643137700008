var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col space-y-4"},[(_vm.headline)?_c('h3',[_vm._v(_vm._s(_vm.headline))]):_vm._e(),_c('div',{staticClass:"flex space-x-2 md:space-x-4"},[_c('BaseIcon',{staticClass:"flex-shrink-0 w-5 h-5",class:{
        'text-white': _vm.valid,
        'text-red-500 mt-0-5': !_vm.valid,
      },attrs:{"name":_vm.iconName}}),_c('div',{staticClass:"flex flex-col md:-mt-1"},[_c('div',{staticClass:"text-sm md:text-lg leading-chilled",class:[
          _vm.textClasses,
          {
            'font-bold': !_vm.valid,
          } ]},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.valid ? _vm.text : _vm.hint)}})]),(_vm.subText)?_c('div',{staticClass:"mt-1 text-sm md:text-lg leading-chilled"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.subText)}})]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }