






























import { defineComponent, PropType } from "@vue/composition-api";

import { RegistrationStepState } from "../RegistrationStep";

export default defineComponent({
  name: "RegistrationStepCount",
  props: {
    state: {
      type: String as PropType<RegistrationStepState>,
      default: RegistrationStepState.unvisited,
    },
    count: {
      type: Number,
      required: true,
    },
    checked: {
      type: Boolean,
    },
    valid: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      RegistrationStepState,
    };
  },
});
