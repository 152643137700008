













import { defineComponent, computed, PropType } from "@vue/composition-api";

import { PriceUnit } from "@/api/sap";

export default defineComponent({
  name: "TariffCalculatorPriceInfo",
  props: {
    priceUnit: {
      type: String as PropType<PriceUnit>,
      required: true,
    },
    customNettoText: {
      type: String,
      required: false,
    },
    customBruttoText: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const defaultNettoText =
      "Alle Preise sind Nettopreise zuzüglich aktueller Umsatzsteuer, jedoch inkl. aller sonstiger aktueller Steuern und aktueller gesetzlicher Abgaben.";

    const defaultBruttoText =
      "Alle Preise inkl. aktueller gesetzlicher Abgaben und Steuern.";

    const nettoText = props.customNettoText || defaultNettoText;

    const bruttoText = props.customBruttoText || defaultBruttoText;

    const isNetto = computed(() => props.priceUnit === PriceUnit.Netto);

    return {
      nettoText,
      bruttoText,
      isNetto,
    };
  },
});
