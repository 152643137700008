















import { defineComponent, ref } from "@vue/composition-api";

import { useAsyncComponent } from "@/hooks/useAsyncComponent";
import { getProviders, DivisionType, Eservprovt } from "@/api/sap";

export default defineComponent({
  name: "SalesApplicationProviders",
  props: {
    divisionType: {
      type: String,
      default: DivisionType.Electricity,
    },
  },
  setup(props, ctx) {
    const { withLoading } = useAsyncComponent({
      root: ctx.root,
      ids: ["SalesApplicationDeliveryStep"],
    });

    const providers = ref<Eservprovt[]>([]);

    function reduceOptions(provider: Eservprovt) {
      return provider.SERVICEID || provider.SP_NAME;
    }

    function createOwnProviderOption(provider: string) {
      return {
        MANDT: "",
        SPRAS: "",
        SERVICEID: "",
        SP_NAME: provider,
      } as Eservprovt;
    }

    // "unsafe" Cast prop to division type to satisfy typings, as right now there is no time to decipher correct vue prop typing syntax for this
    withLoading(getProviders(props.divisionType as DivisionType)).then(
      (data) => (providers.value = data)
    );

    return {
      reduceOptions,
      createOwnProviderOption,
      providers,
    };
  },
});
