import { CalculatorMode, CustomerType } from "@/api/sap";
import { defineGetters } from "direct-vuex";
import { findLastIndex, get, isEmpty, kebabCase } from "lodash-es";
import {
  salesApplicationRouteModuleGetterContext,
  SalesApplicationRouteState,
} from ".";
import { SalesApplicationStepState } from "../components/SalesApplicationStep";
import { DeliveryType } from "../components/SalesApplicationSteps/steps/SalesApplicationDeliveryStep";
import { ProductAbilityType } from "./helper/productStepAbilityFactory.helper";

export enum GetterType {
  shouldShowSalesApplicationRoute = "shouldShowSalesApplicationRoute",
  salesApplicationTariffFormData = "salesApplicationTariffFormData",
  salesApplicationPersonalDetailsFormData = "salesApplicationPersonalDetailsFormData",
  salesApplicationPaymentFormData = "salesApplicationPaymentFormData",
  salesApplicationDeliveryFormData = "salesApplicationDeliveryFormData",
  salesApplicationCompleteFormData = "salesApplicationCompleteFormData",
  currentSalesApplicationStep = "currentSalesApplicationStep",
  isLastSalesApplicationStepActive = "isLastSalesApplicationStepActive",
  salesApplicationIsChangeOfSupplier = "salesApplicationIsChangeOfSupplier",
  salesApplicationIsHeatingTariffType = "salesApplicationIsHeatingTariffType",
  salesApplicationShowHTNTMeterReading = "salesApplicationShowHTNTMeterReading",
  // -- //
  salesApplicationRouteHasProductSelected = "salesApplicationRouteHasProductSelected",
  salesApplicationRouteCustomerType = "salesApplicationRouteCustomerType",
  salesApplicationRouteIsBusinessCustomer = "salesApplicationRouteIsBusinessCustomer",
  salesApplicationRouteCurrentStepId = "salesApplicationRouteCurrentStepId",
  salesApplicationRouteTariffType = "salesApplicationRouteTariffType",
  salesApplicationRouteTariffTypeText = "salesApplicationRouteTariffTypeText",
  salesApplicationAbilities = "salesApplicationAbilities",
}

export default defineGetters<SalesApplicationRouteState>()({
  [GetterType.shouldShowSalesApplicationRoute](...args): boolean {
    const { state } = salesApplicationRouteModuleGetterContext(args);
    const { showSalesApplicationRoute } = state;

    const shouldShowSalesApplicationRoute = showSalesApplicationRoute;

    return shouldShowSalesApplicationRoute;
  },
  [GetterType.salesApplicationRouteHasProductSelected](...args): boolean {
    const { state } = salesApplicationRouteModuleGetterContext(args);
    const { formData } = state;

    const hasProduct = !isEmpty(formData.salesApplicationTariffStep.product);

    return hasProduct;
  },
  [GetterType.salesApplicationAbilities](...args): ProductAbilityType {
    const { state } = salesApplicationRouteModuleGetterContext(args);
    const { salesApplicationAbilities } = state;

    return salesApplicationAbilities;
  },
  [GetterType.salesApplicationTariffFormData](
    ...args
  ): SalesApplicationRouteState["formData"]["salesApplicationTariffStep"] {
    const { state } = salesApplicationRouteModuleGetterContext(args);
    const { formData } = state;

    return formData.salesApplicationTariffStep;
  },
  [GetterType.salesApplicationPersonalDetailsFormData](
    ...args
  ): SalesApplicationRouteState["formData"]["salesApplicationPersonalDetailsStep"] {
    const { state } = salesApplicationRouteModuleGetterContext(args);
    const { formData } = state;

    return formData.salesApplicationPersonalDetailsStep;
  },
  [GetterType.salesApplicationPaymentFormData](
    ...args
  ): SalesApplicationRouteState["formData"]["salesApplicationPaymentStep"] {
    const { state } = salesApplicationRouteModuleGetterContext(args);
    const { formData } = state;

    return formData.salesApplicationPaymentStep;
  },
  [GetterType.salesApplicationDeliveryFormData](
    ...args
  ): SalesApplicationRouteState["formData"]["salesApplicationDeliveryStep"] {
    const { state } = salesApplicationRouteModuleGetterContext(args);
    const { formData } = state;

    return formData.salesApplicationDeliveryStep;
  },
  [GetterType.salesApplicationCompleteFormData](
    ...args
  ): SalesApplicationRouteState["formData"]["salesApplicationCompleteStep"] {
    const { state } = salesApplicationRouteModuleGetterContext(args);
    const { formData } = state;

    return formData.salesApplicationCompleteStep;
  },
  [GetterType.currentSalesApplicationStep](...args): number {
    const { state } = salesApplicationRouteModuleGetterContext(args);
    const { steps } = state;

    return findLastIndex(
      steps,
      (step) => step.state === SalesApplicationStepState.active
    );
  },
  [GetterType.isLastSalesApplicationStepActive](...args): boolean {
    const { state, getters } = salesApplicationRouteModuleGetterContext(args);
    const { steps } = state;
    const { currentSalesApplicationStep } = getters;

    return currentSalesApplicationStep === steps.length - 1;
  },
  [GetterType.salesApplicationIsChangeOfSupplier](...args): boolean {
    const { state } = salesApplicationRouteModuleGetterContext(args);
    const {
      formData: { salesApplicationDeliveryStep },
    } = state;

    return (
      salesApplicationDeliveryStep.deliveryMethod ===
      DeliveryType.CHANGE_OF_SUPPLIER
    );
  },
  [GetterType.salesApplicationRouteCustomerType](...args): CustomerType {
    const { state } = salesApplicationRouteModuleGetterContext(args);
    const {
      formData: {
        salesApplicationTariffStep: { product },
      },
    } = state;

    return product?.customerType ?? CustomerType.PrivateCustomer;
  },
  [GetterType.salesApplicationRouteIsBusinessCustomer](...args): boolean {
    const { getters } = salesApplicationRouteModuleGetterContext(args);
    const { salesApplicationRouteCustomerType } = getters;

    return salesApplicationRouteCustomerType === CustomerType.BusinessCustomer;
  },
  [GetterType.salesApplicationRouteCurrentStepId](...args): string {
    const { state, getters } = salesApplicationRouteModuleGetterContext(args);
    const { steps } = state;
    const { currentSalesApplicationStep } = getters;

    return get(steps, `${currentSalesApplicationStep}.id`, "");
  },
  [GetterType.salesApplicationRouteTariffType](...args): string {
    const { getters } = salesApplicationRouteModuleGetterContext(args);
    const { salesApplicationTariffFormData } = getters;
    const { product } = salesApplicationTariffFormData;

    return kebabCase(get(product, "calculatorMode", ""));
  },
  [GetterType.salesApplicationIsHeatingTariffType](...args): boolean {
    const { getters } = salesApplicationRouteModuleGetterContext(args);
    const { salesApplicationRouteTariffType } = getters;

    return salesApplicationRouteTariffType === CalculatorMode.Heating;
  },
  [GetterType.salesApplicationShowHTNTMeterReading](...args): boolean {
    const { getters } = salesApplicationRouteModuleGetterContext(args);
    const {
      salesApplicationIsHeatingTariffType,
      salesApplicationTariffFormData,
    } = getters;

    const hasHTNT =
      salesApplicationTariffFormData.product?.highTariff &&
      salesApplicationTariffFormData.product?.lowTariff;

    const salesApplicationShowHTNTMeterReading =
      salesApplicationIsHeatingTariffType && hasHTNT;

    return salesApplicationShowHTNTMeterReading;
  },
  [GetterType.salesApplicationRouteTariffTypeText](...args): string {
    const { getters } = salesApplicationRouteModuleGetterContext(args);
    const { salesApplicationRouteTariffType } = getters;

    switch (salesApplicationRouteTariffType) {
      case CalculatorMode.Electricity:
        return "Strom";
      case CalculatorMode.Gas:
        return "Gas";
      case CalculatorMode.Heating:
        return "Heizstrom";
      default:
        return "";
    }
  },
});
