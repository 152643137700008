
























































































































































































































































































import { get, filter, flattenDeep } from "lodash-es";

import { defineComponent, PropType, computed } from "@vue/composition-api";

import { PriceUnit, ZnliIssPreisstaffel, Cs67IssPriceComp } from "@/api/sap";
import { formatNumber, formatSAPPrices } from "@/helper";

import { tariffCalculatorConstants } from "@/features/tariff-calculator/constants";

export default defineComponent({
  name: "TariffCalculatorProductDetailsPrices",
  props: {
    prices: Object as PropType<Record<string, ZnliIssPreisstaffel>>,
    priceUnit: String as PropType<PriceUnit>,
    consumption: [String, Number],
    tableClasses: {
      type: String,
      default: "",
    },
    isBuildingKitTariff: {
      type: Boolean,
      default: false,
    },
    isSalesApplication: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isNetto = computed(() => props.priceUnit === PriceUnit.Netto);

    const newBasePriceEqualsOld = computed(() => {
      return isNetto
        ? get(props.prices, "basePrice.0.NETTO", []) ===
            get(props.prices, "basePrice.1.NETTO", [])
        : get(props.prices, "basePrice.0.BRUTTO", []) ===
            get(props.prices, "basePrice.1.BRUTTO", []);
    });

    const additionalBasePriceEqualsOld = computed(() => {
      return isNetto
        ? get(props.prices, "basePrice.1.NETTO", []) ===
            get(props.prices, "basePrice.2.NETTO", [])
        : get(props.prices, "basePrice.1.BRUTTO", []) ===
            get(props.prices, "basePrice.2.BRUTTO", []);
    });

    const newWorkingPriceEqualsOld = computed(() => {
      return isNetto
        ? get(props.prices, "workingPrice.0.NETTO", []) ===
            get(props.prices, "workingPrice.1.NETTO", [])
        : get(props.prices, "workingPrice.0.BRUTTO", []) ===
            get(props.prices, "workingPrice.1.BRUTTO", []);
    });

    const additionalWorkingPriceEqualsOld = computed(() => {
      return isNetto
        ? get(props.prices, "workingPrice.1.NETTO", []) ===
            get(props.prices, "workingPrice.2.NETTO", [])
        : get(props.prices, "workingPrice.1.BRUTTO", []) ===
            get(props.prices, "workingPrice.2.BRUTTO", []);
    });

    const basePricesComps = computed(() => {
      const basePrices = get(
        props.prices,
        "basePrice.0",
        []
      ) as ZnliIssPreisstaffel[];

      const result: Cs67IssPriceComp[] = [];

      const priceComp = get(
        basePrices,
        "PREIS_COMP._-CS67_-ISS_PRICE_COMP",
        undefined
      );

      priceComp && result.push(priceComp);

      return flattenDeep(result);
    });

    const bonusTitle = computed(() =>
      props.isSalesApplication ? "Bonus" : "Neukundenbonus"
    );

    const increasedBasePricesComps = computed(() => {
      const basePrices = get(
        props.prices,
        "basePrice.1",
        []
      ) as ZnliIssPreisstaffel[];

      const result: Cs67IssPriceComp[] = [];

      const priceComp = get(
        basePrices,
        "PREIS_COMP._-CS67_-ISS_PRICE_COMP",
        undefined
      );

      priceComp && result.push(priceComp);

      return flattenDeep(result);
    });

    const additionalIncreasedBasePricesComps = computed(() => {
      const basePrices = get(
        props.prices,
        "basePrice.2",
        []
      ) as ZnliIssPreisstaffel[];

      const result: Cs67IssPriceComp[] = [];

      const priceComp = get(
        basePrices,
        "PREIS_COMP._-CS67_-ISS_PRICE_COMP",
        undefined
      );

      priceComp && result.push(priceComp);

      return flattenDeep(result);
    });

    const workingPricesComps = computed(() => {
      const workingPrices = get(
        props.prices,
        "workingPrice.0",
        []
      ) as ZnliIssPreisstaffel[];

      const result: Cs67IssPriceComp[] = [];

      const priceComp = get(
        workingPrices,
        "PREIS_COMP._-CS67_-ISS_PRICE_COMP",
        undefined
      );

      priceComp && result.push(priceComp);

      return flattenDeep(result);
    });

    const increasedWorkingPricesComps = computed(() => {
      const workingPrices = get(
        props.prices,
        "workingPrice.1",
        []
      ) as ZnliIssPreisstaffel[];

      const result: Cs67IssPriceComp[] = [];

      const priceComp = get(
        workingPrices,
        "PREIS_COMP._-CS67_-ISS_PRICE_COMP",
        undefined
      );

      priceComp && result.push(priceComp);

      return flattenDeep(result);
    });

    const bonusPricesComps = computed(() => {
      const bonusPrices = get(
        props.prices,
        "bonusPrice.0",
        []
      ) as ZnliIssPreisstaffel[];

      const result: Cs67IssPriceComp[] = [];

      const priceComp = get(
        bonusPrices,
        "PREIS_COMP._-CS67_-ISS_PRICE_COMP",
        undefined
      );

      priceComp && result.push(priceComp);

      return flattenDeep(result);
    });

    // const increasedBonusPricesComps = computed(() => {
    //   const bonusPrices = get(
    //     props.prices,
    //     "bonusPrice.1",
    //     []
    //   ) as ZnliIssPreisstaffel[];

    //   const result: Cs67IssPriceComp[] = [];

    //   const priceComp = get(
    //     bonusPrices,
    //     "PREIS_COMP._-CS67_-ISS_PRICE_COMP",
    //     undefined
    //   );

    //   priceComp && result.push(priceComp);

    //   return flattenDeep(result);
    // });

    const workingPriceHT = computed(() =>
      filter(workingPricesComps.value, [
        tariffCalculatorConstants.keys.DESCRIPTION,
        tariffCalculatorConstants.filter.HT,
      ])
    );

    const workingPriceNT = computed(() =>
      filter(workingPricesComps.value, [
        tariffCalculatorConstants.keys.DESCRIPTION,
        tariffCalculatorConstants.filter.NT,
      ])
    );

    const increasedWorkingPriceHT = computed(() =>
      filter(increasedWorkingPricesComps.value, [
        tariffCalculatorConstants.keys.DESCRIPTION,
        tariffCalculatorConstants.filter.HT,
      ])
    );

    const increasedWorkingPriceNT = computed(() =>
      filter(increasedWorkingPricesComps.value, [
        tariffCalculatorConstants.keys.DESCRIPTION,
        tariffCalculatorConstants.filter.NT,
      ])
    );

    const switchingPrice = computed(() =>
      filter(basePricesComps.value, [
        tariffCalculatorConstants.keys.DESCRIPTION,
        tariffCalculatorConstants.filter.SWITCHING_PRICE,
      ])
    );

    const increasedSwitchingPrice = computed(() =>
      filter(increasedBasePricesComps.value, [
        tariffCalculatorConstants.keys.DESCRIPTION,
        tariffCalculatorConstants.filter.SWITCHING_PRICE,
      ])
    );

    const hasSwitchingPrice = computed(
      () =>
        switchingPrice.value.length > 0 ||
        increasedSwitchingPrice.value.length > 0
    );

    const hasIncreasedBasePrice = computed(
      () => (get(props.prices, "basePrice", []) as unknown[]).length > 1
    );

    const hasAdditionalIncreasedBasePrice = computed(
      () => (get(props.prices, "basePrice", []) as unknown[]).length > 2
    );

    const hasIncreasedSwitchingPrice = computed(
      () => increasedSwitchingPrice.value.length > 0
    );

    const hasIncreasedWorkingPrice = computed(
      () => (get(props.prices, "workingPrice", []) as unknown[]).length > 1
    );

    const hasAdditionalIncreasedWorkingPrice = computed(
      () => (get(props.prices, "workingPrice", []) as unknown[]).length > 2
    );

    const hasIncreasedWorkingPriceHT = computed(
      () => increasedWorkingPriceHT.value.length > 0
    );

    const hasIncreasedWorkingPriceNT = computed(
      () => increasedWorkingPriceNT.value.length > 0
    );

    const hasWorkingPricesComps = computed(
      () => workingPriceHT.value.length > 0 && workingPriceNT.value.length > 0
    );

    const hasBonusPriceComps = computed(() => !!bonusPricesComps.value?.length);

    const showBonusPriceComp = computed(
      () =>
        hasBonusPriceComps &&
        props.priceUnit &&
        Number(bonusPricesComps.value[0][props.priceUnit]) > 0
    );

    const unchangedBasePriceComponent = computed(
      () =>
        !hasIncreasedBasePrice.value &&
        (hasIncreasedSwitchingPrice.value ||
          hasIncreasedWorkingPriceHT.value ||
          hasIncreasedWorkingPriceNT.value)
    );

    const additionalUnchangedBasePriceComponent = computed(
      () =>
        !hasAdditionalIncreasedBasePrice.value &&
        hasAdditionalIncreasedWorkingPrice.value
    );

    const unchangedSwitchingPrice = computed(
      () =>
        !hasIncreasedSwitchingPrice.value &&
        (hasIncreasedBasePrice.value ||
          hasIncreasedWorkingPriceHT.value ||
          hasIncreasedWorkingPriceNT.value)
    );

    const unchangedWorkingPriceHT = computed(
      () =>
        !hasIncreasedWorkingPriceHT.value &&
        (hasIncreasedBasePrice.value ||
          hasIncreasedSwitchingPrice.value ||
          hasIncreasedWorkingPriceNT.value)
    );

    const unchangedWorkingPriceNT = computed(
      () =>
        !hasIncreasedWorkingPriceNT.value &&
        (hasIncreasedBasePrice.value ||
          hasIncreasedSwitchingPrice.value ||
          hasIncreasedWorkingPriceHT.value)
    );

    const increasedPriceDate = computed(() =>
      hasIncreasedBasePrice.value
        ? get(props.prices, "basePrice.1.VALID_FROM", undefined)
        : get(props.prices, "workingPrice.1.VALID_FROM", undefined)
    );

    const additionalIncreasedPriceDate = computed(() =>
      hasAdditionalIncreasedBasePrice.value
        ? get(props.prices, "basePrice.2.VALID_FROM", undefined)
        : get(props.prices, "workingPrice.2.VALID_FROM", undefined)
    );

    return {
      isNetto,
      formatNumber,
      formatSAPPrices,
      hasIncreasedBasePrice,
      hasIncreasedWorkingPrice,
      hasAdditionalIncreasedBasePrice,
      hasAdditionalIncreasedWorkingPrice,
      hasWorkingPricesComps,
      hasBonusPriceComps,
      showBonusPriceComp,
      increasedPriceDate,
      additionalIncreasedPriceDate,
      basePricesComps,
      increasedBasePricesComps,
      additionalIncreasedBasePricesComps,
      workingPricesComps,
      workingPriceHT,
      workingPriceNT,
      increasedWorkingPriceHT,
      increasedWorkingPriceNT,
      switchingPrice,
      increasedSwitchingPrice,
      hasSwitchingPrice,
      hasIncreasedSwitchingPrice,
      hasIncreasedWorkingPriceHT,
      hasIncreasedWorkingPriceNT,
      unchangedBasePriceComponent,
      additionalUnchangedBasePriceComponent,
      unchangedSwitchingPrice,
      unchangedWorkingPriceHT,
      unchangedWorkingPriceNT,
      newBasePriceEqualsOld,
      additionalBasePriceEqualsOld,
      newWorkingPriceEqualsOld,
      additionalWorkingPriceEqualsOld,
      bonusTitle,
    };
  },
});
