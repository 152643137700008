














































































































import { defineComponent, computed, PropType } from "@vue/composition-api";
import {
  TransformedProductData,
  TransformedCombinedProductOption,
  TransformedProductOption,
} from "@/api/sap";
import { getSelectableProductCombiOptions } from "@/api/sap/helper/product-options.helper";
import { formatNumber } from "@/helper";

import InfoPopup from "@/shared/components/InfoPopup";
import TariffCalculatorProductOptionInfoPopup from "@/features/tariff-calculator/components/TariffCalculatorProductOptionInfoPopup";

export default defineComponent({
  name: "TariffCalculatorProductDetailsContract",
  components: {
    InfoPopup,
    TariffCalculatorProductOptionInfoPopup,
  },
  props: {
    initialTermInfo: {
      type: String,
    },
    contractRenewalInfo: {
      type: String,
    },
    termOfNoticeInfo: {
      type: String,
    },
    guaranteeType: {
      type: Boolean,
    },
    guaranteeInfo: {
      type: String,
    },
    newCustomerBonusInfo: {
      type: String,
    },
    special: {
      type: String,
    },
    spHint: {
      type: String,
    },
    prices: {
      type: Object,
    },
    priceUnit: {
      type: String,
    },
    bonus: {
      type: String,
    },
    freeKwh: {
      type: String,
    },
    customerNumber: {
      type: String,
    },
    isBasicSupplierTariff: {
      type: Boolean,
    },
    productOptions: {
      type: Array as PropType<TransformedProductOption[]>,
    },
    combinedProductOptions: {
      type: Array as PropType<TransformedCombinedProductOption[]>,
    },
    fullProductData: {
      type: Object as PropType<TransformedProductData>,
      required: true,
    },
    isSalesApplication: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const isConfigurableTariff = computed(() => !!props.productOptions?.length);
    const renderableOptions = computed(() => {
      return props.productOptions?.map((option: TransformedProductOption) => {
        let selectedValue: string | number | undefined = "";
        if (option.type === "FIX_DETAIL" || option.type === "FREE") {
          selectedValue = option.description ? option.description : "Ja";
        } else {
          selectedValue = option.values?.find(
            (value) => value.value === option.selectedOption
          )?.text;
        }
        return {
          id: option.id,
          name: option.name,
          value: selectedValue ?? "",
          info: option.info,
        };
      });
    });

    const selectableCombinedProductOptions = computed(() =>
      getSelectableProductCombiOptions(props.fullProductData)
    );

    const renderableCombinedOptions = computed(() => {
      return selectableCombinedProductOptions?.value?.map(
        (option: TransformedCombinedProductOption) => {
          return {
            id: option.id,
            name: option.name,
            value: option?.optionData?.description
              ? option.optionData.description
              : "Ja",
            info: option.info,
            optionData: option.optionData,
          };
        }
      );
    });

    return {
      formatNumber,
      isConfigurableTariff,
      renderableOptions,
      renderableCombinedOptions,
    };
  },
});
