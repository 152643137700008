import { CustomerType, CustomerTypeFromValue, DivisionType } from "@/api/sap";
import { useWindowUtils } from "@/hooks/useWindowUtils.hook";
import store from "@/store";
import { camelCase, get } from "lodash-es";
import { compress, decompress } from "lz-string";
import { SalesApplicationRouteState } from "..";
import { SalesApplicationRouteConfig } from "@/features/sales-application-route/components/SalesApplicationRoute";

export enum UpsellingStorage {
  NAME = "upselling",
}

export enum UpsellingStateKey {
  PREV_DIVISION_TYPE = "prevDivisionType",
  PERSONAL_DETAILS = "personalDetails",
  DELIVERY_DETAILS = "deliveryDetails",
}

interface UpsellingState {
  [UpsellingStateKey.PREV_DIVISION_TYPE]?: DivisionType;
  [UpsellingStateKey.PERSONAL_DETAILS]?: Partial<
    SalesApplicationRouteState["formData"]["salesApplicationPersonalDetailsStep"]
  >;
  [UpsellingStateKey.DELIVERY_DETAILS]?: Partial<
    SalesApplicationRouteState["formData"]["salesApplicationDeliveryStep"]
  >;
}

const defaultStorageState: UpsellingState = {
  [UpsellingStateKey.PREV_DIVISION_TYPE]: undefined,
} as const;

const compressedDefaultStorageState = compress(
  JSON.stringify(defaultStorageState)
);

export function getErrorPageByType(
  invalidTariffCode: string,
  errorPages: SalesApplicationRouteConfig["links"]["errorPages"]
) {
  switch (invalidTariffCode) {
    case "B":
      return errorPages.errorPageBoniversum;
    case "L":
      return errorPages.errorPageLoading;
    case "U":
      return errorPages.errorPageAlreadyCompleted;
    case "T":
      return errorPages.errorPageValid;
    default:
      return errorPages.errorPage;
  }
}

export function getSuccessPageByType(
  type: string,
  successPages: SalesApplicationRouteConfig["links"]["successPages"],
  errorPages: SalesApplicationRouteConfig["links"]["errorPages"]
) {
  switch (type) {
    case "N":
      return successPages.successMoveIn;
    case "T":
      return successPages.successTariffChange;
    case "V":
      return successPages.successContractChange;
    default:
      return errorPages.errorPage;
  }
}

export function upsellingHelper() {
  const upsellingStorage =
    sessionStorage.getItem(UpsellingStorage.NAME) ??
    compressedDefaultStorageState;

  const decompressedData =
    decompress(upsellingStorage) ??
    decompress(compressedDefaultStorageState) ??
    "{}";

  const parsedData = JSON.parse(decompressedData);

  let state = parsedData as UpsellingState;

  const setValue = async (key: keyof typeof UpsellingStateKey, value: any) => {
    state[UpsellingStateKey[key]] = value;
    await syncState();
  };

  const setState = async (value: UpsellingState) => {
    state = value;
    await syncState();
  };

  const syncState = async () => {
    const compressedData = await compress(JSON.stringify(state));
    sessionStorage.setItem(UpsellingStorage.NAME, compressedData);
  };

  const reset = async () => {
    sessionStorage.setItem(
      UpsellingStorage.NAME,
      compressedDefaultStorageState
    );
  };

  const redirect = async ({
    success,
    unableToFetch,
    errorType,
  }: {
    success: boolean;
    unableToFetch?: boolean;
    errorType?: string;
  }) => {
    const { redirect: windowRedirect } = useWindowUtils();

    const salesApplicationRoute = store.state.salesApplicationRoute;
    const links = salesApplicationRoute.config.links;
    const successPages = links.successPages;
    const errorPages = links.errorPages;
    const currentProduct =
      salesApplicationRoute.formData.salesApplicationTariffStep.product;
    const changeType = salesApplicationRoute.formData.type;

    let resultsURL = "";

    if (unableToFetch) {
      resultsURL = getErrorPageByType("L", errorPages);
      windowRedirect({ resultsURL });
      return;
    }

    if (errorType) {
      resultsURL = getErrorPageByType(errorType, errorPages);
      windowRedirect({ resultsURL });
      return;
    }

    resultsURL = success
      ? getSuccessPageByType(changeType, successPages, errorPages)
      : errorPages.errorPage;

    if (success) {
      const divisionType = currentProduct?.divisionType;
      divisionType && (await setValue("PREV_DIVISION_TYPE", divisionType));
    }

    windowRedirect({ resultsURL });
  };

  const getUpsellingDataFromStorage = () => {
    const personalDetails = get(state, UpsellingStateKey.PERSONAL_DETAILS, {});
    const deliveryDetails = get(state, UpsellingStateKey.DELIVERY_DETAILS, {});

    return {
      personalDetails,
      deliveryDetails,
    };
  };

  return {
    state,
    setState,
    setValue,
    syncState,
    reset,
    redirect,
    getUpsellingDataFromStorage,
  };
}
