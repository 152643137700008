















import { defineComponent, PropType } from "@vue/composition-api";
import { TransformedProductOptionValue } from "@/api/sap/@types";

import InfoPopup from "@/shared/components/InfoPopup";

export default defineComponent({
  name: "TariffCalculatorProductOptionInfoPopup",
  props: {
    id: {
      type: String,
      default: "",
    },
    optionData: {
      type: Object as PropType<TransformedProductOptionValue>,
      default: undefined,
    },
  },
  components: { InfoPopup },
});
