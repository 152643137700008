

























































































































import { get } from "lodash-es";
import { defineComponent, PropType, computed } from "@vue/composition-api";

import { formatNumber } from "@/helper";
import { pushEvent } from "@/helper/pushEvent.helper";
import { PriceUnit } from "@/api/sap";
import store from "@/store";

import { GetProductType } from "@/features/tariff-calculator/state/getters";
import TariffCalculatorProductDetailsPrices from "@/features/tariff-calculator/components/TariffCalculatorProductDetailsPrices";
import TariffCalculatorProductDetailsBulkPrices from "@/features/tariff-calculator/components/TariffCalculatorProductDetailsBulkPrices";
import TariffCalculatorProductDetailsContract from "@/features/tariff-calculator/components/TariffCalculatorProductDetailsContract";
import TariffCalculatorPriceInfo from "@/features/tariff-calculator/components/TariffCalculatorPriceInfo";

export const getTariffConsumption = (productData: GetProductType) =>
  computed(() =>
    productData.lowTariff && productData.highTariff
      ? `${Number(productData.highTariff).toLocaleString(
          "de"
        )} kWh HT / ${Number(productData.lowTariff).toLocaleString(
          "de"
        )} kWh NT`
      : Number(productData.consumption).toLocaleString("de")
  );

export default defineComponent({
  name: "TariffCalculatorProductDetails",
  props: {
    productData: {
      type: Object as PropType<GetProductType>,
      required: true,
    },
    hasBadge: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
    },
  },
  components: {
    TariffCalculatorProductDetailsPrices,
    TariffCalculatorProductDetailsBulkPrices,
    TariffCalculatorProductDetailsContract,
    TariffCalculatorPriceInfo,
  },
  setup(props, { root }) {
    const isNetto = computed(
      () => props.productData.priceUnit === PriceUnit.Netto
    );

    const legalLinkText = computed(() =>
      props.productData.isBasicSupplierTariff
        ? "Grundversorgungsbedingungen (GVV)"
        : "Allgemeinen Versorgungsbedingungen (AVB)"
    );

    const legalLinkIdentifier = computed(() =>
      props.productData.isBasicSupplierTariff
        ? `${props.productData.calculatorMode.toLowerCase()}_base`
        : props.productData.calculatorMode.toLowerCase()
    );

    // Get Legal Link from config for Grundversorgung/Standard Contract, fallback to standard contract link
    const legalLink = computed(
      () =>
        props?.config?.contractUrls[legalLinkIdentifier.value] ??
        props?.config?.contractUrls[
          props.productData.calculatorMode.toLowerCase()
        ]
    );

    const tariffConsumption = getTariffConsumption(props.productData);
    const isElectricityMode = computed(
      () => props?.productData.calculatorMode === "Electricity"
    );

    const bonusPrice = computed(
      () => get(props.productData.prices, "bonusPrice[0]", []) as string
    );

    const isBuildingKitTariff = computed(
      () => !!props?.productData?.productOptions?.length
    );

    const detailsClass = computed(() => {
      if (
        props.productData.hasBulkPrices ||
        props.productData.hasIncreasedBasePrice ||
        props.productData.hasIncreasedWorkingPrice
      ) {
        return "details--with-bulk-prices";
      }

      return "details--default";
    });

    function close() {
      root.$modal.hide(props.productData.id);
    }

    function concludeTariff() {
      pushEvent({
        event: "addToCart",
        ecommerce: {
          currencyCode: "EUR",
          add: {
            products: [
              {
                name: props.productData.name,
                id: props.productData.id,
                price: formatNumber(
                  get(props.productData.prices, [
                    "annualCosts",
                    "0",
                    props.productData.priceUnit,
                  ])
                ),
                brand: "EVO",
                category: props.productData.calculatorMode,
                quantity: 1,
              },
            ],
          },
        },
      });

      close();

      store.dispatch.startRegistrationRoute({
        product: props.productData,
      });
    }

    return {
      bonusPrice,
      close,
      concludeTariff,
      legalLinkText,
      legalLink,
      detailsClass,
      formatNumber,
      isNetto,
      tariffConsumption,
      isBuildingKitTariff,
      isElectricityMode,
    };
  },
});
