import {
  CustomerType,
  PriceUnit,
  TransformedProductData,
  transformProductData,
} from "@/api/sap";
import { defineGetters } from "direct-vuex";
import { findIndex, max } from "lodash-es";
import { tariffCalculatorModuleGetterContext, TariffCalculatorState } from ".";

export enum GetterType {
  getAllProducts = "getAllProducts",
  getFeaturedProducts = "getFeaturedProducts",
  getAdditionalProducts = "getAdditionalProducts",
  getProductsWithProviderDifference = "getProductsWithProviderDifference",
  hasElectricityCalculator = "hasElectricityCalculator",
}

export type GetProductType = TransformedProductData & {
  // eslint-disable-next-line
  [key: string]: any;
};

const getters = defineGetters<TariffCalculatorState>()({
  [GetterType.getAllProducts](...args): GetProductType[] {
    const { state } = tariffCalculatorModuleGetterContext(args);
    const { products, lastRequest } = state;

    let transformedProducts: GetProductType[] = [];

    if (products && products.length > 0) {
      transformedProducts = products.map((product) => {
        const transformedProductData = transformProductData(
          product,
          undefined,
          lastRequest.customerType
        );

        const priceUnit =
          lastRequest.customerType === CustomerType.BusinessCustomer
            ? PriceUnit.Netto
            : PriceUnit.Brutto;

        const consumption = max([
          lastRequest.powerConsumption,
          lastRequest.gasConsumption,
          lastRequest.highTariff,
          lastRequest.lowTariff,
        ]);

        return {
          ...lastRequest,
          ...transformedProductData,
          consumption,
          priceUnit,
        };
      });
    }

    return transformedProducts;
  },
  [GetterType.getFeaturedProducts](...args): GetProductType[] {
    const { getters } = tariffCalculatorModuleGetterContext(args);

    return getters.getAllProducts.slice(0, 3);
  },
  [GetterType.getAdditionalProducts](...args): GetProductType[] {
    const { getters } = tariffCalculatorModuleGetterContext(args);

    return getters.getAllProducts.slice(3);
  },
  [GetterType.getProductsWithProviderDifference](...args): GetProductType[] {
    const { getters } = tariffCalculatorModuleGetterContext(args);

    const productsWithDifference: GetProductType[] = [];

    getters.getAllProducts.forEach((product) => {
      if (product.defaultProviderDifference) {
        productsWithDifference.push(product);
      }
    });

    return productsWithDifference;
  },
  [GetterType.getProductsWithProviderDifference](...args): GetProductType[] {
    const { getters } = tariffCalculatorModuleGetterContext(args);

    const productsWithDifference: GetProductType[] = [];

    getters.getAllProducts.forEach((product) => {
      if (product.defaultProviderDifference) {
        productsWithDifference.push(product);
      }
    });

    return productsWithDifference;
  },
  [GetterType.hasElectricityCalculator](...args): boolean {
    const { state } = tariffCalculatorModuleGetterContext(args);
    const { availableCalculators } = state;

    return findIndex(availableCalculators, ["mode", "Electricity"]) >= 0;
  },
});

export default getters;
