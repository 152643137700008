import axios, { AxiosInstance } from "axios";
import axiosRetry from "axios-retry";

import { HOST } from "@/constants";

export const FUNDING_PROJECT_CONFIG = {
  baseUrl: `${HOST}/api/funding-projects`,
};

export const fundingProjectsAPI: AxiosInstance = axios.create({
  baseURL: FUNDING_PROJECT_CONFIG.baseUrl,
  method: "get",
});

axiosRetry(fundingProjectsAPI, {
  retries: 5,
  retryDelay: (retryCount) => {
    return retryCount * 500;
  },
  retryCondition: ({ response }) => {
    if (response) {
      const { status } = response;

      return status >= 400;
    }

    return false;
  },
});

export default {
  FUNDING_PROJECT_CONFIG,
  fundingProjectsAPI,
};
