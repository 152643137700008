import { SapResponse } from "./sap-response.interface";

export type ReadLieferantResponse = SapResponse<ReadLieferantData>;

export interface ReadLieferantData {
  XT_ESERVPROVT: XtEservprovt;
}

export interface XtEservprovt {
  ESERVPROVT: Eservprovt[];
}

export interface Eservprovt {
  MANDT: string;
  SPRAS: Spras | string;
  SERVICEID: string;
  SP_NAME: string;
}

export enum Spras {
  D = "D",
}
