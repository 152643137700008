














































































































































import { defineComponent, PropType, computed } from "@vue/composition-api";
import { get, toNumber } from "lodash-es";

import { PriceUnit, STAFFELNClass, ZnliIssPreisstaffel } from "@/api/sap";
import { formatNumber } from "@/helper";

export default defineComponent({
  name: "TariffCalculatorProductDetailsBulkPrices",
  props: {
    prices: Object as PropType<Record<string, STAFFELNClass>>,
    priceUnit: String as PropType<PriceUnit>,
    tableClasses: {
      type: String,
      default: "",
    },
  },
  setup({ prices, priceUnit }) {
    const isNetto = computed(() => priceUnit === PriceUnit.Netto);

    const baseBulkPrices = computed(
      () =>
        get(
          prices,
          "basePrice[0].STAFFELN.ZNLI_ISS_PREISSTAFFEL",
          []
        ) as ZnliIssPreisstaffel[]
    );

    const workingBulkPrices = computed(
      () =>
        get(
          prices,
          "workingPrice[0].STAFFELN.ZNLI_ISS_PREISSTAFFEL",
          []
        ) as ZnliIssPreisstaffel[]
    );

    const increasedBaseBulkPrices = computed(
      () =>
        get(
          prices,
          "basePrice[1].STAFFELN.ZNLI_ISS_PREISSTAFFEL",
          []
        ) as ZnliIssPreisstaffel[]
    );

    const increasedWorkingBulkPrices = computed(
      () =>
        get(
          prices,
          "workingPrice[1].STAFFELN.ZNLI_ISS_PREISSTAFFEL",
          []
        ) as ZnliIssPreisstaffel[]
    );

    const pricesItemsCount = computed(() =>
      baseBulkPrices.value > workingBulkPrices.value
        ? baseBulkPrices.value.length
        : workingBulkPrices.value.length
    );

    const tableData = computed(() => {
      const result: {
        from: number;
        to: number;
        formattedFrom: string;
        formattedTo: string;
        basePrice: Pick<ZnliIssPreisstaffel, "NETTO" | "BRUTTO" | "EINHEIT">;
        workingPrice: Pick<ZnliIssPreisstaffel, "NETTO" | "BRUTTO" | "EINHEIT">;
      }[] = [];

      for (let index = 0; index < pricesItemsCount.value; index++) {
        const currentBaseBulkPrices = get(baseBulkPrices.value, `${index}`, {});
        const currentWorkingBulkPrices = get(
          workingBulkPrices.value,
          `${index}`,
          {}
        );
        const currentFrom = toNumber(get(currentBaseBulkPrices, "VONZONE", -1));
        const currentTo = toNumber(get(currentBaseBulkPrices, "BISZONE", -1));

        result.push({
          from: currentFrom,
          to: currentTo,
          formattedFrom: currentFrom.toLocaleString("de"),
          formattedTo: currentTo.toLocaleString("de"),
          basePrice: {
            NETTO: get(currentBaseBulkPrices, "NETTO"),
            BRUTTO: get(currentBaseBulkPrices, "BRUTTO"),
            EINHEIT: get(currentBaseBulkPrices, "EINHEIT"),
          },
          workingPrice: {
            NETTO: get(currentWorkingBulkPrices, "NETTO"),
            BRUTTO: get(currentWorkingBulkPrices, "BRUTTO"),
            EINHEIT: get(currentWorkingBulkPrices, "EINHEIT"),
          },
        });
      }

      return result;
    });

    const increasedTableData = computed(() => {
      const result: {
        from: number;
        to: number;
        formattedFrom: string;
        formattedTo: string;
        basePrice: Pick<ZnliIssPreisstaffel, "NETTO" | "BRUTTO" | "EINHEIT">;
        workingPrice: Pick<ZnliIssPreisstaffel, "NETTO" | "BRUTTO" | "EINHEIT">;
      }[] = [];

      for (
        let index = 0;
        index < increasedBaseBulkPrices.value.length;
        index++
      ) {
        const currentBaseBulkPrices = get(
          increasedBaseBulkPrices.value,
          `${index}`,
          {}
        );
        const currentWorkingBulkPrices = get(
          increasedWorkingBulkPrices.value,
          `${index}`,
          {}
        );
        const currentFrom = toNumber(get(currentBaseBulkPrices, "VONZONE", -1));
        const currentTo = toNumber(get(currentBaseBulkPrices, "BISZONE", -1));

        result.push({
          from: currentFrom,
          to: currentTo,
          formattedFrom: currentFrom.toLocaleString("de"),
          formattedTo: currentTo.toLocaleString("de"),
          basePrice: {
            NETTO: get(currentBaseBulkPrices, "NETTO"),
            BRUTTO: get(currentBaseBulkPrices, "BRUTTO"),
            EINHEIT: get(currentBaseBulkPrices, "EINHEIT"),
          },
          workingPrice: {
            NETTO: get(currentWorkingBulkPrices, "NETTO"),
            BRUTTO: get(currentWorkingBulkPrices, "BRUTTO"),
            EINHEIT: get(currentWorkingBulkPrices, "EINHEIT"),
          },
        });
      }

      return result;
    });

    return {
      isNetto,
      tableData,
      formatNumber,
      increasedTableData,
    };
  },
});
