import { get } from "lodash-es";
import { GetConfigurationData } from "../@types";
import { getConfigurationAction } from "../actions";

export const getConfiguration = async () => {
  const response = await getConfigurationAction();
  const configurationData: GetConfigurationData = get(response, "0.DATA");

  return configurationData;
};
