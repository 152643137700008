






























import { defineComponent, PropType } from "@vue/composition-api";

import { SalesApplicationStepState } from "../SalesApplicationStep";

export default defineComponent({
  name: "SalesApplicationStepCount",
  props: {
    state: {
      type: String as PropType<SalesApplicationStepState>,
      default: SalesApplicationStepState.unvisited,
    },
    count: {
      type: Number,
      required: true,
    },
    checked: {
      type: Boolean,
    },
    valid: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      SalesApplicationStepState,
    };
  },
});
