import { CustomerType } from "@/api/sap";

const host = process.env.IS_PROTOTYPE ? "http://www.evo.test" : "";

export const config = {
  paths: {
    tariffHeads: `${host}/fileadmin/user_upload/Tarife/Tarifkoepfe/`,
    badges: `${host}/fileadmin/user_upload/Tarife/Badges/CO_BADGES_`,
  },
  extensions: {
    tariffHead: ".svg",
    tariffHeadNegative: "_n.svg",
    badge: ".svg",
  },
  defaults: {
    sliderOptions: {
      max: 30000,
      interval: 100,
    },
  },
  powerConsumption: {
    [CustomerType.PrivateCustomer]: {
      Electricity: {
        sliderOptions: {
          max: 10000,
          name: "Privatkunden",
          iconName: "PrivateCustomers",
          iconPathNames: [
            "Person1",
            "Person2",
            "Person3",
            "Person4",
            "Person5",
          ],
        },
        steps: [1800, 2700, 3400, 4000, 6000],
      },
      Gas: {
        sliderOptions: {
          max: 35000,
          name: "Privatkunden",
          iconName: "PrivateHouses",
          iconPathNames: ["Haus1", "Haus2", "Haus3", "Haus4"],
        },
        steps: [5000, 14000, 21000, 28000],
      },
      Heating: {
        sliderOptions: {
          max: 35000,
          name: "Privatkunden",
          iconName: "PrivateHouses",
          iconPathNames: ["Haus1", "Haus2", "Haus3", "Haus4"],
        },
        steps: [5000, 14000, 21000, 28000],
      },
    },
    [CustomerType.BusinessCustomer]: {
      Electricity: {
        sliderOptions: {
          max: 10000,
          name: "Geschäftskunden",
          iconName: "BusinessCustomers",
          iconPathNames: [
            "Person1",
            "Person2",
            "Person3",
            "Person4",
            "Person5",
          ],
        },
        steps: [1800, 2700, 3400, 4000, 6000],
      },
      Gas: {
        sliderOptions: {
          max: 35000,
          name: "Geschäftskunden",
          iconName: "BusinessHouses",
          iconPathNames: ["Haus1", "Haus2", "Haus3", "Haus4"],
        },
        steps: [5000, 14000, 21000, 28000],
      },
      Heating: {
        sliderOptions: {
          max: 35000,
          name: "Geschäftskunden",
          iconName: "BusinessHouses",
          iconPathNames: ["Haus1", "Haus2", "Haus3", "Haus4"],
        },
        steps: [5000, 14000, 21000, 28000],
      },
    },
  },
};

export default config;
