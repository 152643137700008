






















import { defineComponent, PropType, ref, computed } from "@vue/composition-api";
import { ValidationObserver } from "vee-validate";

import store from "@/store";

import SalesApplicationStepSubmit from "../SalesApplicationStepSubmit";
import { pushEvent } from "@/helper/pushEvent.helper";

export default defineComponent({
  name: "SalesApplicationStepForm",
  props: {
    onSubmit: {
      type: Function as PropType<() => void>,
      default: () => undefined,
    },
    onSubmitTitle: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    customErrors: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showErrorMessage: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationObserver,
    SalesApplicationStepSubmit,
  },
  setup(props) {
    const nextStep = store.dispatch.salesApplicationRouteNextStep;

    const observerRef = ref<any>();
    const hasCustomErrors = computed(() => props.customErrors.length > 0);

    async function handleFormSubmit() {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      observerRef.value!.reset();

      await props.onSubmit();

      if (!hasCustomErrors.value) {
        if (!store.getters.isLastSalesApplicationStepActive) {
          pushEvent({
            event: "EEcheckout",
            ecommerce: {
              checkout: {
                actionField: {
                  step: store.getters.currentSalesApplicationStep + 1,
                },
              },
            },
          });
        }

        nextStep();
      }
    }

    return {
      handleFormSubmit,
      observerRef,
      hasCustomErrors,
    };
  },
});
