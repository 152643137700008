import { DivisionType } from "@/api/sap";
import { Cs67IssProductInfo } from "@/api/sap/@types/read-products-response.interface";
import { defineMutations } from "direct-vuex";
import {
  ProdOption,
  ProdOptionCombi,
} from "@/api/sap/@types/read-products-response.interface";
import Vue from "vue";
import { get } from "lodash-es";
import { TariffCalculatorState } from ".";

export enum MutationType {
  setTariffCalculatorConfig = "setTariffCalculatorConfig",
  setCurrentMode = "setCurrentMode",
  setLoading = "setLoading",
  setErrors = "setErrors",
  setPriceUnit = "setPriceUnit",
  setProducts = "setProducts",
  setProductSelectedOption = "setProductSelectedOption",
  setProductSelectedCombiOption = "setProductSelectedCombiOption",
  setProductPrices = "setProductPrices",
  setLastRequest = "setLastRequest",
  setAvailableCalculators = "setAvailableCalculators",
}

export default defineMutations<TariffCalculatorState>()({
  [MutationType.setTariffCalculatorConfig](
    state,
    config: Partial<TariffCalculatorState["config"]>
  ) {
    Vue.set(state, "config", {
      ...state.config,
      ...config,
    });
  },
  [MutationType.setCurrentMode](state, mode: keyof typeof DivisionType) {
    state.currentMode = mode;
  },
  [MutationType.setProductSelectedOption](
    state,
    { productId, optionId, selectedValue }
  ) {
    const { products } = state;
    const newProducts = products?.map((product) => {
      // When matching product ID
      if (product["PRODUCT_ID"] === productId) {
        let options = get(product, ["PROD_OPTIONS", "ZEVO_PRODUCT_OPTION"], []);
        // Catch Api Data case that one option gets passed as Object from SAP
        options = Array.isArray(options) ? options : [options];
        // For matching Option set selected Value
        options?.forEach((option: ProdOption) => {
          if (option["ID"] === optionId) {
            option["SELECTED_VALUE"] = selectedValue;
          }
        });
      }
      return product;
    });

    Vue.set(state, "products", newProducts);
  },
  [MutationType.setProductSelectedCombiOption](
    state,
    { productId, optionId, selectedValue }
  ) {
    const { products } = state;
    const newProducts = products?.map((product) => {
      // When matching product ID
      if (product["PRODUCT_ID"] === productId) {
        let options = get(
          product,
          ["PROD_OPTIONS_COMBI", "ZEVO_PRODUCT_OPTION_COMBI"],
          []
        );
        // Catch Api Data case that one option gets passed as Object from SAP
        options = Array.isArray(options) ? options : [options];
        // For matching Option set selected Value
        options?.forEach((option: ProdOptionCombi) => {
          if (option["ID"] === optionId) {
            option["SELECTED_VALUE"] = selectedValue;
          }
        });
      }
      return product;
    });

    Vue.set(state, "products", newProducts);
  },
  [MutationType.setProductPrices](state, { productId, prices }) {
    const { products } = state;
    const newProducts = products?.map((product) => {
      if (product["PRODUCT_ID"] === productId) {
        product["PRICES"] = prices;
      }
      return product;
    });
    Vue.set(state, "products", newProducts);
  },
  [MutationType.setErrors](state, errors: string[]) {
    Vue.set(state, "errors", errors);
  },
  [MutationType.setLoading](state, isLoading: boolean) {
    state.loading = isLoading;
  },
  [MutationType.setProducts](state, products: Cs67IssProductInfo[] | null) {
    Vue.set(state, "products", products);
  },
  [MutationType.setLastRequest](state, payload: unknown) {
    Vue.set(state, "lastRequest", payload);
  },
  [MutationType.setAvailableCalculators](state, payload: unknown[]) {
    Vue.set(state, "availableCalculators", payload);
  },
});
