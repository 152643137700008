





















































































































































































































































































































































































































































































































































































import {
  defineComponent,
  computed,
  PropType,
  ref,
  watchEffect,
  watch,
} from "@vue/composition-api";
import { mask } from "vue-the-mask";
import { find, omit } from "lodash-es";
import { formatISO, isBefore, startOfDay, subDays } from "date-fns";

import { getConfiguration, CustomerType } from "@/api/sap";
import store from "@/store";
import { useAsyncComponent } from "@/hooks/useAsyncComponent";

import { BaseRadioButtonProps } from "@/shared/components/base/BaseRadioButton";
import InfoPopup from "@/shared/components/InfoPopup";

import { initialSalesApplicationRouteState } from "@/features/sales-application-route/state";
import SapPostalCodeField from "@/features/shared/SapPostalCodeField";
import SapStreetSelectField from "@/features/shared/SapStreetSelectField";

import { SalesApplicationStepState } from "../../../SalesApplicationStep";
import SalesApplicationProviders from "../../../SalesApplicationProviders";
import SalesApplicationStepContent from "../../../SalesApplicationStepContent";
import SalesApplicationStepForm from "../../../SalesApplicationStepForm";
import SalesApplicationDateOfDelivery from "../../../SalesApplicationDateOfDelivery";
import SalesApplicationSalutations from "../../../SalesApplicationSalutations";
import SalesApplicationDeliveryStepOverview from "./SalesApplicationDeliveryStepOverview.vue";

import { DeliveryType } from ".";

export default defineComponent({
  name: "SalesApplicationDeliveryStep",
  components: {
    InfoPopup,
    SapPostalCodeField,
    SapStreetSelectField,
    SalesApplicationProviders,
    SalesApplicationStepContent,
    SalesApplicationStepForm,
    SalesApplicationDateOfDelivery,
    SalesApplicationDeliveryStepOverview,
    SalesApplicationSalutations,
  },
  props: {
    state: {
      type: String as PropType<SalesApplicationStepState>,
      default: SalesApplicationStepState.unvisited,
    },
  },
  directives: {
    mask,
  },
  setup(props, ctx) {
    const { withLoading } = useAsyncComponent({
      root: ctx.root,
      ids: ["SalesApplicationDeliveryStep"],
    });

    const abilities = store.getters.salesApplicationAbilities;
    const deliveryAbilities = abilities["salesApplicationDeliveryStep"];

    const isDisabled = (fieldName: string, value?: string | boolean) =>
      value
        ? !deliveryAbilities.canEdit ||
          deliveryAbilities.restrictedFields?.includes(fieldName)
        : !deliveryAbilities.canEditWhenEmpty;

    const stateCustomerType = computed(
      () => store.getters.salesApplicationRouteCustomerType
    );

    withLoading(
      getConfiguration().then((configurationData) => {
        configurationData && store.commit.setConfigData(configurationData);
      })
    );

    const showMeterReadingPopup = () => {
      ctx.root.$modal.show("MeterReadingPopup");
    };

    const regionId = computed(
      () =>
        store.state.salesApplicationRoute.formData.salesApplicationTariffStep
          .product?.regionId || ""
    );

    const formData = ref({
      ...store.getters.salesApplicationDeliveryFormData,
    });

    const initialData = store.getters.salesApplicationDeliveryFormData;

    const tariffTypeText = computed(
      () => store.getters.salesApplicationRouteTariffTypeText
    );

    watch(
      () => props.state,
      (currentState, prevState) => {
        if (
          currentState !== prevState &&
          prevState === SalesApplicationStepState.visited
        ) {
          formData.value = {
            ...store.getters.salesApplicationDeliveryFormData,
          };
        }
      }
    );

    watch(
      () => formData.value.deliveryMethod,
      (currentDeliveryMethod, prevDeliveryMethod) => {
        if (currentDeliveryMethod !== prevDeliveryMethod) {
          formData.value = {
            deliveryMethod: formData.value.deliveryMethod,
            deliveryMethodData: formData.value.deliveryMethodData,
            ...omit(store.getters.salesApplicationDeliveryFormData, [
              "deliveryMethod",
              "deliveryMethodData",
            ]),
          };
        }
      }
    );

    const methods = [
      {
        name: "deliveryMethod",
        value: DeliveryType.CHANGE_OF_SUPPLIER,
        title: "Versorgerwechsel",
        description:
          "Ich bin aktuell Kunde eines anderen Versorgers und möchte zu evo wechseln.",
      },
      {
        name: "deliveryMethod",
        value: DeliveryType.MOVE_IN,
        title: "Neueinzug",
        description:
          stateCustomerType.value == "1"
            ? "Ich ziehe um und möchte in meinem neuen Zuhause durch die evo beliefert werden."
            : "Wir möchten an unserem neuen Standort durch die evo beliefert werden.",
      },
      {
        name: "deliveryMethod",
        value: DeliveryType.TARIFF_CHANGE,
        title: "Vertragswechsel",
        description:
          "Ich habe aktuell einen anderen Vertrag und möchte diesen wechseln.",
      },
    ];

    const deliveryMethods = ref<BaseRadioButtonProps[]>(methods);

    const selectableDeliveryMethods = ref<BaseRadioButtonProps[]>(
      methods.filter((method) => {
        return method.value === formData.value.deliveryMethod;
      })
    );

    const deliveryMethodData = computed(() =>
      find(deliveryMethods.value, ["value", formData.value.deliveryMethod])
    );

    const isChangeOfSupplier = computed(
      () => formData.value.deliveryMethod === DeliveryType.CHANGE_OF_SUPPLIER
    );

    const isTariffChange = computed(
      () => formData.value.deliveryMethod === DeliveryType.TARIFF_CHANGE
    );

    const divisionType = computed(
      () => store.getters.salesApplicationTariffFormData.product?.divisionType
    );

    const showHTNTMeterReading = computed(
      () => store.getters.showHTNTMeterReading
    );

    const cancellationTypes = ref<BaseRadioButtonProps[]>([
      {
        name: "cancellationType",
        value: "authorization",
        title:
          "Hiermit bevollmächtige ich die Energieversorgung Oberhausen Aktiengesellschaft (evo), meinen bestehenden Energieliefervertrag zu kündigen und die für die Energielieferung notwendigen Verträge mit dem <u>zuständigen</u> Netzbetreiber abzuschließen.",
      },
      {
        name: "cancellationType",
        value: "canceled",
        title: `Ich habe meinen bestehenden ${tariffTypeText.value}vertrag bereits gekündigt.`,
      },
    ]);

    const isContractCancelled = computed(
      () => formData.value.cancellationType === cancellationTypes.value[1].value
    );

    const addressTypes = ref<BaseRadioButtonProps[]>([
      {
        name: "Postanschrift",
        value: "Postanschrift",
        title: "Postanschrift",
      },
      {
        name: "Postfach",
        value: "Postfach",
        title: "Postfach",
      },
    ]);

    const isMailboxNumberType = computed(
      () =>
        formData.value.billingAddress.addressType ===
        addressTypes.value[1].value
    );

    const isDeliveryStartInPast = computed(
      () =>
        formData.value.deliveryStart &&
        isBefore(new Date(formData.value.deliveryStart), new Date())
    );

    const today = ref(startOfDay(new Date()));
    const minTerminationDate = computed(() => {
      const deliveryStartDate = formData.value.deliveryStart;
      const currentMinDate = deliveryStartDate || today.value;
      const minTerminationDate = subDays(
        startOfDay(new Date(currentMinDate)),
        1
      );
      const formattedMinTerminationDate = formatISO(minTerminationDate, {
        representation: "date",
      });

      return formattedMinTerminationDate;
    });

    watchEffect(() => {
      formData.value.meterNumber = formData.value.meterNumber.toUpperCase();
    });

    const terminationDateInfo = ref<string[]>([]);

    const deliveryStartChangeCount = ref(0);
    watch(
      () => formData.value.deliveryStart,
      (currentValue, prevValue) => {
        if (!prevValue || currentValue === prevValue) {
          return;
        }

        if (deliveryStartChangeCount.value) {
          terminationDateInfo.value.unshift(
            "Achtung: Das Kündigungsdatum wurde angepasst, da Sie ein anderes Lieferdatum gewählt haben."
          );
        }

        deliveryStartChangeCount.value++;
      }
    );

    watch(
      () => formData.value.terminationDate,
      (currentValue, prevValue) => {
        if (
          currentValue === prevValue ||
          currentValue === minTerminationDate.value
        ) {
          return;
        }

        terminationDateInfo.value = [];
      }
    );

    function onSubmit() {
      const data = {
        ...omit(formData.value, ["billingAddress"]),
        deliveryMethodData: deliveryMethodData.value,
        previousSupplier: formData.value.previousSupplierData?.SP_NAME,
        cancellationTypeData: find(cancellationTypes.value, [
          "value",
          formData.value.cancellationType,
        ]),
        terminationDate:
          formData.value.cancellationType === cancellationTypes.value[0].value
            ? ""
            : formData.value.terminationDate,
        billingAddress: {
          ...initialSalesApplicationRouteState.formData
            .salesApplicationDeliveryStep.billingAddress,
          ...(formData.value.differentBillingAddress &&
            omit(
              formData.value.billingAddress,
              isMailboxNumberType.value
                ? ["street", "houseNumber"]
                : ["mailboxNumber"]
            )),
        },
      };

      store.commit.setDeliveryData(data);
    }

    return {
      divisionType,
      deliveryMethodData,
      selectableDeliveryMethods,
      deliveryAbilities,
      isDisabled,
      tariffTypeText,
      initialData,
      formData,
      isChangeOfSupplier,
      isTariffChange,
      isContractCancelled,
      isMailboxNumberType,
      deliveryMethods,
      isDeliveryStartInPast,
      showHTNTMeterReading,
      cancellationTypes,
      addressTypes,
      today,
      minTerminationDate,
      onSubmit,
      CustomerType,
      regionId,
      terminationDateInfo,
      showMeterReadingPopup,
    };
  },
});
