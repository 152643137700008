import { SapResponse } from "./sap-response.interface";

export type ReadProductsResponse = SapResponse<ProductsResponseData>;
export type ReadProductsWithOptionsResponse = SapResponse<
  ProductsWithOptionsResponseData
>;

export interface ProductsResponseData {
  PRODUCTS: Products;
}

export interface ProductsWithOptionsResponseData {
  DATA: ProductsWithOptionsResponseProduct;
}
export interface ProductsWithOptionsResponseProduct {
  PRODUCT: Cs67IssProductInfo[];
}

export interface Products {
  "_-CS67_-ISS_PRODUCT_INFO": Cs67IssProductInfo[];
}

export interface Cs67IssProductInfo {
  PRODUCT_ID: string;
  SPARTE: string;
  PROD_TYPE: unknown[];
  PROD_PROP: ProdProp;
  PRODUCT_NAME: string;
  PRICES: Prices;
  PROD_DESC: ProdDesc;
  PROD_OPTIONS?: ProdOptions;
  PROD_OPTIONS_COMBI?: ProdOptionsCombi;
  PRIORITY: string;
  ACTIVE: unknown[];
  ERSTLAUF: string;
  ERSTPERIO: string;
  ERSTLAUF_ENDE: string;
  KUENDDAT: string;
  KFRIST: string;
  KUENPER: string;
  VERLAENG: string;
  VERPER: string;
  ABSZYK: string;
  NKB: string;
  PREISGARANTIE: string;
  GARANTIEMONATE: string;
  GARANTIEART: string;
  REGION_ID: string;
  SPONSORSHIP_ID: unknown[];
  PROD_GROUP: ProdGroup;
  DEFAULT_PROVIDER_DIFFERENCE: DefaultProviderDifference;
}

export interface Prices {
  "_-CS67_-ISS_PRICE_INFO": Cs67IssPriceInfo[];
}

export interface Cs67IssPriceInfo {
  PREIS_ART: string;
  PREIS_ART_TEXT: string;
  BRUTTO: string;
  NETTO: string;
  EINHEIT: string;
  PREIS_COMP: unknown[] | PREISCOMPClass;
  VALID_FROM: string;
  VALID_TO: string;
  DESCRIPTION: unknown[];
  STAFFELN: unknown[] | STAFFELNClass;
}

export enum Einheit {
  CentKWh = "Cent/kWh",
  EURJahr = "EUR/Jahr",
  Eur = "EUR",
}

export interface PREISCOMPClass {
  "_-CS67_-ISS_PRICE_COMP": Cs67IssPriceComp;
}

export interface Cs67IssPriceComp {
  BESCHREIBUNG: string;
  BRUTTO: string;
  NETTO: string;
  EINHEIT: string;
}

export interface STAFFELNClass {
  ZNLI_ISS_PREISSTAFFEL: ZnliIssPreisstaffel[];
}

export interface ZnliIssPreisstaffel {
  BRUTTO: string;
  NETTO: string;
  EINHEIT: Einheit;
  VONZONE: string;
  BISZONE: string;
}

export interface ProdDesc {
  "_-CS67_-ISS_PROD_DESC": Cs67IssProdDesc[];
}

export enum ProdDescType {
  ADVANTAGES = "ADVANTAGES",
  HEADER = "HEADER",
  PROD_DESC = "PROD_DESC",
  DURATION = "DURATION",
  HIGHLIGHT = "HIGHLIGHT",
  PERF_DESC = "PERF_DESC",
  BADGES = "BADGES",
  SPECIAL = "SPECIAL",
  SP_HINT = "SP_HINT",
}

export interface Cs67IssProdDesc {
  PROD_DESC_TYPE: keyof typeof ProdDescType;
  PROD_DESC_CONTENT: string;
}

export interface ProdGroup {
  ZNLI_PRODUCT_GROUP: string;
}

export interface DefaultProviderDifference {
  NETTO: string;
  BRUTTO: string;
}

export interface ProdProp {
  "_-CS67_-PROD_PROP": string[];
}

export interface ProdOptions {
  ZEVO_PRODUCT_OPTION: ProdOption[];
}

export interface ProdOption {
  ID: string;
  NAME: string;
  DESC: string;
  VALID_FROM: string;
  VALID_TO: string;
  TYPE: string;
  VALUES: ProdOptionValues;
  SELECTED_VALUE?: string | number | boolean | undefined;
}

export interface ProdOptionValues {
  ZEVO_PRODUCT_OPTION_VALUE: ProdOptionValue[];
}

export interface ProdOptionValue {
  VALUE: string | number;
  VALUE_TXT: string;
  DEFAULT_VALUE?: string | number;
}

export interface ProdOptionsCombi {
  ZEVO_PRODUCT_OPTION_COMBI: ProdOptionCombi[] | ProdOptionCombi;
}

export interface ProdOptionCombi {
  ID: string;
  NAME: string;
  DESC: string;
  VALID_FROM: string;
  VALID_TO: string;
  OPTIONS: ProdOptionsCombiOptions[];
  SELECTED_VALUE?: string | number | boolean | undefined;
}

export interface ProdOptionsCombiOptions {
  ZNLI_ISS_PROD_OPT_SEL: ProdOptionsCombiOption;
}

export interface ProdOptionsCombiOption {
  PROD_OPTION: string;
  VALUE?: string | number;
  VALUE_TXT: string;
}

export interface TransformedProductOption {
  id: string | number;
  description: string;
  name: string;
  type: string;
  info?: string;
  optionType: string;
  validFrom?: string;
  validTo?: string;
  values: TransformedProductOptionValue[];
  selectedOption: string | number | boolean | undefined;
}

export interface TransformedProductOptionValue {
  id: string;
  text: string | number;
  value: string | number;
  default?: string | number | boolean;
}

export interface TransformedCombinedProductOption {
  id: string | number;
  description: string;
  name: string;
  type: string;
  info?: string;
  optionType: string;
  validFrom?: string;
  validTo?: string;
  options: TransformedCombinedProductOptionValue[];
  selectedOption: string | number | boolean | undefined;
  optionData?: TransformedProductOption;
}

export interface TransformedCombinedProductOptionValue {
  text: string | number;
  value: string | number;
  option: string | number;
}
