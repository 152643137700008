/**
 * app/public/typo3conf/ext/bu_sap_api/Classes/Sap/SapRequests.php
 * app/public/typo3conf/ext/bu_sap_api/Classes/Controller/ApiController.php (:301) - to disable error message hashing
 *
 * See: Google Docs "Feldbeschreibungen Schnittstelle"
 * Tab: SUPPLY_START
 * Priority: 0 -> not needed / optional (white)
 * Priority: 1 -> optional (orange)
 * Priority: 2 -> mandatory information (red)
 */

import { HeatingType, MeterType } from "@/api/sap";
import store from "@/store";
import { compact, get } from "lodash-es";
import { RegistrationRouteState } from "..";
import { ProdOptionsRequest } from "@/api/sap/@types/read-single-product-request.interface";

// *ok
export const getVstelleAdr = (
  formData: RegistrationRouteState["formData"]
) => ({
  STREET: formData.registrationDeliveryStep.deliveryAddress.street?.LINE, // Priority: 2
  HOUSE_NUM: formData.registrationDeliveryStep.deliveryAddress.houseNumber, // Priority: 2
  HOUSE_NUM2:
    formData.registrationDeliveryStep.deliveryAddress.additionalAddress, // Priority: 1
  POST_CODE1:
    formData.registrationDeliveryStep.deliveryAddress.addressResponse // Priority: 2
      ?.POST_CODE1,
  CITY: formData.registrationDeliveryStep.deliveryAddress.addressResponse?.LINE, // Priority: 2
});

// *ok
export const getPersonData = (
  formData: RegistrationRouteState["formData"]
) => ({
  TITLE: formData.registrationPersonalDetailsStep.salutation?.TITLE || "", // Priority: 2
  LAST_NAME: formData.registrationPersonalDetailsStep.lastName, // Priority: 2
  FIRST_NAME: formData.registrationPersonalDetailsStep.firstName, // Priority: 2
  BIRTHDATE: formData.registrationPersonalDetailsStep.birthDate, // Priority: 2
  PHONE: formData.registrationPersonalDetailsStep.phone, // Priority: 1
  EMAIL: formData.registrationPersonalDetailsStep.emailAddress, // Priority: 2
});

/*
 * // ?Title: See CR: EVOW-302 -> Remove title for business customers
 */
export const getOrgData = (formData: RegistrationRouteState["formData"]) => ({
  TITLE: "0003", // Priority: 2 || '0003': Firma
  ORG_NAME: formData.registrationPersonalDetailsStep.companyName, // Priority: 2
  ORG_NAME2: formData.registrationPersonalDetailsStep.companySuffix, // Priority: 1
  BRANCHE: formData.registrationPersonalDetailsStep.branchType?.IND_SECTOR, // Priority: 2
  PHONE: formData.registrationPersonalDetailsStep.phone, // Priority: 1
  EMAIL: formData.registrationPersonalDetailsStep.emailAddress, // Priority: 2
});

// *ok
export const getBillAltAdr = (
  formData: RegistrationRouteState["formData"],
  isBusinessCustomer: boolean
) => ({
  TITLE:
    formData.registrationDeliveryStep.billingAddress.salutation?.TITLE || "", // Priority: 2
  LAST_NAME: !isBusinessCustomer
    ? formData.registrationDeliveryStep.billingAddress.lastName
    : "", // Priority: 2
  FIRST_NAME: !isBusinessCustomer
    ? formData.registrationDeliveryStep.billingAddress.firstName
    : "", // Priority: 2
  ORG_NAME: formData.registrationPersonalDetailsStep.companyName, // Priority: 2
  ORG_NAME2: formData.registrationPersonalDetailsStep.companySuffix, // Priority: 1
  STREET: formData.registrationDeliveryStep.billingAddress.street?.LINE, // Priority: 2
  HOUSE_NUM: formData.registrationDeliveryStep.billingAddress.houseNumber, // Priority: 2
  POST_CODE1:
    formData.registrationDeliveryStep.billingAddress.addressResponse
      ?.POST_CODE1, // Priority: 2
  POST_CODE2: formData.registrationDeliveryStep.billingAddress.mailboxNumber
    ? formData.registrationDeliveryStep.billingAddress.addressResponse
        ?.POST_CODE1
    : "",
  CITY: formData.registrationDeliveryStep.billingAddress.addressResponse?.LINE, // Priority: 2
  PO_BOX:
    formData.registrationDeliveryStep.billingAddress.mailboxNumber &&
    !formData.registrationDeliveryStep.billingAddress.street
      ? formData.registrationDeliveryStep.billingAddress.mailboxNumber
      : "", // Priority: 0
});

// *ok
export const getContactAltAdr = (
  formData: RegistrationRouteState["formData"]
) => {
  const contactPersonSalutation =
    formData.registrationPersonalDetailsStep.contactPersonSalutation?.TITLE ??
    null;

  return {
    ...getPersonData(formData),
    ...(contactPersonSalutation && {
      TITLE: contactPersonSalutation,
    }),
  };
};

// *ok
export const getMeterReadings = (
  formData: RegistrationRouteState["formData"]
) => {
  const ISS_MTR = [];

  if (
    store.getters.isHeatingTariffType &&
    store.getters.registrationTariffFormData.product?.heatingType ===
      HeatingType.NightStorage
  ) {
    ISS_MTR.push(
      {
        SERIALNO: formData.registrationDeliveryStep.meterNumber, // Priority: 2
        READINGRESULT: formData.registrationDeliveryStep.meterReadingHT, // Priority: 1
        ACTUALMRDATE: formData.registrationDeliveryStep.dateMeterReading, // Priority: 0
        ZWART: MeterType.HIGH_TARIFF, // Priority: 0
      },
      {
        SERIALNO: formData.registrationDeliveryStep.meterNumber, // Priority: 2
        READINGRESULT: formData.registrationDeliveryStep.meterReadingNT, // Priority: 1
        ACTUALMRDATE: formData.registrationDeliveryStep.dateMeterReading, // Priority: 0
        ZWART: MeterType.LOW_TARIFF, // Priority: 0
      }
    );
  } else {
    ISS_MTR.push({
      SERIALNO: formData.registrationDeliveryStep.meterNumber, // Priority: 2
      READINGRESULT: formData.registrationDeliveryStep.meterReading, // Priority: 1
      ACTUALMRDATE: formData.registrationDeliveryStep.dateMeterReading, // Priority: 0
    });
  }

  return [
    // Priority: 0
    {
      ISS_MTR,
    },
  ];
};

// ?check
export const getProductSell = (
  formData: RegistrationRouteState["formData"],
  hasDoubleTariffMeter: boolean,
  isChangeOfSupplier: boolean,
  productOptions?: ProdOptionsRequest[]
) => ({
  ISS_PROD_SELL: [
    {
      PRODUCT_ID: formData.registrationTariffStep.product?.id, // Priority: 2
      SPARTE: formData.registrationTariffStep.product?.divisionType, // Priority: 2
      CONSMPT_SPART: [
        {
          ISS_CONSMPT_SPART: compact([
            // Priority: 2
            formData.registrationTariffStep.product?.highTariff && {
              ZWART: MeterType.HIGH_TARIFF,
              CONSMPT: formData.registrationTariffStep.product.highTariff,
              SPARTE: formData.registrationTariffStep.product?.divisionType,
              ANLART: formData.registrationTariffStep.product?.heatingType,
            },
            formData.registrationTariffStep.product?.lowTariff && {
              ZWART: MeterType.LOW_TARIFF,
              CONSMPT: formData.registrationTariffStep.product.lowTariff,
              SPARTE: formData.registrationTariffStep.product?.divisionType,
              ANLART: formData.registrationTariffStep.product?.heatingType,
            },
            !hasDoubleTariffMeter &&
              formData.registrationTariffStep.product?.consumption && {
                CONSMPT: formData.registrationTariffStep.product.consumption,
                SPARTE: formData.registrationTariffStep.product?.divisionType,
                ANLART: formData.registrationTariffStep.product?.heatingType,
              },
          ]),
        },
      ],
      OLDSERVPROV: isChangeOfSupplier
        ? formData.registrationDeliveryStep.previousSupplierData?.SERVICEID ||
          formData.registrationDeliveryStep.previousSupplier
        : "", // Priority: 2
      METER_READINGS: getMeterReadings(formData),
      PROD_OPTIONS: productOptions ?? [],
      START_OF_DELIVERY: isChangeOfSupplier
        ? formData.registrationDeliveryStep.deliveryStart
        : "", // Priority: 2
      OLD_CUSTOMER_NUMBER:
        formData.registrationDeliveryStep.previousCustomerNumber, // Priority: 1
      CANCEL_DATE: formData.registrationDeliveryStep.terminationDate, // Priority: 1,
      BBP_AMOUNT: get(
        formData.registrationTariffStep,
        "product.monthlyDiscount[0].NETTO",
        ""
      ), // Priority: 2
      SPONSORSHIP_ID: getSponsorshipId(formData), // Priority: 1
      MALO_ID: formData.registrationDeliveryStep.maLoID, // Priority: 1

      READINGRESULT: formData.registrationDeliveryStep.meterReading,
      CONSUMPTION: formData.registrationTariffStep.product?.consumption,
      SERIALNO: formData.registrationDeliveryStep.meterNumber,
    },
  ],
});

// *ok
export const getMoveIn = (formData: RegistrationRouteState["formData"]) => ({
  PREV_TENANT_LNAME: formData.registrationDeliveryStep.previousTenant,
  METER_READINGS: getMeterReadings(formData),
});

// *ok
export const getBankData = (formData: RegistrationRouteState["formData"]) => ({
  SWIFT: formData.registrationPaymentStep.swift, // Priority: 1
  IBAN: formData.registrationPaymentStep.iban.replace(/\s/g, "").toUpperCase(), // Priority: 2
  KOINH: formData.registrationPaymentStep.accountHolder, // Priority: 2
  XEZER: formData.registrationPaymentStep.doc ? "X" : "", // Priority: 2
});

// *ok
export const getOptin = (formData: RegistrationRouteState["formData"]) =>
  formData.registrationCompleteStep.informationAndOffersAccepted ? "X" : ""; // Priority: 2

// *ok
export const getAgb = (formData: RegistrationRouteState["formData"]) =>
  formData.registrationCompleteStep.declarationsOfConsentAccepted ? "X" : ""; // Priority: 2

// *ok
export const getMoveindate = (
  formData: RegistrationRouteState["formData"],
  isChangeOfSupplier: boolean
) =>
  !isChangeOfSupplier ? formData.registrationDeliveryStep.deliveryStart : "";

// *ok
export const getProcess = (formData: RegistrationRouteState["formData"]) =>
  formData.registrationDeliveryStep.deliveryMethod; // Priority: 2

// *ok
export const getPostOption = (formData: RegistrationRouteState["formData"]) =>
  formData.registrationDeliveryStep.billingAddress.mailboxNumber ? "X" : ""; // Priority: 1

// *ok
// ?Info: This flag should always be filled at this time (EVOW-472)
export const getAutoRegistration = () =>
  // formData: RegistrationRouteState["formData"]
  {
    // return formData.registrationTariffStep.product?.onlinereg ? "X" : ""; // Priority: 2
    return "X";
  };

export const getAffiliateId = (formData: RegistrationRouteState["formData"]) =>
  formData.registrationFundingProjectsStep.affiliateId
    ? formData.registrationFundingProjectsStep.fundingProject.affiliateId
    : "";

export const getSponsorshipId = (
  formData: RegistrationRouteState["formData"]
) =>
  get(
    formData.registrationFundingProjectsStep.fundingProject.serviceNo,
    store.getters.registrationRouteTariffType,
    ""
  );
