export enum SalesApplicationStepState {
  active = "active",
  visited = "visited",
  unvisited = "unvisited",
}

export interface SalesApplicationStepData {
  id: string;
  headline: string;
  businessCustomerHeadline?: string;
  state: SalesApplicationStepState;
  checked?: boolean;
  errors?: string[];
  hidden?: boolean;
  locked?: boolean;
}

export interface SalesApplicationStepProps extends SalesApplicationStepData {
  customToggleEdit?: (index: number) => void;
}
