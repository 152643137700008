import { CustomerType } from "@/api/sap";

export const tariffCalculatorConstants = {
  keys: {
    DESCRIPTION: "BESCHREIBUNG",
  },
  filter: {
    NT: "NT",
    HT: "HT",
    SWITCHING_PRICE: "Schalt- & Messpreis",
  },
  properties: {
    PRICESCALE: "PRICESCALE",
  },
  limits: {
    Electricity: {
      [CustomerType.PrivateCustomer]: 80000,
      [CustomerType.BusinessCustomer]: 80000,
    },
    Gas: {
      [CustomerType.PrivateCustomer]: 150000,
      [CustomerType.BusinessCustomer]: 150000,
    },
  },
} as const;
