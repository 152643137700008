import axios, { AxiosInstance } from "axios";

import { HOST } from "@/constants";

export const SAP_CONFIG = {
  baseUrl: `${HOST}/api/sap`,
};

export const sapAPI: AxiosInstance = axios.create({
  baseURL: SAP_CONFIG.baseUrl,
  method: "post",
  params: {
    code: "sapc",
  },
});

export default {
  SAP_CONFIG,
  sapAPI,
};
