


















import { defineComponent, ref, PropType, watch } from "@vue/composition-api";
import { isEmpty, isString } from "lodash-es";

import { getStreets, StreetItem } from "@/api/sap";
import { useAsyncComponent } from "@/hooks/useAsyncComponent";

export default defineComponent({
  name: "SapStreetSelectField",
  props: {
    loadingIds: {
      type: Array as PropType<string[]>,
    },
    postalCode: {
      type: String,
    },
    city: {
      type: String,
    },
    cityCode: {
      type: String,
    },
    regionId: {
      type: String,
    },
  },
  setup(props, ctx) {
    const { withLoading } = useAsyncComponent({
      root: ctx.root,
      ids: props.loadingIds,
    });

    const options = ref<StreetItem[]>([]);
    const searchValue = ref();

    function createOption(streetName: string) {
      const newOption = {
        LINE: streetName,
        CODE: "",
      } as StreetItem;

      ctx.emit("option:created", newOption);

      return newOption;
    }

    function onInput(value: StreetItem) {
      searchValue.value = value;
      ctx.emit("input", value);
    }

    function onBlur() {
      if (isString(searchValue.value) && !isEmpty(searchValue.value)) {
        ctx.emit("input", createOption(searchValue.value));
        // createOption(searchValue.value);
      }
    }

    watch(
      () => props.postalCode,
      (postalCode, prevPostalCode) => {
        if (!postalCode || postalCode === prevPostalCode) {
          return;
        }

        withLoading(
          getStreets({
            postalCode,
            city: props.city,
            cityCode: props.cityCode,
            regionId: props.regionId,
          })
        ).then((data) => (options.value = data));
      },
      {
        immediate: true,
      }
    );

    return {
      createOption,
      options,
      onInput,
      onBlur,
      searchValue,
    };
  },
});
