

























































































































































































































































































































































































































































import {
  defineComponent,
  computed,
  PropType,
  ref,
  watchEffect,
  watch,
} from "@vue/composition-api";
import { mask } from "vue-the-mask";
import { find, omit } from "lodash-es";
import { formatISO, isBefore, startOfDay, subDays } from "date-fns";

import { getConfiguration, CustomerType } from "@/api/sap";
import store from "@/store";
import { useAsyncComponent } from "@/hooks/useAsyncComponent";

import { BaseRadioButtonProps } from "@/shared/components/base/BaseRadioButton";
import InfoPopup from "@/shared/components/InfoPopup";

import { initialRegistrationRouteState } from "@/features/registration-route/state";
import SapPostalCodeField from "@/features/shared/SapPostalCodeField";
import SapStreetSelectField from "@/features/shared/SapStreetSelectField";

import { RegistrationStepState } from "../../../RegistrationStep";
import RegistrationProviders from "../../../RegistrationProviders";
import RegistrationStepContent from "../../../RegistrationStepContent";
import RegistrationStepForm from "../../../RegistrationStepForm";
import RegistrationDateOfDelivery from "../../../RegistrationDateOfDelivery";
import RegistrationSalutations from "../../../RegistrationSalutations";
import RegistrationDeliveryStepOverview from "./RegistrationDeliveryStepOverview.vue";

import { DeliveryType } from ".";

export default defineComponent({
  name: "RegistrationDeliveryStep",
  components: {
    InfoPopup,
    SapPostalCodeField,
    SapStreetSelectField,
    RegistrationProviders,
    RegistrationStepContent,
    RegistrationStepForm,
    RegistrationDateOfDelivery,
    RegistrationDeliveryStepOverview,
    RegistrationSalutations,
  },
  props: {
    state: {
      type: String as PropType<RegistrationStepState>,
      default: RegistrationStepState.unvisited,
    },
  },
  directives: {
    mask,
  },
  setup(props, ctx) {
    const { withLoading } = useAsyncComponent({
      root: ctx.root,
      ids: ["RegistrationDeliveryStep"],
    });

    const stateCustomerType = computed(
      () => store.getters.registrationRouteCustomerType
    );

    withLoading(
      getConfiguration().then((configurationData) => {
        configurationData && store.commit.setConfigData(configurationData);
      })
    );

    const showMeterReadingPopup = () => {
      ctx.root.$modal.show("MeterReadingPopup");
    };

    const regionId = computed(
      () =>
        store.state.registrationRoute.formData.registrationTariffStep.product
          ?.regionId || ""
    );

    const formData = ref({
      ...store.getters.registrationDeliveryFormData,
    });

    const tariffTypeText = computed(
      () => store.getters.registrationRouteTariffTypeText
    );

    watch(
      () => props.state,
      (currentState, prevState) => {
        if (
          currentState !== prevState &&
          prevState === RegistrationStepState.visited
        ) {
          formData.value = {
            ...store.getters.registrationDeliveryFormData,
          };
        }
      }
    );

    watch(
      () => formData.value.deliveryMethod,
      (currentDeliveryMethod, prevDeliveryMethod) => {
        if (currentDeliveryMethod !== prevDeliveryMethod) {
          formData.value = {
            deliveryMethod: formData.value.deliveryMethod,
            deliveryMethodData: formData.value.deliveryMethodData,
            ...omit(store.getters.registrationDeliveryFormData, [
              "deliveryMethod",
              "deliveryMethodData",
            ]),
          };
        }
      }
    );

    const deliveryMethods = ref<BaseRadioButtonProps[]>([
      {
        name: "deliveryMethod",
        value: DeliveryType.CHANGE_OF_SUPPLIER,
        title: "Versorgerwechsel",
        description:
          "Ich bin aktuell Kunde eines anderen Versorgers und möchte zu evo wechseln.",
      },
      {
        name: "deliveryMethod",
        value: DeliveryType.MOVE_IN,
        title: "Neueinzug",
        description:
          stateCustomerType.value == "1"
            ? "Ich ziehe um und möchte in meinem neuen Zuhause durch die evo beliefert werden."
            : "Wir möchten an unserem neuen Standort durch die evo beliefert werden.",
      },
    ]);

    const divisionType = computed(
      () => store.getters.registrationTariffFormData.product?.divisionType
    );

    const deliveryMethodData = computed(() =>
      find(deliveryMethods.value, ["value", formData.value.deliveryMethod])
    );

    const isChangeOfSupplier = computed(
      () => formData.value.deliveryMethod === DeliveryType.CHANGE_OF_SUPPLIER
    );

    const showHTNTMeterReading = computed(
      () => store.getters.showHTNTMeterReading
    );

    const cancellationTypes = ref<BaseRadioButtonProps[]>([
      {
        name: "cancellationType",
        value: "authorization",
        title:
          "Hiermit bevollmächtige ich die Energieversorgung Oberhausen Aktiengesellschaft (evo), meinen bestehenden Energieliefervertrag zu kündigen und die für die Energielieferung notwendigen Verträge mit dem <u>zuständigen</u> Netzbetreiber abzuschließen.",
      },
      {
        name: "cancellationType",
        value: "canceled",
        title: `Ich habe meinen bestehenden ${tariffTypeText.value}vertrag bereits gekündigt.`,
      },
    ]);

    const isContractCancelled = computed(
      () => formData.value.cancellationType === cancellationTypes.value[1].value
    );

    const addressTypes = ref<BaseRadioButtonProps[]>([
      {
        name: "Postanschrift",
        value: "Postanschrift",
        title: "Postanschrift",
      },
      {
        name: "Postfach",
        value: "Postfach",
        title: "Postfach",
      },
    ]);

    const isMailboxNumberType = computed(
      () =>
        formData.value.billingAddress.addressType ===
        addressTypes.value[1].value
    );

    const isDeliveryStartInPast = computed(
      () =>
        formData.value.deliveryStart &&
        isBefore(new Date(formData.value.deliveryStart), new Date())
    );

    const today = ref(startOfDay(new Date()));
    const minTerminationDate = computed(() => {
      const deliveryStartDate = formData.value.deliveryStart;
      const currentMinDate = deliveryStartDate || today.value;
      const minTerminationDate = subDays(
        startOfDay(new Date(currentMinDate)),
        1
      );
      const formattedMinTerminationDate = formatISO(minTerminationDate, {
        representation: "date",
      });

      return formattedMinTerminationDate;
    });

    watchEffect(() => {
      console.log(
        "effect meternumber",
        formData.value.meterNumber,
        formData.value.meterNumber.toUpperCase()
      );
      formData.value.meterNumber = formData.value.meterNumber.toUpperCase();
    });

    const terminationDateInfo = ref<string[]>([]);

    const deliveryStartChangeCount = ref(0);
    watch(
      () => formData.value.deliveryStart,
      (currentValue, prevValue) => {
        if (!prevValue || currentValue === prevValue) {
          return;
        }

        if (deliveryStartChangeCount.value) {
          terminationDateInfo.value.unshift(
            "Achtung: Das Kündigungsdatum wurde angepasst, da Sie ein anderes Lieferdatum gewählt haben."
          );
        }

        deliveryStartChangeCount.value++;
      }
    );

    watch(
      () => formData.value.terminationDate,
      (currentValue, prevValue) => {
        if (
          currentValue === prevValue ||
          currentValue === minTerminationDate.value
        ) {
          return;
        }

        terminationDateInfo.value = [];
      }
    );

    function onSubmit() {
      const data = {
        ...omit(formData.value, ["billingAddress"]),
        deliveryMethodData: deliveryMethodData.value,
        previousSupplier: formData.value.previousSupplierData?.SP_NAME,
        cancellationTypeData: find(cancellationTypes.value, [
          "value",
          formData.value.cancellationType,
        ]),
        terminationDate:
          formData.value.cancellationType === cancellationTypes.value[0].value
            ? ""
            : formData.value.terminationDate,
        billingAddress: {
          ...initialRegistrationRouteState.formData.registrationDeliveryStep
            .billingAddress,
          ...(formData.value.differentBillingAddress &&
            omit(
              formData.value.billingAddress,
              isMailboxNumberType.value
                ? ["street", "houseNumber"]
                : ["mailboxNumber"]
            )),
        },
      };

      store.commit.setDeliveryData(data);
    }

    return {
      divisionType,
      deliveryMethodData,
      tariffTypeText,
      formData,
      isChangeOfSupplier,
      isContractCancelled,
      isMailboxNumberType,
      deliveryMethods,
      isDeliveryStartInPast,
      showHTNTMeterReading,
      cancellationTypes,
      addressTypes,
      today,
      minTerminationDate,
      onSubmit,
      CustomerType,
      regionId,
      terminationDateInfo,
      showMeterReadingPopup,
    };
  },
});
