import { isEmpty } from "lodash-es";
import { stringify, parse, ParsedQs } from "qs";
interface URLWithParamsProps {
  resultsURL?: string;
  // eslint-disable-next-line
  values?: Record<string, any>;
}

export function useWindowUtils() {
  function getParamsFromURL(url: string) {
    const index = url.lastIndexOf("?");

    if (index > -1) {
      return url.slice(url.lastIndexOf("?") + 1);
    }

    return "";
  }

  function getURLWithParams(props: URLWithParamsProps) {
    const { resultsURL, values } = props;

    const origin = resultsURL ?? window.location.origin;
    const stringifiedValues = stringify(values) ?? {};

    return isEmpty(stringifiedValues)
      ? origin
      : `${origin}/?${stringifiedValues}`;
  }

  function redirect(redirectProps: URLWithParamsProps) {
    const resultURLWithParams = getURLWithParams(redirectProps);

    window.location.href = resultURLWithParams;
  }

  function pushToHistory(data: any, title: string, url?: string | null) {
    window.history.pushState(data, title, url);
  }

  function getParams<T>(url: string = window.location.href): T & ParsedQs {
    const params = getParamsFromURL(url);
    const parsedParams = parse(params) ?? {};

    return parsedParams as T & ParsedQs;
  }

  return {
    pushToHistory,
    redirect,
    getParamsFromURL,
    getURLWithParams,
    getParams,
  };
}
