import store from "@/store";
import {
  queueSupplyStartAction,
  getMessagesArray,
  userIsAlreadyCustomer,
  getErrorMessages,
  mergeMessages,
} from "@/api/sap";
import { IS_PROTOTYPE, DEBUG } from "@/constants";
import { fundingProjectsAPI } from "@/features/funding-projects/config";
import { GetProductType } from "@/features/tariff-calculator/state/getters";
import { clean } from "@/helper/object.helper";
import { useWindowUtils } from "@/hooks/useWindowUtils.hook";
import { defineActions } from "direct-vuex";
import { findIndex, get, isEmpty, omit, includes } from "lodash-es";
import {
  registrationRouteModuleActionContext,
  RegistrationRouteState,
  RegistrationStepId,
} from ".";
import { RegistrationStepState } from "../components/RegistrationStep";
import { DeliveryType } from "../components/RegistrationSteps/steps/RegistrationDeliveryStep";
import {
  getAffiliateId,
  getAgb,
  getAutoRegistration,
  getBankData,
  getBillAltAdr,
  getContactAltAdr,
  getMoveIn,
  getMoveindate,
  getOptin,
  getOrgData,
  getPersonData,
  getPostOption,
  getProcess,
  getProductSell,
  getVstelleAdr,
} from "./helper/formData.helper";
import { upsellingHelper } from "./helper/upselling.helper";
import {
  generateProductOptionsForRequest,
  TransformedProductData,
} from "@/api/sap/helper/products.helper";
import { ProdOptionsRequest } from "@/api/sap/@types/read-single-product-request.interface";

export enum ActionType {
  tariffCalculatorResetOptions = "tariffCalculatorResetOptions",
  setRegistrationRouteConfig = "setRegistrationRouteConfig",
  setFundingProjectConfig = "setFundingProjectConfig",
  fetchFundingProjects = "fetchFundingProjects",
  fetchFundingProject = "fetchFundingProject",
  startRegistrationRoute = "startRegistrationRoute",
  registrationRouteToggleEdit = "registrationRouteToggleEdit",
  registrationRouteNextStep = "registrationRouteNextStep",
  registrationRouteResetProduct = "registrationRouteResetProduct",
  registrationRouteSupplyStart = "registrationRouteSupplyStart",
  removeFundingProjectsStep = "removeFundingProjectsStep",
}

export default defineActions({
  async [ActionType.setRegistrationRouteConfig](
    context,
    config: Partial<RegistrationRouteState["config"]>
  ) {
    const { getUpsellingDataFromStorage } = upsellingHelper();
    const { personalDetails, deliveryDetails } = getUpsellingDataFromStorage();

    const { commit } = registrationRouteModuleActionContext(context);
    const { setConfigData, setPersonalDetails, setDeliveryData } = commit;

    await setConfigData(config);

    setPersonalDetails(personalDetails);
    setDeliveryData(deliveryDetails);
  },
  async [ActionType.removeFundingProjectsStep](context) {
    const { state, commit } = registrationRouteModuleActionContext(context);
    const { steps } = state;
    const { setSteps } = commit;

    const fundingProjectStepConfigIndex = findIndex(steps, {
      id: RegistrationStepId.REGISTRATION_FUNDING_PROJECTS_STEP,
    });

    const newSteps = [...steps];

    if (fundingProjectStepConfigIndex != -1) {
      newSteps.splice(fundingProjectStepConfigIndex, 1);
    }

    setSteps(newSteps);
  },
  async [ActionType.setFundingProjectConfig](context) {
    const { state, commit, dispatch } = registrationRouteModuleActionContext(
      context
    );

    const { config } = state;
    const { setFundingProjectsStep } = commit;
    const { removeFundingProjectsStep } = dispatch;

    const { getParams } = useWindowUtils();
    const showFundingProjects = get(config, "showFundingProjects", false);
    const affiliateIdFromUrl = get(getParams(), "affiliateId", "");

    const shouldChangeFundingProjectsConfig =
      !showFundingProjects || affiliateIdFromUrl;

    if (shouldChangeFundingProjectsConfig) {
      if (!showFundingProjects) {
        removeFundingProjectsStep();
        return;
      }

      if (affiliateIdFromUrl) {
        setFundingProjectsStep({
          affiliateId: affiliateIdFromUrl,
        });
      }
    }
  },
  async [ActionType.fetchFundingProject](_, affiliateId: string) {
    let result = undefined;

    if (!affiliateId) {
      return result;
    }

    try {
      const response = await fundingProjectsAPI.get("", {
        params: {
          code: "single",
          "tx_bufundingproject[id]": affiliateId,
        },
      });

      const fundingProjectsData = response.data ?? {};

      if (!isEmpty(fundingProjectsData.data)) {
        result = fundingProjectsData.data;
      }

      return result;
    } catch (err) {
      console.error("Could not load funding projects", err);
      return result;
    }
  },
  async [ActionType.fetchFundingProjects](context) {
    const { state, commit, dispatch } = registrationRouteModuleActionContext(
      context
    );
    const { steps } = state;
    const { setActiveStep, setFundingProjectsStep } = commit;
    const { removeFundingProjectsStep } = dispatch;

    const fundingProjectStepConfigIndex = findIndex(steps, {
      id: RegistrationStepId.REGISTRATION_FUNDING_PROJECTS_STEP,
    });
    let result = undefined;

    try {
      setFundingProjectsStep({ affiliateId: "" });

      const response = await fundingProjectsAPI.get("", {
        params: {
          code: "list",
        },
      });

      const fundingProjectsData = response.data ?? {};

      if (!isEmpty(fundingProjectsData.data)) {
        result = fundingProjectsData.data;
      }

      return result;
    } catch (err) {
      DEBUG && console.error("Could not load funding projects", err);

      removeFundingProjectsStep();
      setActiveStep(fundingProjectStepConfigIndex);

      return result;
    }
  },
  async [ActionType.startRegistrationRoute](
    context,
    payload: { product: GetProductType }
  ) {
    const { product } = payload;

    const { state, commit, dispatch } = registrationRouteModuleActionContext(
      context
    );

    const { formData } = state;

    const {
      setConfigData,
      setActiveStep,
      setTariffStep,
      setShowRegistrationRoute,
      setDeliveryData,
    } = commit;

    const { registrationRouteNextStep, setFundingProjectConfig } = dispatch;

    await setConfigData({
      showFundingProjects: includes(product.productGroups, "RKRAFT"),
    });
    await setFundingProjectConfig();

    setActiveStep(0);
    setTariffStep({ product });
    await registrationRouteNextStep();
    setShowRegistrationRoute(true);
    setDeliveryData({
      deliveryAddress: {
        ...formData.registrationDeliveryStep.deliveryAddress,
        addressResponse: get(product, "address", {}),
      },
    });
  },
  [ActionType.registrationRouteToggleEdit](context, index: number) {
    const { state, commit } = registrationRouteModuleActionContext(context);
    const { steps } = state;
    const { setStepState } = commit;

    steps.forEach((step, i) => {
      if (i === index) {
        if (step.state === RegistrationStepState.visited) {
          setStepState({ index: i, state: RegistrationStepState.active });
        }
      } else {
        if (step.state === RegistrationStepState.active) {
          setStepState({ index: i, state: RegistrationStepState.visited });
        }
      }
    });
  },
  [ActionType.registrationRouteNextStep](context) {
    const { state, commit, getters } = registrationRouteModuleActionContext(
      context
    );
    const { steps } = state;
    const { setActiveStep } = commit;
    const {
      isLastRegistrationStepActive,
      currentRegistrationStep: currentStepIndex,
    } = getters;

    if (!isLastRegistrationStepActive) {
      let nextIndex = currentStepIndex;
      for (let index = currentStepIndex + 1; index < steps.length; index++) {
        const step = steps[index];
        const isNextAvailableStep = !step.hidden && !step.locked;

        if (isNextAvailableStep) {
          nextIndex = index;
          break;
        }
      }

      setActiveStep(nextIndex);
    }
  },
  [ActionType.tariffCalculatorResetOptions]() {
    const { resetSelectedOptions: resetOptions } = upsellingHelper();

    resetOptions();
  },
  [ActionType.registrationRouteResetProduct](context) {
    const { commit } = registrationRouteModuleActionContext(context);
    const {
      setShowRegistrationRoute,
      setTariffStep,
      resetRegistrationStepsState,
    } = commit;

    setShowRegistrationRoute(false);
    setTariffStep({ product: null });
    resetRegistrationStepsState();
  },
  async [ActionType.registrationRouteSupplyStart](context) {
    const { state, getters, commit } = registrationRouteModuleActionContext(
      context
    );
    const { formData } = state;
    let alreadyCustomer = false;
    const {
      setIsLoadingGlobally,
      resetRegistrationRouteErrors,
      resetRegistrationRouteMessage,
    } = commit;
    const { registrationRouteIsBusinessCustomer: isBusinessCustomer } = getters;

    setIsLoadingGlobally(true);
    resetRegistrationRouteErrors();
    resetRegistrationRouteMessage();

    const isChangeOfSupplier =
      formData.registrationDeliveryStep.deliveryMethod ===
      DeliveryType.CHANGE_OF_SUPPLIER;

    const hasDoubleTariffMeter =
      !!formData.registrationTariffStep.product?.highTariff &&
      !!formData.registrationTariffStep.product?.lowTariff &&
      formData.registrationTariffStep.product?.heatingType === "NSP";

    const isSepa = formData.registrationPaymentStep.paymentMethod === "sepa";

    const VSTELLE_ADR = getVstelleAdr(formData);
    const PERSON_DATA = getPersonData(formData);
    const ORG_DATA = getOrgData(formData);
    const BILL_ALT_ADR = getBillAltAdr(formData, isBusinessCustomer);
    const CONTACT_ALT_ADR = getContactAltAdr(formData);
    const productOptions: ProdOptionsRequest[] = generateProductOptionsForRequest(
      formData.registrationTariffStep.product as TransformedProductData
    );
    const PRODUCT_SELL = getProductSell(
      formData,
      hasDoubleTariffMeter,
      isChangeOfSupplier,
      productOptions
    );
    const MOVE_IN = getMoveIn(formData);
    const BANK_DATA = getBankData(formData);
    const OPTIN = getOptin(formData);
    const AGB = getAgb(formData);
    const MOVEINDATE = getMoveindate(formData, isChangeOfSupplier);
    const PROCESS = getProcess(formData);
    const POST_OPTION = getPostOption(formData);
    const AFFILIATE_ID = getAffiliateId(formData);
    const DIST_CHANNEL = "07";
    // *ok: Leave empty -> today's date is taken as order date
    const ORDER_DATE = "";
    const AUTO_REGISTRATION = getAutoRegistration();
    const ACCOUNT_CUSTOMER =
      formData.registrationPersonalDetailsStep.accountCustomer;
    const PROMOTION_CODE =
      context.rootState.tariffCalculator.config?.promotionCode;

    // *ok
    const data = {
      VSTELLE_ADR: [VSTELLE_ADR],
      PERSON_DATA: !isBusinessCustomer ? [PERSON_DATA] : [],
      ORG_DATA: isBusinessCustomer ? [ORG_DATA] : [],
      BILL_ALT_ADR: formData.registrationDeliveryStep.differentBillingAddress
        ? [BILL_ALT_ADR]
        : [],
      CONTACT_ALT_ADR: isBusinessCustomer ? [CONTACT_ALT_ADR] : [],
      PRODUCT_SELL: [PRODUCT_SELL],
      MOVE_IN: [MOVE_IN],
      BANK_DATA: isSepa ? [BANK_DATA] : [],
      POST_OPTION,
      AFFILIATE_ID,
    };

    const cleanedData = {
      ...clean()(data),
      OPTIN,
      AGB,
      MOVEINDATE,
      PROCESS,
      DIST_CHANNEL,
      ORDER_DATE,
      AUTO_REGISTRATION,
      ACCOUNT_CUSTOMER,
      PROMOTION_CODE,
    };

    const {
      setState: setUpselling,
      reset: resetUpselling,
      redirect,
    } = upsellingHelper();

    let hasErrorCode = false;
    try {
      await resetUpselling();
      console.log("QUEUE REGISTRATION SUPPLY START WITH", { ...cleanedData });
      const actionData = await queueSupplyStartAction(cleanedData);
      const messages = getMessagesArray(actionData);
      IS_PROTOTYPE && console.log("messages", messages);

      hasErrorCode = !!getErrorMessages(messages).length;
      alreadyCustomer = userIsAlreadyCustomer(messages);
      let logMessage = mergeMessages(messages) || "";

      if (hasErrorCode) {
        if (!logMessage) {
          logMessage =
            "Die Anfrage konnte nicht durchgeführt werden. Bitte versuchen Sie es erneut oder kontaktieren Sie den Kundenservice.";
        }

        // -> Uncomment for debugging
        // addRegistrationRouteError(logMessage);
      } else {
        // -> Uncomment for debugging
        // addRegistrationRouteMessage(logMessage);
      }
    } catch (error) {
      // -> Uncomment for debugging
      // addRegistrationRouteError(error);

      hasErrorCode = true;

      if (DEBUG) {
        console.error(error);
      }
    } finally {
      // setIsLoadingGlobally(false);

      await setUpselling({
        personalDetails: formData.registrationPersonalDetailsStep,
        deliveryDetails: omit(formData.registrationDeliveryStep, [
          "deliveryStart",
          "meterNumber",
          "maLoID",
          "meterReading",
          "dateMeterReading",
        ]),
      });

      if (
        IS_PROTOTYPE ||
        store.state.registrationRoute.config.preventRedirect
      ) {
        console.log("hasErrorCode", hasErrorCode);
        console.log("will redirect on dev or production");
      } else {
        await redirect({ success: !hasErrorCode, alreadyCustomer });
      }
    }
  },
});
