import { toNumber } from "lodash-es";

const locale = "de-DE";

export const formatNumber = (
  value: string | number,
  options?: Intl.NumberFormatOptions
) =>
  new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }).format(toNumber(value));

export const formatSAPPrices = (
  value: string | number,
  currencyString: string,
  options?: Intl.NumberFormatOptions
) => {
  // Sap Prices should be rendered with 3 decimals if EUR, else 2 decimals
  const fractionDigits = currencyString.includes("EUR") ? 3 : 2;

  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    ...options,
  }).format(toNumber(value));
};

export const formatCurrency = (
  value: string | number,
  options?: Intl.NumberFormatOptions
) =>
  formatNumber(value, {
    style: "currency",
    currency: "EUR",
    ...options,
  });

export const limitNumberWithinRange = (
  num: number,
  min: number,
  max: number
) => {
  return Math.min(Math.max(num, min), max);
};
