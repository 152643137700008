import { ref, SetupContext } from "@vue/composition-api";

interface UseAsyncComponentProps {
  root?: SetupContext["root"];
  ids?: string[];
  emit?: boolean;
}

export function useAsyncComponent(props: UseAsyncComponentProps = {}) {
  const EMIT_LOADING_START = "loading:start";
  const EMIT_LOADING_END = "loading:end";

  const { root, emit = true, ids } = props;
  const isLoading = ref(false);
  const canEmit = ref(ref && emit && ids && ids.length > 0);

  function startLoading() {
    isLoading.value = true;

    if (canEmit.value) {
      ids?.forEach((id) => root?.$emit(EMIT_LOADING_START, { id }));
    }
  }

  function endLoading() {
    isLoading.value = false;

    if (canEmit.value) {
      ids?.forEach((id) => root?.$emit(EMIT_LOADING_END, { id }));
    }
  }

  async function withLoading<T>(request: Promise<T>) {
    try {
      startLoading();
      return await request;
    } finally {
      endLoading();
    }
  }

  return {
    withLoading,
    isLoading,
  };
}
