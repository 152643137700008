import store from "@/store";
import {
  getMessages,
  hasError,
  sendSalesApplication,
  fetchCustomerDataWithToken,
  readSingleProductAction,
  getProduct,
  TariffDataResponseType,
  transformProductData,
} from "@/api/sap";
import { IS_PROTOTYPE, DEBUG } from "@/constants";
import { clean } from "@/helper/object.helper";
// import { useWindowUtils } from "@/hooks/useWindowUtils.hook";
import { defineActions } from "direct-vuex";
import { omit } from "lodash-es";
import {
  salesApplicationRouteModuleActionContext,
  SalesApplicationRouteState,
} from ".";
import ActionTypes from "@/api/sap/action-types";
import { SalesApplicationStepState } from "../components/SalesApplicationStep";
import { DeliveryType } from "../components/SalesApplicationSteps/steps/SalesApplicationDeliveryStep";
import {
  // getAffiliateId,
  getType,
  getContractId,
  getContract,
  getDeliveryStart,
  getConsmptSpart,
  getProductId,
  getAgb,
  getAutoSalesApplication,
  getBankData,
  getBillAltAdr,
  getContactAltAdr,
  getMoveIn,
  getMoveindate,
  getOptin,
  getOrgData,
  getPersonData,
  getPostOption,
  getProcess,
  getProductSell,
  getVstelleAdr,
} from "./helper/formData.helper";
import {
  generateSingleProductPostData,
  buildFormData,
} from "./helper/salesApiData.helper";
import { upsellingHelper } from "./helper/upselling.helper";
import { dummyState } from "@/features/sales-application-route/state/data/dummy-state";
import { ProductAbilityType } from "./helper/productStepAbilityFactory.helper";
import { ProductStepAbilityFactory } from "../state/helper/productStepAbilityFactory.helper";
import {
  generateProductOptionsForRequest,
  TransformedProductData,
} from "@/api/sap/helper/products.helper";
import { ProdOptionsRequest } from "@/api/sap/@types/read-single-product-request.interface";

export enum ActionType {
  setSalesApplicationRouteConfig = "setSalesApplicationRouteConfig",
  fetchInitialCustomerTariffData = "fetchInitialCustomerTariffData",
  startSalesApplicationRoute = "startSalesApplicationRoute",
  salesApplicationRouteToggleEdit = "salesApplicationRouteToggleEdit",
  salesApplicationRouteNextStep = "salesApplicationRouteNextStep",
  salesApplicationRouteResetProduct = "salesApplicationRouteResetProduct",
  salesApplicationRouteSupplyStart = "salesApplicationRouteSupplyStart",
  salesApplicationAbilities = "salesApplicationAbilities",
}

export default defineActions({
  async [ActionType.setSalesApplicationRouteConfig](
    context,
    config: Partial<SalesApplicationRouteState["config"]>
  ) {
    const { getUpsellingDataFromStorage } = upsellingHelper();
    const { personalDetails, deliveryDetails } = getUpsellingDataFromStorage();

    const { commit } = salesApplicationRouteModuleActionContext(context);
    const { setConfigData, setPersonalDetails, setDeliveryData } = commit;

    await setConfigData(config);

    setPersonalDetails(personalDetails);
    setDeliveryData(deliveryDetails);
  },
  [ActionType.salesApplicationRouteToggleEdit](context, index: number) {
    const { state, commit } = salesApplicationRouteModuleActionContext(context);
    const { steps } = state;
    const { setStepState } = commit;

    steps.forEach((step, i) => {
      if (i === index) {
        if (step.state === SalesApplicationStepState.visited) {
          setStepState({ index: i, state: SalesApplicationStepState.active });
        }
      } else {
        if (step.state === SalesApplicationStepState.active) {
          setStepState({ index: i, state: SalesApplicationStepState.visited });
        }
      }
    });
  },
  [ActionType.salesApplicationRouteNextStep](context) {
    const { state, commit, getters } = salesApplicationRouteModuleActionContext(
      context
    );
    const { steps } = state;
    const { setActiveStep } = commit;
    const {
      isLastSalesApplicationStepActive,
      currentSalesApplicationStep: currentStepIndex,
    } = getters;

    if (!isLastSalesApplicationStepActive) {
      let nextIndex = currentStepIndex;
      for (let index = currentStepIndex + 1; index < steps.length; index++) {
        const step = steps[index];
        const isNextAvailableStep = !step.hidden && !step.locked;

        if (isNextAvailableStep) {
          nextIndex = index;
          break;
        }
      }

      setActiveStep(nextIndex);
    }
  },
  [ActionType.salesApplicationRouteResetProduct](context) {
    const { commit } = salesApplicationRouteModuleActionContext(context);
    const {
      setShowSalesApplicationRoute,
      setTariffStep,
      resetSalesApplicationStepsState,
    } = commit;

    setShowSalesApplicationRoute(false);
    setTariffStep({ product: null });
    resetSalesApplicationStepsState();
  },
  [ActionType.salesApplicationAbilities](
    context,
    abilities: ProductAbilityType
  ) {
    const { commit } = salesApplicationRouteModuleActionContext(context);
    const { setSalesApplicationAbilities } = commit;

    setSalesApplicationAbilities(abilities);
  },
  async [ActionType.fetchInitialCustomerTariffData](
    context,
    token?: string | null
  ) {
    const { redirect } = upsellingHelper();
    const { commit } = salesApplicationRouteModuleActionContext(context);

    const {
      setFormData,
      setSteps,
      setSalesApplicationAbilities,
      setIsLoadingGlobally,
    } = commit;

    setIsLoadingGlobally(true);

    if (!token) {
      redirect({ success: false, unableToFetch: true });
      return;
    }

    const tariffDataResponse: TariffDataResponseType = await fetchCustomerDataWithToken(
      token
    );

    const invalidKey = tariffDataResponse.invalid.toString();

    if (invalidKey) {
      redirect({ success: false, errorType: invalidKey });
      return;
    }

    const readProductsPostData = generateSingleProductPostData(
      tariffDataResponse,
      token
    );

    const productDataResponse = await readSingleProductAction(
      readProductsPostData
    );
    const product = getProduct(productDataResponse);

    let transformedProduct;

    if (product) {
      transformedProduct = transformProductData(product);
    } else {
      setIsLoadingGlobally(false);
      redirect({ success: false, unableToFetch: true });
      return;
    }

    const formData = buildFormData(
      tariffDataResponse,
      transformedProduct,
      token
    );

    const abilities = ProductStepAbilityFactory(tariffDataResponse.type);

    const response = dummyState.privateCustomer;

    setSalesApplicationAbilities(abilities);

    setFormData(formData);
    setSteps(response.steps as any);
    setIsLoadingGlobally(false);
  },

  async [ActionType.salesApplicationRouteSupplyStart](context) {
    const { state, getters, commit } = salesApplicationRouteModuleActionContext(
      context
    );
    const { formData } = state;
    const {
      // setIsLoadingGlobally,
      resetSalesApplicationRouteErrors,
      resetSalesApplicationRouteMessage,
    } = commit;
    const {
      salesApplicationRouteIsBusinessCustomer: isBusinessCustomer,
    } = getters;

    // setIsLoadingGlobally(true);
    resetSalesApplicationRouteErrors();
    resetSalesApplicationRouteMessage();

    const salesApplicationIsChangeOfSupplier =
      formData.salesApplicationDeliveryStep.deliveryMethod ===
      DeliveryType.CHANGE_OF_SUPPLIER;

    let actionType = ActionTypes.SUPPLY_START;

    const hasDoubleTariffMeter =
      !!formData.salesApplicationTariffStep.product?.highTariff &&
      !!formData.salesApplicationTariffStep.product?.lowTariff &&
      formData.salesApplicationTariffStep.product?.heatingType === "NSP";

    const type = getType(formData);

    const productOptions: ProdOptionsRequest[] = generateProductOptionsForRequest(
      formData.salesApplicationTariffStep.product as TransformedProductData
    );

    const isSepa =
      formData.salesApplicationPaymentStep.paymentMethod === "sepa";

    const VSTELLE_ADR = getVstelleAdr(formData);
    const PERSON_DATA = getPersonData(formData);
    const ORG_DATA = getOrgData(formData);
    const BILL_ALT_ADR = getBillAltAdr(formData, isBusinessCustomer);
    const CONTACT_ALT_ADR = getContactAltAdr(formData);
    const PRODUCT_SELL = getProductSell(
      formData,
      hasDoubleTariffMeter,
      salesApplicationIsChangeOfSupplier,
      productOptions
    );
    const MOVE_IN = getMoveIn(formData);
    const BANK_DATA = getBankData(formData);
    const OPTIN = getOptin(formData);
    const AGB = getAgb(formData);
    const MOVEINDATE = getMoveindate(
      formData
      // salesApplicationIsChangeOfSupplier
    );
    const PROCESS = getProcess(formData);
    const POST_OPTION = getPostOption(formData);
    // const AFFILIATE_ID = getAffiliateId(formData);
    const DIST_CHANNEL = "09";
    // *ok: Leave empty -> today's date is taken as order date
    const ORDER_DATE = "";
    const AUTO_SALES_APPLICATION = getAutoSalesApplication();
    const ACCOUNT_CUSTOMER =
      formData.salesApplicationPersonalDetailsStep.accountCustomer;
    const PROMOTION_CODE =
      context.rootState.tariffCalculator.config?.promotionCode;

    // *ok
    const data = {
      VSTELLE_ADR: [VSTELLE_ADR],
      PERSON_DATA: !isBusinessCustomer ? [PERSON_DATA] : [],
      ORG_DATA: isBusinessCustomer ? [ORG_DATA] : [],
      BILL_ALT_ADR: formData.salesApplicationDeliveryStep
        .differentBillingAddress
        ? [BILL_ALT_ADR]
        : [],
      CONTACT_ALT_ADR: isBusinessCustomer ? [CONTACT_ALT_ADR] : [],
      PRODUCT_SELL: [PRODUCT_SELL],
      MOVE_IN: [MOVE_IN],
      BANK_DATA: isSepa ? [BANK_DATA] : [],
      POST_OPTION,
      // AFFILIATE_ID,
    };

    let cleanedData = {
      ...clean()(data),
      OPTIN,
      AGB,
      MOVEINDATE,
      PROCESS,
      DIST_CHANNEL,
      ORDER_DATE,
      AUTO_SALES_APPLICATION,
      ACCOUNT_CUSTOMER,
      PROMOTION_CODE,
    };

    // Tariff Change needs different POST Body, sent to different api endpoint
    if (type === "T") {
      const VERTRAGSDRID = getContractId(formData);
      const CHG_DATE = getDeliveryStart(formData);
      const CONSMPT_SPART = getConsmptSpart(formData, hasDoubleTariffMeter);
      const PRODUCT = getProductId(formData);
      const CONTRACT = [{ CONTRACT: getContract(formData) }];

      actionType = ActionTypes.PRODUCT_CHANGE;

      const productChangeData = {
        VERTRAGSDRID,
        CHG_DATE,
        PRODUCT,
        CONSMPT_SPART,
        CONTRACT,
        BANK_DATA: isSepa ? [BANK_DATA] : [],
      };

      const productChangeBody = {
        ...clean()(productChangeData),
      };

      cleanedData = {
        ...cleanedData,
        productChangeBody,
      };
    }

    const {
      setState: setUpselling,
      reset: resetUpselling,
      redirect,
    } = upsellingHelper();

    let hasErrorCode = false;
    let errorType = "";

    try {
      await resetUpselling();
      // console.log("QUEUE SALES APPLICATION SUPPLY START WITH", {
      //   ...cleanedData,
      // });
      const actionData = await sendSalesApplication(cleanedData, actionType);
      const messages = getMessages(actionData);

      IS_PROTOTYPE && console.log("messages", messages);

      hasErrorCode = hasError(messages);
      if (messages && messages["MSGTY"] && messages["MSGTY"] === "E") {
        // Set Error Type to B (Bonitätsprüfung failed) if MSGTXT equals their SAP Text
        switch (messages["MSG_TXT"]) {
          case "Leider können wir Ihnen kein Vertragsangebot unterbreiten, da die Bonitätsprüfung fehlgeschlagen ist. (Bei Fragen hierzu wenden Sie sich bitte an unseren Kundenservice)":
            errorType = "B";
            break;
          default:
            errorType = "E";
            break;
        }
      }

      let logMessage = messages?.MSG_TXT || "";

      if (hasErrorCode) {
        if (!logMessage) {
          logMessage =
            "Die Anfrage konnte nicht durchgeführt werden. Bitte versuchen Sie es erneut oder kontaktieren Sie den Kundenservice.";
        }

        // -> Uncomment for debugging
        // addsalesApplicationRouteError(logMessage);
      } else {
        // -> Uncomment for debugging
        // addsalesApplicationRouteMessage(logMessage);
      }
    } catch (error) {
      // -> Uncomment for debugging
      // addsalesApplicationRouteError(error);

      hasErrorCode = true;

      if (DEBUG) {
        console.error(error);
      }
    } finally {
      // setIsLoadingGlobally(false);
      await setUpselling({
        personalDetails: formData.salesApplicationPersonalDetailsStep,
        deliveryDetails: omit(formData.salesApplicationDeliveryStep, [
          "deliveryStart",
          "meterNumber",
          "maLoID",
          "meterReading",
          "dateMeterReading",
        ]),
      });

      if (
        IS_PROTOTYPE ||
        store.state.salesApplicationRoute.config.preventRedirect
      ) {
        console.log("hasErrorCode", hasErrorCode);
        console.log("will redirect on dev or production");
      } else {
        await redirect({ success: !hasErrorCode, errorType });
      }
    }
  },
});
