













import { defineComponent, ref, PropType, computed } from "@vue/composition-api";
import { getSalutations, CustomerType } from "@/api/sap";
import { useAsyncComponent } from "@/hooks/useAsyncComponent";
import store from "@/store";

export default defineComponent({
  name: "SalesApplicationSalutations",
  props: {
    customerType: {
      type: String as PropType<CustomerType>,
    },
    loadingIds: {
      type: Array as PropType<string[]>,
      default: () => ["SalesApplicationPersonalDetailsStep"],
    },
    rules: {
      type: String,
      default: "required",
    },
  },
  setup(props, ctx) {
    const stateCustomerType = computed(
      () => store.getters.salesApplicationRouteCustomerType
    );

    const requestCustomerType = computed(() =>
      props.customerType ? props.customerType : stateCustomerType.value
    );

    const { withLoading } = useAsyncComponent({
      root: ctx.root,
      ids: props.loadingIds,
    });

    const options = ref([
      {
        TITLE: "0001",
        TITLE_MEDI: "Frau",
      },
      {
        TITLE: "0002",
        TITLE_MEDI: "Herrn",
      },
    ]);

    withLoading(getSalutations(requestCustomerType.value)).then(
      (salutations) => {
        if (salutations) {
          options.value = salutations ?? [];
        }
      }
    );

    return {
      options,
    };
  },
});
